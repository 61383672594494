import { Shape } from "./shape";
import { SideCoordinate } from "./side-coordinate";

export class Line extends Shape {

    public constructor() {
        super();
        this.Name = 'line';
        this.AddTop(this.Top);
        this.AddBottom(this.Bottom);
        this.AddRight(this.Right);
        this.AddLeft(this.Left);
    }
    
    private AddBottom(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 0, endY: 100, side: '1' });        
    }

    private AddTop(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });        
    }

    private AddRight(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });        
    }

    private AddLeft(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });        
    }  
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ValidationService } from '@xactly-app/common';
import { Payment } from '@xactly-app/common';
declare var require: any;
const CreditCard = require('creditcards');

@Component({
  selector: 'app-creditcard',
  templateUrl: './creditcard.component.html',
  styleUrls: ['./creditcard.component.scss']
})
export class CreditcardComponent implements OnInit {
  @Output() submitPayment: EventEmitter<Payment> = new EventEmitter<Payment>();
  public cardnumber: number;
  public expiryDate: string;
  public name: string;

  constructor(public validationService: ValidationService) {
  }

  ngOnInit() {

  }

  getCardType() {
    return CreditCard.card.type(CreditCard.card.parse(String(this.cardnumber)), true);
  }

  isCardValid() {
    return CreditCard.card.isValid(CreditCard.card.parse(String(this.cardnumber)));
  }

  isValid() {
    return this.isCardValid();
  }
  submit() {
    const paymentInformation = new Payment();
    paymentInformation.name = this.name;
    paymentInformation.creditCardNumber = CreditCard.card.parse(String(this.cardnumber));
    paymentInformation.expiryDate = this.expiryDate;
    this.submitPayment.emit(paymentInformation);
  }
}

<form #creditCardForm="ngForm" (ngSubmit)="submit()">
  <div>
    <mat-form-field>
      <input matInput [(ngModel)]="name" maxlength="500" placeholder="Name on card" name="nameOnCard" required>
      <mat-error>Name is required</mat-error>
    </mat-form-field>
    <mat-form-field [hintLabel]="getCardType()">
      <input matInput [(ngModel)]="cardnumber" maxlength="20" placeholder="Credit Card Number" type="tel" name="creditCardNumber"
        required>
      <mat-error>Credit card is required</mat-error>
    </mat-form-field>
    <mat-form-field [hintLabel]="'For example, for January 2017 enter 01/17'">
      <input matInput [textMask]="validationService.ExpiryDate" [(ngModel)]="expiryDate" #expirydate="ngModel"
        placeholder="Expiry Date (mm/yy)" type="text" name="expiryDate" required completed>
      <mat-error *ngIf="expirydate.errors?.required">Expiry date is required</mat-error>
      <mat-error *ngIf="expirydate.errors?.notCompleted">Expiry date provided is not valid</mat-error>
    </mat-form-field>
  </div>
  <br>
  <div>
    <ngb-alert *ngIf="creditCardForm.valid && !isCardValid()" [dismissible]="false" type="danger">The credit card
      number you have entered is
      not valid
    </ngb-alert>
    <ngb-alert *ngIf="!creditCardForm.valid" [dismissible]="false" type="warning">Please enter all credit card
      information in order to submit
      payment
    </ngb-alert>
    <ngb-alert *ngIf="creditCardForm.valid && isCardValid()" type="success" [dismissible]="false">
      Credit card information is correct
    </ngb-alert>
    <button mat-raised-button [disabled]="!creditCardForm.valid || !isValid()" color="primary" type="submit">Submit</button>
  </div>
</form>
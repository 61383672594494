import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';

@Component({
  selector: 'app-agency-add',
  templateUrl: './agency-add.component.html',
  styleUrls: ['./agency-add.component.scss']
})
export class AgencyAddComponent extends BaseComponent implements OnInit {
  public agency: Agency;
  constructor(
    public router: Router,
    public errorTranslationService: ErrorTranslationService,
    public validationService: ValidationService,
    public dialogService: DialogService,
    public agencyService: AgencyService) {
    super();
  }

  ngOnInit() {
    this.Load();
  }

  private Load() {
    this.agency = new Agency();
  }

  public Cancel() {
    this.router.navigate(['/agencies']);
  }

  public Save() {
    this.busy();
    this.agencyService.createAgency(this.agency).pipe(finalize(() => { this.ready(); })).subscribe(
      agency => {
        this.agency = agency;
        this.router.navigate(['/agencies']);
      },
      this.errorTranslationService.ErrorHandler);
  }
}

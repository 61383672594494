import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { EventEmitter } from 'events';
import { finalize } from 'rxjs/operators';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
var UserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserComponent, _super);
    function UserComponent(apiService, agentService, agencyService, roleService, errorTranslationService, validationService, dialogService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.agentService = agentService;
        _this.agencyService = agencyService;
        _this.roleService = roleService;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.userChanged = new EventEmitter();
        _this.userDeleted = new EventEmitter();
        return _this;
    }
    UserComponent.prototype.delete = function () {
        var _this = this;
        if (this.agentUser.user.id && this.agentUser.user.id !== null) {
            this.busy();
            this.agentService.getAgentUser(this.agentUser.user.id).pipe(finalize(function () { _this.ready(); })).subscribe(function (agentResult) {
                _this.busy();
                _this.apiService.deleteUser(_this.agentUser.user.id).pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
                    _this.userDeleted.emit(_this.agentUser.user);
                    _this.dialogService.showOkDialog('User has been deleted.', 'User');
                }, _this.errorTranslationService.ErrorHandler);
            }, this.errorTranslationService.ErrorHandler);
            this.apiService.deleteUser(this.agentUser.user.id).pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
                _this.userDeleted.emit(_this.agentUser.user);
                _this.dialogService.showOkDialog('User has been deleted.', 'User');
            }, this.errorTranslationService.ErrorHandler);
        }
    };
    UserComponent.prototype.save = function () {
        var _this = this;
        this.busy();
        this.apiService.updateUser(this.agentUser.user).pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
            if (_this.agentUser.agent.id === null) {
                var agent = new Agent();
                agent.agencyId = _this.agentUser.agent.agencyId;
                agent.userId = _this.agentUser.user.id;
                _this.agentService.createAgent(agent).pipe(finalize(function () { _this.ready(); })).subscribe(function (agent) {
                    _this.agentUser.agent = agent;
                });
            }
            else {
                _this.agentService.updateAgent(_this.agentUser.agent).pipe(finalize(function () { _this.ready(); })).subscribe(function (agent) {
                    _this.agentUser.agent = agent;
                });
            }
            _this.dialogService.showOkDialog('User was updated successfully.', 'User');
            _this.userChanged.emit(result);
        }, this.errorTranslationService.ErrorHandler);
    };
    UserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.agencyService.getAgencys().subscribe(function (agencies) {
            _this.agencies = agencies;
        }, this.errorTranslationService.ErrorHandler);
        this.roleService.getRoles().subscribe(function (roles) {
            _this.roles = roles;
        }, this.errorTranslationService.ErrorHandler);
    };
    return UserComponent;
}(BaseComponent));
export { UserComponent };

<div>
    <strong>{{permission.name}}</strong>
</div>
<div>
    <mat-checkbox [registerForm]="form" [registerModel]="modelInput" #modelInput="ngModel" name="effectivePermission.create" [(ngModel)]="effectivePermission.create">Create</mat-checkbox>
</div>
<div>
    <mat-checkbox [registerForm]="form" [registerModel]="modelInput1" #modelInput1="ngModel" name="effectivePermission.update" [(ngModel)]="effectivePermission.update">Update</mat-checkbox>
</div>
<div>
    <mat-checkbox [registerForm]="form" [registerModel]="modelInput2" #modelInput2="ngModel" name="effectivePermission.delete" [(ngModel)]="effectivePermission.delete">Delete</mat-checkbox>
</div>
<div>
    <mat-checkbox [registerForm]="form" [registerModel]="modelInput3" #modelInput3="ngModel" name="effectivePermission.read" [(ngModel)]="effectivePermission.read">Read</mat-checkbox>
</div>
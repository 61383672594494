<instruction-card [dismissable]='false' title='Subscriptions'>
</instruction-card>
<mat-card>
  <mat-card-header>
  </mat-card-header>
  <mat-card-content>
    <loading-indicator *ngIf="loading"></loading-indicator>
    <ngb-alert [dismissible]="false" type='info'>
      The purchase of a subscription will give you or your oganization full access to creating an unlimted number of
      diagrams for the subscription period.
    </ngb-alert>
    <ngb-alert *ngIf='!loading && UserHasFreeSubscription()' [dismissible]="false" type='success'>
      You have a free trial single user subscription available, try us out!  Choose single user subscription and choose the free subscription.
    </ngb-alert>
    <ngb-alert *ngIf='!loading && !securityService.HasManagerRole && !agentUser?.agency.isSystem' [dismissible]="false"
      type='warning'>
      Your user is currently associated with agency {{agentUser?.agency.name}}. Please contact the agency manager to
      purchase a new subscription.
    </ngb-alert>
    <mat-vertical-stepper *ngIf='securityService.HasManagerRole || agentUser?.agency.isSystem' linear="true" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>
            Do you need a subscription for yourself or multiple people?
          </ng-template>
          <ngb-alert *ngIf='securityService.HasManagerRole || !agentUser?.agency.isSystem' [dismissible]="false"
            type='info'>
            Your user is currently associated with agency {{agentUser?.agency.name}}, so only subscriptions for
            multiple users can be purchased.
          </ngb-alert>
          <mat-radio-group formControlName="firstCtrl" class="radio-group" [(ngModel)]="selectedParentSubscriptionType">
            <mat-radio-button class="radio-button" *ngFor="let parentSubscriptionType of parentSubscriptionTypes"
              [value]="parentSubscriptionType">
              {{parentSubscriptionType.description}}
            </mat-radio-button>
          </mat-radio-group>
          <div>
            <button mat-raised-button matStepperNext color="primary">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Choose the subscription that best suits your needs</ng-template>
          <ngb-alert *ngIf='!agentUser?.agency.isSystem && agentUser?.agency.agentCount > 1' [dismissible]="false"
            type='info'>
            Your agency currently has {{agentUser?.agency.agentCount}} agents. Only subscriptions that cover at least
            that many users are shown.
          </ngb-alert>
          <ngb-alert [dismissible]="false" type='success'
            *ngIf="agentUser?.agency.isSystem && UserHasFreeSubscription()">
            Congratulations! You have a free subscription available, you can select it from the list below.
          </ngb-alert>
          <mat-radio-group formControlName="secondCtrl" class="radio-group" [(ngModel)]="selectedSubscriptionType">
            <mat-radio-button class="radio-button" *ngFor="let subscriptionType of GetSubscriptionTypes()"
              [value]="subscriptionType">
              {{subscriptionType.description}}, {{subscriptionType.price | currency}}
            </mat-radio-button>
          </mat-radio-group>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-raised-button matStepperNext color="primary">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <form [formGroup]="thirdFormGroup" *ngIf="selectedSubscriptionType?.price > 0">
          <ng-template matStepLabel>Purchase subscription</ng-template>
          <ngb-alert *ngIf='agentUser?.agency.isSystem && selectedParentSubscriptionType.isAgencySubscription'
            [dismissible]="false" type='warning'>
            When purchasing a subscription for multiple users you must give your group of users a name. This can be
            your company or agency name and will be called an agency in this system. You will become a manager for your
            own group and will be able to assign users.
          </ngb-alert>
          <mat-form-field *ngIf='agentUser?.agency.isSystem && selectedParentSubscriptionType.isAgencySubscription'
            required>
            <input matInput placeholder="Group Name" formControlName="thirdCtrl" [(ngModel)]="groupName">
          </mat-form-field>
          <ngb-alert [dismissible]="false" type='info'>
            <div>Subscription Cost: {{selectedSubscriptionType?.price | currency}}</div>
            <br />
            <div>PST: {{GetStateTaxAmount() | currency}}</div>
            <br />
            <div>GST: {{GetFederalTaxAmount() | currency}}</div>
            <br />
            <div> Total: {{GetTotalCost() | currency}}</div>
            <br />
            <div> Your card will be charged {{GetTotalCost() | currency}}</div>
          </ngb-alert>
          <app-creditcard (submitPayment)="SubmitPayment($event)"></app-creditcard>
        </form>
        <form [formGroup]="thirdFormGroup" *ngIf="selectedSubscriptionType?.price === 0">
          <ng-template matStepLabel>Claim free subscription</ng-template>
          <button mat-raised-button matStepperNext color="primary" (click)="SubmitPayment($event)">Submit</button>
        </form>
        <div>
          <button mat-button matStepperPrevious>Back</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
</mat-card>
import * as tslib_1 from "tslib";
import { Shape } from "./shape";
var Rectangle = /** @class */ (function (_super) {
    tslib_1.__extends(Rectangle, _super);
    function Rectangle() {
        var _this = _super.call(this) || this;
        _this.Name = 'rectangle';
        _this.AddTop(_this.Top);
        _this.AddBottom(_this.Bottom);
        _this.AddRight(_this.Right);
        _this.AddLeft(_this.Left);
        return _this;
    }
    Rectangle.prototype.AddBottom = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    };
    Rectangle.prototype.AddTop = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    };
    Rectangle.prototype.AddRight = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    };
    Rectangle.prototype.AddLeft = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    };
    return Rectangle;
}(Shape));
export { Rectangle };

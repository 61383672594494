<form #userForm="ngForm" (ngSubmit)="Save()">
  <mat-card>
    <mat-card-header>
      <loading-indicator *ngIf="loading"></loading-indicator>
      <mat-card-title>
        <h2>Add Measurement Unit</h2>
      </mat-card-title>
      <mat-card-subtitle>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="measurementUnit.singleName" placeholder="Single Name" maxlength="200" type="text" name="measurementUnit.singleName"
            #measurementUnitSingleName="ngModel" required>
          <mat-error *ngIf="measurementUnitSingleName.errors?.required">Single name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="measurementUnit.pluralName" placeholder="Plural Name" maxlength="200" type="number" name="measurementUnit.pluralName"
            #measurementUnitPluralName="ngModel" required>
          <mat-error *ngIf="measurementUnitPluralName.errors?.required">Plural name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="measurementUnit.shortName" placeholder="Short Name" maxlength="200" type="number" name="measurementUnit.shortName"
            #measurementUnitshortName="ngModel" required>
          <mat-error *ngIf="measurementUnitshortName.errors?.required">Short name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="measurementUnit.squaredName" placeholder="Squared Name" maxlength="200" type="number" name="measurementUnit.squaredName"
            #measurementUnitsquaredName="ngModel" required>
          <mat-error *ngIf="measurementUnitsquaredName.errors?.required">Squared name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="measurementUnit.description" placeholder="Description" maxlength="200" type="number" name="measurementUnit.description"
            #measurementUnitdescription="ngModel">
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <loading-indicator *ngIf="loading"></loading-indicator>
      <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">Save</button>
      <button mat-raised-button color="warn" (click)="Cancel()" type="button">Cancel</button>
    </mat-card-actions>
  </mat-card>
</form>
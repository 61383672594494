import * as tslib_1 from "tslib";
import { AfterViewChecked, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';
var SubscriptionTypeEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionTypeEditComponent, _super);
    function SubscriptionTypeEditComponent(winService, stateService, dialogService, validationService, errorTranslationService, activatedRoute, router, subscriptionTypeService) {
        var _this = _super.call(this) || this;
        _this.winService = winService;
        _this.stateService = stateService;
        _this.dialogService = dialogService;
        _this.validationService = validationService;
        _this.errorTranslationService = errorTranslationService;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.subscriptionTypeService = subscriptionTypeService;
        _this.subscriptionType = new SubscriptionType();
        return _this;
    }
    SubscriptionTypeEditComponent.prototype.ngAfterViewChecked = function () {
    };
    SubscriptionTypeEditComponent.prototype.ngAfterViewInit = function () {
    };
    SubscriptionTypeEditComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    SubscriptionTypeEditComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.subscriptionTypeService.updateSubscriptionType(this.subscriptionType)
            .pipe(finalize(function () { _this.ready(); }))
            .subscribe(function (subscriptionType) {
            _this.subscriptionType = subscriptionType;
            _this.router.navigate(['/subscriptionTypes']);
        }, this.errorTranslationService.ErrorHandler);
    };
    SubscriptionTypeEditComponent.prototype.Cancel = function () {
        this.router.navigate(['/subscriptionTypes']);
    };
    SubscriptionTypeEditComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        this.activatedRoute.paramMap
            .pipe(finalize(function () { _this.ready(); }), switchMap(function (params) {
            return _this.subscriptionTypeService.getSubscriptionType(params.get('id'));
        }))
            .pipe(finalize(function () { _this.ready(); }))
            .subscribe(function (subscriptionType) {
            _this.subscriptionType = subscriptionType;
            _this.ready();
        }, this.errorTranslationService.ErrorHandler);
    };
    return SubscriptionTypeEditComponent;
}(BaseComponent));
export { SubscriptionTypeEditComponent };

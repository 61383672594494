<form #thisForm="ngForm" (ngSubmit)="Save()">
  <mat-card>
    <mat-card-header>
      <loading-indicator *ngIf="loading"></loading-indicator>
      <mat-card-title>
        <h2 *ngIf="adding">New support request</h2>
        <h2 *ngIf="!adding">Edit support request</h2>
      </mat-card-title>
      <mat-card-subtitle>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>                                
      <div>
        <mat-form-field class="input-full-width">          
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="10"
            cdkAutosizeMaxRows="20"
            [(ngModel)]="supportRequest.message" 
            placeholder="Description of problem or question" 
            maxlength="1000" 
            type="text" 
            name="supportRequest.message"  
            #message="ngModel" 
            required>
          </textarea>
          <mat-error *ngIf="message.errors?.required">Support message is required</mat-error>
        </mat-form-field>
      </div>              
    </mat-card-content>
    <mat-card-actions>
      <loading-indicator *ngIf="loading"></loading-indicator>
      <button [disabled]="!thisForm.valid || loading" mat-raised-button color="primary" type="submit">Submit</button>
      <button mat-raised-button color="warn" (click)="Cancel()" type="button">Cancel</button>        
    </mat-card-actions>
  </mat-card>
</form>
<mat-toolbar class="fixed-header">
    <img src="./assets/images/logo.png" [routerLink]="'home'"><span class="gap" [routerLink]="'home'"> Xactly!</span>    

    <mat-progress-spinner diameter="20" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
    <button *ngIf="!loading" (click)="start.toggle()" mat-button>
        <mat-icon>reorder</mat-icon>
    </button>
    <button mat-icon-button color="accent" (click)="ShowFaq()" title='Help'>
        <mat-icon>help</mat-icon>
    </button>
    <div *ngIf="securityService?.UserLastName" class="move-right">{{securityService?.UserFirstName}},
        {{securityService?.UserLastName}}</div>
</mat-toolbar>
<mat-sidenav-container>
    <mat-sidenav #start mode="push">
        <mat-nav-list>
            <a mat-list-item *ngIf="!IsAuthorized" (click)="Login()">Login</a>
            <a mat-list-item *ngIf="IsAuthorized" (click)="Logout()">Logout</a>
            <a mat-list-item *ngFor="let navItem of navItems" [routerLink]="[navItem.route]" routerLinkActive="nav-active"
                (click)="start.close()">
                {{navItem.name}}
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<a href="http://kaixo.ca" target="_blank"><img class="move-right logo-icon" src="./assets/images/kaiko.png"></a>
<div class="move-right">Engineered by</div>
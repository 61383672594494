import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setLicenseKey } from 'wijmo/wijmo';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

setLicenseKey(
  'Adrian Wilson,test.xactly.ca|www.xactly.ca,957622844663186#B0YIJNHZisnOiwmbBJye0ICRiwiI34zZxlXVTdHePJneuRDRLRVN5sWaIFVaiR4L7FmUNB5dZdTUQNlVQ9EZuNkUldGSNlkNjNXY62WNxoFbH3GMDpkeUt6Vi9kML9mQw3SNzETZzMETqpGUltUbkdUWURFdjdmTMNHNv3kW9x6S9BlWFlGWkp7MvhESoZDN0JjT74mRa9EZmFTMVlnSlRXQ4gXVM9ES5cFWiZWVFREOGJHNBtSSPZFSGZ4UtJTeXRUWmRHalR6UW3mYa3kQwFWVZdWNL3WZQZ7UYVXS74Eeqd5a4d6Nv2EaXdnNyMDMM3mM0hnWCVDNY96TBV6K426LxpXdZZzQK3CakNjQqpERiNGUtVTSyMlej5kW9AVQiNGN6cHOzhTMMpndzlXQstGW8RFVSlFavJzUPdFU73GMBRHZ4VDOBtiY8h6TFRHbJNGbVh5KVFnQYV6MI5EOBpndFdmevY6VN5Wd5MkI0IyUiwiI5kDMzMUNyEjI0ICSiwSM7kjM4AjMzgTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiQTMyQDMxASOwQDM9EDMyIiOiQncDJCLiE6YukHb4NWY89yd7dHLhNmL9xGdjFGeuQ7clRnI0IyctRkIsIibvNHbpdFIuFWayRWQiojIh94QiwiI6gTMzYjN4QDOyIjN7UTOiojIklkIs4nIxYHOxAjMiojIyVmdiwSZzxMYwp'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

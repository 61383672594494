import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var RegistrationService = /** @class */ (function () {
    function RegistrationService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    RegistrationService.prototype.createRegistration = function (registration) {
        return this.httpService.post(this.configuration.ApiServer + "api/Registration", JSON.stringify(registration));
    };
    RegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: RegistrationService, providedIn: "root" });
    return RegistrationService;
}());
export { RegistrationService };

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'instruction-card',
  templateUrl: './instruction-card.component.html',
  styleUrls: ['./instruction-card.component.scss']
})
export class InstructionCardComponent implements OnInit {
  public showInstructions = true;
  @Input() message: string;
  @Input() title: string;
  @Input() dismissable = true;

  constructor() { }
  ngOnInit() {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { EffectivePermission } from '@xactly-app/common/src/lib/models/EffectivePermission';
import { Permission } from '@xactly-app/common/src/lib/models/Permission';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'permission-component',
    templateUrl: './permission.component.html',
    styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

    @Input() effectivePermission: EffectivePermission;
    @Input() permission: Permission;
    @Input('form') form: NgForm;    

    constructor() { }

    ngOnInit() {
    }

}

import * as tslib_1 from "tslib";
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { switchMap, finalize, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSupportRequestService } from '@xactly-app/common/src/lib/services/UserSupportRequestService';
import { OnInit } from '@angular/core';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { of } from 'rxjs';
import { SupportRequest } from '@xactly-app/common/src/lib/models/SupportRequest';
import { UserSupportRequest } from '@xactly-app/common/src/lib/models/UserSupportRequest';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';
var SupportEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SupportEditComponent, _super);
    function SupportEditComponent(dialogService, router, userSupportRequestService, activatedRoute, errorTranslationService, securityService) {
        var _this = _super.call(this) || this;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.userSupportRequestService = userSupportRequestService;
        _this.activatedRoute = activatedRoute;
        _this.errorTranslationService = errorTranslationService;
        _this.securityService = securityService;
        _this.supportRequest = new SupportRequest();
        return _this;
    }
    SupportEditComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    SupportEditComponent.prototype.Cancel = function () {
        if (this.securityService.HasAdminRole) {
            this.router.navigate(['/support']);
        }
        else {
            this.router.navigate(['/home']);
        }
    };
    SupportEditComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        var observable = this.userSupportRequestService.updateSupport(this.supportRequest);
        if (this.adding) {
            observable = this.userSupportRequestService.createSupport(this.supportRequest);
        }
        observable.pipe(take(1), finalize(function () { _this.ready(); })).subscribe(function (userSupportRequest) {
            _this.supportRequest = userSupportRequest.supportRequest;
            if (_this.adding) {
                _this.dialogService.showOkDialog('Your support request has been submitted, you will be contacted as soon as possible', 'Support Request Submitted').subscribe(function () {
                    if (_this.securityService.HasAdminRole) {
                        _this.router.navigate(['/support']);
                    }
                    else {
                        _this.router.navigate(['/home']);
                    }
                });
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    SupportEditComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        this.activatedRoute.paramMap
            .pipe(switchMap(function (params) {
            if (params.get('id') === '0') {
                _this.adding = true;
                var newUserSupportRequest = new UserSupportRequest();
                newUserSupportRequest.supportRequest = new SupportRequest();
                newUserSupportRequest.supportRequest.message = '';
                return of(newUserSupportRequest);
            }
            _this.adding = false;
            return _this.userSupportRequestService.getSupport(params.get('id'));
        }), take(1), finalize(function () { _this.ready(); })).subscribe(function (existingUserSupportRequest) {
            _this.supportRequest = existingUserSupportRequest.supportRequest;
        }, this.errorTranslationService.ErrorHandler);
    };
    return SupportEditComponent;
}(BaseComponent));
export { SupportEditComponent };

import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserSupportRequest } from '@xactly-app/common/src/lib/models/UserSupportRequest';
import { finalize, take, debounce } from 'rxjs/operators';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserSupportRequestService } from '@xactly-app/common/src/lib/services/UserSupportRequestService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { PagingBaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styleUrls: ['./support-list.component.css']
})
export class SupportListComponent extends PagingBaseComponent implements OnInit {
  public supportRequests: Array<UserSupportRequest>;

  constructor(
    private supportService: UserSupportRequestService,
    private errorTranslationService: ErrorTranslationService,
    private dialogService: DialogService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.LoadPageSize();
  }

  public PageChanged(page: number) {
    this.Load(page);
  }

  public Edit(userSupportRequest: UserSupportRequest) {
    this.router.navigate(['/support', userSupportRequest.supportRequest.id]);
  }

  public Add() {
    this.router.navigate(['/support', '0']);
  }

  protected LoadPageSize() {
    this.busy();
    this.supportService.getSupportCount().pipe(take(1)).subscribe(
      count => {
        this.totalCount = <any>count;
        this.totalPages = Math.ceil(<any>count / this.pageSize);
        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
        }

        if (this.totalPages === 0 || this.totalPages === 1) {
          this.currentPage = 1;
        }
        this.Load(this.currentPage);
      },
      this.errorTranslationService.ErrorHandler);
  }

  protected Load(page: number) {
    this.busy();
    this.supportService.getSupportPageSizePages(this.pageSize, page).pipe(take(1), finalize(() => { this.ready(); })).subscribe(
      supportRequests => {
        this.supportRequests = supportRequests;
      },
      this.errorTranslationService.ErrorHandler);
  }

  protected Delete(userSupportRequest: UserSupportRequest) {
    this.dialogService.showConfirmDialog('Are you sure you want to remove this support request?', 'Remove Support Request').subscribe(
      dialogResult => {
        if (dialogResult) {
          this.busy();
          this.supportService.deleteSupport(userSupportRequest.supportRequest.id)
            .pipe(take(1)).subscribe((result) => {
              this.LoadPageSize();
            },
              this.errorTranslationService.ErrorHandler);
        }
      },
      this.errorTranslationService.ErrorHandler);
  }
}

﻿export class Utility {
    public static IsEmptyOrNull(value: any) {
        return (
            typeof value === 'undefined' ||
            value === null ||
            value === '' ||
            (Object.prototype.toString.call(value) === '[object Array]' && value.length == 0)
        );
    }

    public static IsEmpty(o) {
        for (var i in o) {
            return false;
        }
        return true;
    }

    public static IsNull(value: any) {
        return typeof value === 'undefined' || value === null;
    }

    public static GetServer = () =>
        window.location.port !== ''
            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`
            : `${window.location.protocol}//${window.location.hostname}/`;
}

export function isProd() {
    return window.location.hostname.indexOf('www') > -1 || window.location.hostname == 'xactly.ca';
}

export function isTest() {
    return window.location.hostname.indexOf('test') > -1;
}

export function isDev() {
    return window.location.hostname.indexOf('localhost') > -1;
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss']
})
export class AgencyListComponent extends BaseComponent implements OnInit {

  public agencies: Agency[];
  public displayedColumns: string[];

  constructor(
    public errorTranslationService: ErrorTranslationService,
    public dialogService: DialogService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public agencyService: AgencyService,
    public userService: UserService) {

    super();
  }

  ngOnInit() {
    this.Load();
  }

  protected Edit(agency: Agency) {
    this.router.navigate(['/agency', agency.id]);
  }

  public Add() {
    this.router.navigate(['/agency']);
  }

  protected Delete(agency: Agency) {
    this.dialogService.showConfirmDialog('Are you sure you want to remove this agency?', 'Remove Agency').subscribe(
      dialogResult => {
        if (dialogResult) {
          this.busy();
          this.agencyService.deleteAgency(agency.id).pipe(finalize(() => { this.ready(); })).subscribe(
            () => {
            },
            this.errorTranslationService.ErrorHandler);
        }
      }
    );
  }

  protected Load() {
    this.busy();
    return this.agencyService.getAgencys().pipe(finalize(() => { this.ready(); })).subscribe(
      agencies => {
        this.agencies = agencies;
      },
      this.errorTranslationService.ErrorHandler);
  }
}

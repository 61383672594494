import { FaqComponent } from './../help/faq/faq.component';
import { HelpComponent } from './../help/help.component';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var HelpService = /** @class */ (function () {
    function HelpService(snackBar) {
        this.snackBar = snackBar;
    }
    HelpService.prototype.ShowHelp = function (helpUrl, message, buttonMessage) {
        var reference = this.snackBar.openFromComponent(HelpComponent, { data: { snackBarReference: this.snackBar, helpUrl: helpUrl, message: message, buttonMessage: buttonMessage } });
    };
    HelpService.prototype.ShowFaq = function () {
        var reference = this.snackBar.openFromComponent(FaqComponent, { data: { snackBarReference: this.snackBar } });
    };
    HelpService.ngInjectableDef = i0.defineInjectable({ factory: function HelpService_Factory() { return new HelpService(i0.inject(i1.MatSnackBar)); }, token: HelpService, providedIn: "root" });
    return HelpService;
}());
export { HelpService };

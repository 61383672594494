import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, UserManagerEvents } from 'oidc-client';
import { Router } from '@angular/router';
import * as Oidc from 'oidc-client';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import LocalWebStorageStore from '../models/memory.storage';
import { Utility } from '../utility';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private userManager: UserManager;
    private user: User;

    private loginChanged: Subject<boolean> = new Subject<boolean>();
    configuration: ConfigurationModel;
    constructor(configService: AppConfigService, private router: Router) {
        this.configuration = configService.getConfig();
        const memoryStorage = new LocalWebStorageStore();

        const storageSettings: Oidc.WebStorageStateStoreSettings = {
            prefix: 'oidc.',
            store: memoryStorage
        };

        const settings: UserManagerSettings = {
            authority: this.configuration.authority,
            client_id: this.configuration.clientId,
            redirect_uri: this.configuration.redirectUri,
            silent_redirect_uri: this.configuration.silentRenewUri,
            response_type: this.configuration.responseType,
            post_logout_redirect_uri: this.configuration.postLogoutRedirectUri,
            scope: this.configuration.scopes,
            response_mode: this.configuration.responseMode,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true
            // userStore: new Oidc.WebStorageStateStore(storageSettings)  Uncomment to switch to memory storage
        };

        if (this.configuration.debugLogging) {
            Oidc.Log.logger = console;
            Oidc.Log.level = Oidc.Log.DEBUG;
        }

        this.userManager = new UserManager(settings);
        this.userManager.getUser().then(user => {
            this.user = user;
            this.loginChanged.next(this.isLoggedIn());
        });

        this.userManager.events.addAccessTokenExpiring(() => {
            this.userManager.getUser().then(user => {
                this.user = user;
            });
            if (this.configuration.debugLogging) {
                console.log('Token about to expire');
            }
        });

        this.userManager.events.addUserSessionChanged(() => {
            this.userManager.getUser().then(user => {
                console.log('Session changed');
                this.user = user;
                this.loginChanged.next(this.isLoggedIn());
            });
        });

        this.userManager.events.addAccessTokenExpired(() => {
            this.userManager.getUser().then(user => {
                this.user = user;
            });
            if (this.configuration.debugLogging) {
                console.log('Token expired');
            }
        });

        this.userManager.events.addSilentRenewError(error => {
            console.log(error);
        });
    }

    public getUser(): Promise<User> {
        return this.userManager.getUser();
    }

    public get LoginChanged() {
        return this.loginChanged;
    }

    getAuthorizationHeaderValue(): string {
        if (this.isLoggedIn()) {
            return `${this.user.token_type} ${this.user.access_token}`;
        } else {
            return '';
        }
    }

    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    login(redirectRoute: string = '/', otac: string = null, extraQueryParams: any = {}) {
        if (otac === null) {
            this.userManager.signinRedirect({ state: redirectRoute, extraQueryParams });
        } else {
            this.userManager.signinRedirect({ acr_values: 'otp:' + otac, state: redirectRoute, extraQueryParams });
        }
    }

    public get UserLastName(): string {
        if (!this.isLoggedIn()) {
            return '';
        }
        return this.user.profile.family_name;
    }

    public get UserFirstName(): string {
        if (!this.isLoggedIn()) {
            return '';
        }
        return this.user.profile.given_name;
    }

    public get UserId(): string {
        if (!this.isLoggedIn()) {
            return '';
        }
        return this.user.profile.id;
    }

    public get HasAgentRole(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        return this.user.profile.role === 'agent';
    }

    public get HasManagerRole(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        return this.user.profile.role === 'manager';
    }

    public get HasAdminRole(): boolean {
        if (!this.isLoggedIn()) {
            return false;
        }
        return this.user.profile.role === 'administrator';
    }

    logout() {
        this.userManager.signoutRedirect();
    }

    callback() {
        this.userManager
            .signinRedirectCallback()
            .then(user => {
                this.user = user;
                this.loginChanged.next(this.isLoggedIn());
                if (typeof user.state === 'undefined' || user.state === '') {
                    this.router.navigate(['/']);
                } else {
                    this.router.navigate([user.state]);
                }
            })
            .catch(err => {
                this.router.navigate(['/']);
            });
    }
}

<instruction-card [message]="'This page displays all of your support requests'" [title]="'Support Requests'"></instruction-card>
<mat-card>
  <mat-card-content>
    <loading-indicator *ngIf="loading"></loading-indicator>
    <div>
      <button mat-button color="primary" (click)="Add()" title='Add Support'>
        Add Support Request
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
    <ngb-pagination *ngIf="totalCount > pageSize" [disabled]="loading" [ellipses]="false" [maxSize]="5" [rotate]="false" [pageSize]="pageSize" [collectionSize]="totalCount" [(page)]="currentPage" [boundaryLinks]="true" (pageChange)="PageChanged($event)"></ngb-pagination>
    <div *ngIf="!loading" class="grid">            
      <wj-flex-grid [itemsSource]="supportRequests" [headersVisibility]="1">
        <wj-flex-grid-column [allowSorting]="false" [header]="'Actions'" [isReadOnly]="true" [width]="90">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button mat-icon-button (click)="Edit(cell.item)" title='Edit Support Request'>
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="Delete(cell.item)" title='Remove Support Request'>
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>        
        </wj-flex-grid-column>        
        <wj-flex-grid-column [header]="'Date'" [minWidth]="250" binding="supportRequest.modifiedDate" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Message'" [minWidth]="250" binding="supportRequest.message" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Email'" [minWidth]="200" binding='user.person.email' [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'First Name'" [minWidth]="200" binding='user.person.firstName' [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Last Name'" [minWidth]="200" binding='user.person.lastName' [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
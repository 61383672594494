import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    SubscriptionService.prototype.getSubscriptionActives = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription/Active");
    };
    SubscriptionService.prototype.getSubscriptions = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription");
    };
    SubscriptionService.prototype.createSubscriptionPurchase = function (subscriptionRequest) {
        return this.httpService.post(this.configuration.ApiServer + "api/Subscription/Purchase", JSON.stringify(subscriptionRequest));
    };
    SubscriptionService.prototype.createSubscription = function (subscription) {
        return this.httpService.post(this.configuration.ApiServer + "api/Subscription", JSON.stringify(subscription));
    };
    SubscriptionService.prototype.deleteSubscription = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Subscription/" + encodeURIComponent(id));
    };
    SubscriptionService.prototype.updateSubscriptionAgency = function (subscription) {
        return this.httpService.put(this.configuration.ApiServer + "api/Subscription/Agency", JSON.stringify(subscription));
    };
    SubscriptionService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: SubscriptionService, providedIn: "root" });
    return SubscriptionService;
}());
export { SubscriptionService };

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../models/Role';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable()
export class RoleService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }
    public getRoles(): Observable<Role[]> {
        return this.httpService.get(`${this.configuration.UserManagementApiServer}api/Role`);
    }
    public getRole(id: string): Observable<Role> {
        return this.httpService.get(`${this.configuration.UserManagementApiServer}api/Role/${encodeURIComponent(id)}`);
    }
    public updateRole(role: Role): Observable<Role> {
        return this.httpService.put(`${this.configuration.UserManagementApiServer}api/Role`, JSON.stringify(role));
    }
    public createRole(role: Role): Observable<Role> {
        return this.httpService.post(`${this.configuration.UserManagementApiServer}api/Role`, JSON.stringify(role));
    }
    public deleteRole(id: string): Observable<boolean> {
        return this.httpService.delete(
            `${this.configuration.UserManagementApiServer}api/Role?id=${encodeURIComponent(id)}`
        );
    }
}

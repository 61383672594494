﻿
// Generated using Typewritter, please do not modify!

import { Payment } from './Payment';

export class SubscriptionRequest {
        
    public newAgencyName : string = null;    
    public subscriptionTypeId : string = null;    
    public payment : Payment = null;
}

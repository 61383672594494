var LocalWebStorageStore = /** @class */ (function () {
    function LocalWebStorageStore() {
        this.keys = [];
        this.memory = {};
    }
    Object.defineProperty(LocalWebStorageStore.prototype, "length", {
        get: function () {
            return this.keys.length;
        },
        enumerable: true,
        configurable: true
    });
    LocalWebStorageStore.prototype.clear = function () {
        // NOTE: no event support
        this.memory = {};
        this.keys = [];
    };
    LocalWebStorageStore.prototype.getItem = function (key) {
        return this.memory.hasOwnProperty(key) ? this.memory[key] : null;
    };
    LocalWebStorageStore.prototype.key = function (n) {
        var key = this.keys[n];
        return typeof key === 'undefined' ? null : key;
    };
    LocalWebStorageStore.prototype.removeItem = function (key) {
        // NOTE: no event support
        delete this.memory[key];
        var index = this.keys.indexOf(key);
        if (index < 0) {
            return;
        }
        this.keys.splice(index, 1);
    };
    LocalWebStorageStore.prototype.setItem = function (key, value) {
        // NOTE: no event support
        this.memory[key] = value;
        if (this.keys.indexOf(key) < 0) {
            this.keys.push(key);
        }
    };
    return LocalWebStorageStore;
}());
export default LocalWebStorageStore;

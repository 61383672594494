import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
  selector: '[completed][formControlName],[completed][formControl],[completed][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CompletedValidatorDirective), multi: true }
  ]
})
export class CompletedValidatorDirective implements Validator {
  constructor(@Attribute('completed') public initialValue: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    const value = c.value;
    // checks if any of the mask guide is still in the value, it shouldn't be
    if (value && value.indexOf('_') >= 0) {
      return {
        notCompleted: true
      };
    } else {
      return null;
    }
  }
}

import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
@Directive({
    selector: '[emailvalid][formControlName],[emailvalid][formControl],[emailvalid][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidatorDirective), multi: true }
    ]
})
export class EmailValidatorDirective implements Validator {
    constructor( @Attribute('emailvalid') public initialValue: string) { }

    validate(c: AbstractControl): { [key: string]: any } {
        let value = c.value;

        if (!value || value.length == 0) {
            return null;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            return {
                emailInvalid: true
            }
        }
        else {
            return null;
        }
    }
}
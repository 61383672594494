import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { Role } from '@xactly-app/common/src/lib/models/Role';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { EventEmitter } from 'events';
import { finalize } from 'rxjs/operators';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';

@Component({
    selector: 'user-component',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent extends BaseComponent implements OnInit {
    @Input() agentUser: AgentUser;
    @Output() userChanged = new EventEmitter<User>();
    @Output() userDeleted = new EventEmitter<User>();
    @ViewChild('userForm') userForm: NgForm;

    public roles: Role[];
    public agencies: Agency[];

    constructor(public apiService: UserService,
        public agentService: AgentService,
        public agencyService: AgencyService,
        public roleService: RoleService,
        public errorTranslationService: ErrorTranslationService,
        public validationService: ValidationService,
        public dialogService: DialogService) {
        super();
    }

    public delete() {
        if (this.agentUser.user.id && this.agentUser.user.id !== null) {
            this.busy();

            this.agentService.getAgentUser(this.agentUser.user.id).pipe(finalize(() => { this.ready() })).subscribe(agentResult => {
                this.busy();
                this.apiService.deleteUser(this.agentUser.user.id).pipe(finalize(() => { this.ready() })).subscribe(result => {
                    this.userDeleted.emit(this.agentUser.user);
                    this.dialogService.showOkDialog('User has been deleted.', 'User');
                },
                    this.errorTranslationService.ErrorHandler
                );
            },
                this.errorTranslationService.ErrorHandler
            );

            this.apiService.deleteUser(this.agentUser.user.id).pipe(finalize(() => { this.ready() })).subscribe(result => {
                this.userDeleted.emit(this.agentUser.user);
                this.dialogService.showOkDialog('User has been deleted.', 'User');
            },
                this.errorTranslationService.ErrorHandler
            );
        }
    }

    public save() {
        this.busy();
        this.apiService.updateUser(this.agentUser.user).pipe(finalize(() => { this.ready() })).subscribe(
            result => {
                if (this.agentUser.agent.id === null) {
                    const agent = new Agent();
                    agent.agencyId = this.agentUser.agent.agencyId;
                    agent.userId = this.agentUser.user.id;
                    this.agentService.createAgent(agent).pipe(finalize(() => { this.ready() })).subscribe(
                        agent => {
                            this.agentUser.agent = agent;
                        }
                    );
                } else {
                    this.agentService.updateAgent(this.agentUser.agent).pipe(finalize(() => { this.ready() })).subscribe(
                        agent => {
                            this.agentUser.agent = agent;
                        }
                    );
                }

                this.dialogService.showOkDialog('User was updated successfully.', 'User');
                this.userChanged.emit(result);
            },
            this.errorTranslationService.ErrorHandler
        );
    }

    ngOnInit() {

        this.agencyService.getAgencys().subscribe(agencies => {
            this.agencies = agencies;
        }, this.errorTranslationService.ErrorHandler);

        this.roleService.getRoles().subscribe(roles => {
            this.roles = roles;
        }, this.errorTranslationService.ErrorHandler);
    }

}

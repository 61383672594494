import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '@xactly-app/material';
import { AlertComponent } from './controls/alert/alert.component';
import { InstructionCardComponent } from './instruction-card/instruction-card.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator';

@NgModule({
  imports: [CommonModule, MaterialModule, NgbModule],
  declarations: [
    LoadingIndicatorComponent,
    InstructionCardComponent,
    AlertComponent
  ],
  exports: [
    LoadingIndicatorComponent,
    InstructionCardComponent,
    AlertComponent
  ]
})
export class XactlyCommonModule {}

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgentUser } from '../models/AgentUser';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class AgentUserService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public updateAgentUser(agentUser: AgentUser): Observable<AgentUser> {
        return this.httpService.put<AgentUser>(
            `${this.configuration.ApiServer}api/AgentUser`,
            JSON.stringify(agentUser)
        );
    }

    public getAgentUserCurrent(): Observable<AgentUser> {
        return this.httpService.get<AgentUser>(`${this.configuration.ApiServer}api/AgentUser/Current`);
    }

    public getAgentUsers(): Observable<AgentUser[]> {
        return this.httpService.get<AgentUser[]>(`${this.configuration.ApiServer}api/AgentUser`);
    }
}

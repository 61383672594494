import { FaqComponent } from './../help/faq/faq.component';
import { HelpComponent } from './../help/help.component';
import { MatSnackBar } from '@angular/material';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(public snackBar: MatSnackBar) { }

  public ShowHelp(helpUrl: string, message: string, buttonMessage: string) {
    const reference = this.snackBar.openFromComponent(HelpComponent,
      { data: { snackBarReference: this.snackBar, helpUrl: helpUrl, message: message, buttonMessage: buttonMessage } }
    );
  }

  public ShowFaq() {
    const reference = this.snackBar.openFromComponent(FaqComponent,
      { data: { snackBarReference: this.snackBar } }
    );
  }
}

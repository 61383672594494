import { Injectable } from '@angular/core';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import emailMask from 'text-mask-addons/dist/emailMask';

@Injectable()
export class ValidationService {
    public PhoneMask = { mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], guide: true, keepCharPositions: true };
    public SINMask = { mask: [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/], guide: true, keepCharPositions: true };
    public DateMask = { pipe: createAutoCorrectedDatePipe('mm/dd/yyyy'), mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] };
    public PostalCodeMask = { mask: [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/] };
    public EmailMask = {
        name: 'Email',
        mask: emailMask,
        placeholder: 'john@smith.com'
    };
    public ExpiryDate = {
        mask: [/[0-9]/, /[0-9]/, '/', /[1-2]/, /[0-9]/],
        keepCharPositions: true,
        guide: true
    };
    constructor() { }

    public getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        const config = {
            'required': 'Required',
            'invalidCreditCard': 'Is invalid credit card number',
            'invalidEmailAddress': 'Invalid email address',
            'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
            'minlength': `Minimum length ${validatorValue.requiredLength}`,
            'invalidPhone': 'Invalid phone number'
        };

        return config[validatorName];
    }
}


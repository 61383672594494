import { InMemoryWebStorage } from 'oidc-client';

export default class LocalWebStorageStore implements InMemoryWebStorage {
  private keys: string[];
  private memory: { [key: string]: string };

  constructor() {
    this.keys = [];
    this.memory = {};
  }

  get length(): number {
    return this.keys.length;
  }

  clear(): void {
    // NOTE: no event support
    this.memory = {};
    this.keys = [];
  }

  public getItem(key: string): string | null {
    return this.memory.hasOwnProperty(key) ? this.memory[key] : null;
  }

  key(n: number): string | null {
    const key: string | undefined = this.keys[n];
    return typeof key === 'undefined' ? null : key;
  }

  removeItem(key: string): void {
    // NOTE: no event support
    delete this.memory[key];
    const index = this.keys.indexOf(key);
    if (index < 0) {
      return;
    }
    this.keys.splice(index, 1);
  }

  setItem(key: string, value: string): void {
    // NOTE: no event support
    this.memory[key] = value;
    if (this.keys.indexOf(key) < 0) {
      this.keys.push(key);
    }
  }
}
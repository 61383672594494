// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.username = null;
        _this.person = null;
        _this.emailVerified = false;
        _this.isActive = false;
        _this.hashedPassword = null;
        _this.roleId = null;
        _this.providerName = null;
        _this.providerSubjectId = null;
        _this.subjectId = null;
        _this.tenantId = null;
        return _this;
    }
    return User;
}(DynamoEntity));
export { User };

import { catchError, timeout } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class HttpService {
    headers;
    constructor(private http: HttpClient, private router: Router) {
        this.headers = new HttpHeaders();
        this.headers.append('Access-Control-Allow-Headers', 'Authorization');
    }

    getBlob<T>(url): Observable<T> {
        return this.http.get<T>(url, { headers: this.headers });
    }

    get<T>(url): Observable<T> {
        return this.http.get<T>(url, { headers: this.headers });
    }

    put<T>(url, data): Observable<T> {
        return this.http.put<T>(url, data, { headers: this.headers });
    }

    post<T>(url, data): Observable<T> {
        return this.http.post<T>(url, data, { headers: this.headers });
    }

    delete<T>(url) {
        return this.http.delete<T>(url, { headers: this.headers });
    }
}

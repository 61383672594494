﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from '../models/Subscription';
import { SubscriptionRequest } from '../models/SubscriptionRequest';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getSubscriptionActives(): Observable<Subscription[]> {
        return this.httpService.get<Subscription[]>(`${this.configuration.ApiServer}api/Subscription/Active`);
    }

    public getSubscriptions(): Observable<Subscription[]> {
        return this.httpService.get<Subscription[]>(`${this.configuration.ApiServer}api/Subscription`);
    }

    public createSubscriptionPurchase(subscriptionRequest: SubscriptionRequest): Observable<Subscription> {
        return this.httpService.post<Subscription>(
            `${this.configuration.ApiServer}api/Subscription/Purchase`,
            JSON.stringify(subscriptionRequest)
        );
    }

    public createSubscription(subscription: Subscription): Observable<Subscription> {
        return this.httpService.post<Subscription>(
            `${this.configuration.ApiServer}api/Subscription`,
            JSON.stringify(subscription)
        );
    }

    public deleteSubscription(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(
            `${this.configuration.ApiServer}api/Subscription/${encodeURIComponent(id)}`
        );
    }

    public updateSubscriptionAgency(subscription: Subscription): Observable<Subscription> {
        return this.httpService.put<Subscription>(
            `${this.configuration.ApiServer}api/Subscription/Agency`,
            JSON.stringify(subscription)
        );
    }
}

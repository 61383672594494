import { Subject, Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable()
export class CacheService {
  private static cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private readonly DEFAULT_MAX_AGE: number = 60000;

  public get<T>(key: string, maxAge?: number): T {
    if (this.hasValidCachedValue(key)) {
      return CacheService.cache.get(key).value;
    } else {
      return null;
    }
  }

  public set<T>(key: string, value: T, maxAge: number = this.DEFAULT_MAX_AGE): void {
    CacheService.cache.set(key, { value: value, expiry: Date.now() + maxAge });
  }

  public has(key: string): boolean {
    return this.hasValidCachedValue(key);
  }

  public invalidate(key: string) {
    if (CacheService.cache.has(key)) {
      CacheService.cache.delete(key);
    }
  }

  private hasValidCachedValue(key: string): boolean {
    if (CacheService.cache.has(key)) {
      if (CacheService.cache.get(key).expiry < Date.now()) {
        CacheService.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common';
import { AgentUserService } from '@xactly-app/common';
import { SubscriptionService } from '@xactly-app/common';
import { Observable } from 'rxjs';
import { shareReplay, finalize, take } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common';
import { CacheService } from '@xactly-app/common';
var HomeComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HomeComponent, _super);
    function HomeComponent(securityService, subscriptionService, agentUserService, router, cacheService) {
        var _this = _super.call(this) || this;
        _this.securityService = securityService;
        _this.subscriptionService = subscriptionService;
        _this.agentUserService = agentUserService;
        _this.router = router;
        _this.cacheService = cacheService;
        _this.IsAuthorized = false;
        _this.Subscriptions = new Array();
        return _this;
    }
    HomeComponent.prototype.UserHasFreeSubscription = function () {
        return false;
        // return this.agentUser != null && this.agentUser.agent.hasFreeSubscription;
    };
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.busy();
        this.securityService.getUser().then(function (user) {
            if (user) {
                _this.IsAuthorized = true;
            }
            else {
                _this.IsAuthorized = false;
            }
            if (_this.IsAuthorized) {
                if (!_this.cacheService.has('AgentCurrentUserObservable')) {
                    _this.cacheService.set('AgentCurrentUserObservable', _this.agentUserService.getAgentUserCurrent().pipe(shareReplay(1)));
                }
                _this.cacheService.get('AgentCurrentUserObservable')
                    .pipe(take(1), finalize(function () { return _this.ready(); }))
                    .subscribe(function (agentCurrentUser) {
                    _this.agentUser = agentCurrentUser;
                });
                if (!_this.cacheService.has('SubscriptionServiceObservable')) {
                    _this.cacheService.set('SubscriptionServiceObservable', _this.subscriptionService.getSubscriptionActives().pipe(shareReplay(1)));
                }
                _this.cacheService.get('SubscriptionServiceObservable')
                    .pipe(take(1), finalize(function () { return _this.ready(); }))
                    .subscribe(function (subscriptions) {
                    _this.Subscriptions = subscriptions;
                    _this.ready();
                });
            }
            else {
                _this.ready();
            }
        });
    };
    HomeComponent.prototype.IsSubscribed = function () {
        return true;
        // return this.Subscriptions !== null && this.Subscriptions.length > 0;
    };
    HomeComponent.prototype.IsAdmin = function () {
        return this.securityService.HasAdminRole;
    };
    HomeComponent.prototype.Logout = function () {
        this.securityService.logout();
    };
    HomeComponent.prototype.Login = function () {
        this.securityService.login();
    };
    HomeComponent.prototype.Register = function () {
        this.router.navigate(['/registration']);
    };
    HomeComponent.prototype.OpenDiagrams = function () {
        this.router.navigate(['/diagrams']);
    };
    HomeComponent.prototype.OpenSubscriptions = function () {
        this.router.navigate(['/subscription/add']);
    };
    return HomeComponent;
}(BaseComponent));
export { HomeComponent };

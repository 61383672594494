import { shareReplay, take, finalize } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseComponent, Utility } from '@xactly-app/common';
import { Subscription } from '@xactly-app/common';
import { SubscriptionService } from '@xactly-app/common';
import { HelpService } from './services/help.service';
import { Observable } from 'rxjs';
import { CacheService } from '@xactly-app/common';
import { AuthenticationService } from '@xactly-app/common';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    public navItems: Array<any>;
    public IsAuthorized = false;
    public Subscriptions = new Array<Subscription>();

    constructor(
        public securityService: AuthenticationService,
        private subscriptionService: SubscriptionService,
        private helpService: HelpService,
        private cacheService: CacheService
    ) {
        super();
    }

    public ShowFaq() {
        this.helpService.ShowFaq();
    }

    LoadNavItems(isAuthorized: boolean) {
        const navigationItems = [];
        navigationItems.push({ name: 'Home', route: 'home' });
        if (isAuthorized) {
            if (this.securityService.HasAdminRole) {
                navigationItems.push({ name: 'Diagrams', route: 'diagrams' });
                navigationItems.push({ name: 'Agencies', route: 'agencies' });
                navigationItems.push({ name: 'Agents', route: 'agents' });
                navigationItems.push({ name: 'Roles', route: 'roles' });
                navigationItems.push({ name: 'Measurements', route: 'measurements' });
                navigationItems.push({ name: 'Subscription Types', route: 'subscriptionTypes' });
                navigationItems.push({ name: 'Settings', route: 'settings' });
                navigationItems.push({ name: 'Support Requests', route: 'support' });
            } else if (this.securityService.HasAgentRole) {
                if (this.IsSubscribed()) {
                    navigationItems.push({ name: 'My Diagrams', route: 'diagrams' });
                }
            } else if (this.securityService.HasManagerRole) {
                if (this.IsSubscribed()) {
                    navigationItems.push({ name: 'My Diagrams', route: 'diagrams' });
                }
                navigationItems.push({ name: 'Agents', route: 'agents' });
            }
            // navigationItems.push({ name: 'Subscriptions', route: 'subscriptions' });
            navigationItems.push({ name: 'Change Password', route: 'changepassword' });
        } else {
            // Not authorized
            navigationItems.push({ name: 'Register', route: 'registration' });
            navigationItems.push({ name: 'Forgot Password', route: 'resetpassword' });
        }
        this.navItems = navigationItems;
        console.log(JSON.stringify(this.navItems));
    }

    public IsSubscribed() {
        return this.Subscriptions !== null && this.Subscriptions.length > 0;
    }

    public GetActiveSubscriptions(isAuthorized: boolean) {
        if (!isAuthorized) {
            this.LoadNavItems(isAuthorized);
            this.ready();
        } else {
            if (!this.cacheService.has('SubscriptionServiceObservable')) {
                this.cacheService.set(
                    'SubscriptionServiceObservable',
                    this.subscriptionService.getSubscriptionActives().pipe(shareReplay(1))
                );
            }
            this.cacheService
                .get<Observable<Subscription[]>>('SubscriptionServiceObservable')
                .subscribe(subscriptions => {
                    this.Subscriptions = subscriptions;
                    this.LoadNavItems(isAuthorized);
                    this.ready();
                });
        }
    }

    ngOnDestroy() {
        this.navItems = [];
    }

    ngOnInit() {
        this.busy();
        this.navItems = [];
        this.securityService.getUser().then(user => {
            this.IsAuthorized = this.securityService.isLoggedIn();
            console.log('GETTING USER:' + this.IsAuthorized);
            this.GetActiveSubscriptions(this.IsAuthorized);
        });

        this.securityService.LoginChanged.subscribe((isAuthorized) => {
            this.IsAuthorized = isAuthorized;
            this.securityService.getUser().then(user => {
                console.log('GETTING USER SESSION:' + this.IsAuthorized);
                this.IsAuthorized = this.securityService.isLoggedIn();
                this.GetActiveSubscriptions(this.IsAuthorized);
            });
        });
    }

    public Login() {
        this.securityService.login();
    }

    public Logout() {
        this.securityService.logout();
    }
}

<instruction-card [message]="'Use this page to edit an agent'" [title]="'Edit an Agent'"></instruction-card>
<form #userForm="ngForm" (ngSubmit)="Save()">
  <mat-card>
    <mat-card-header>
      <loading-indicator *ngIf="loading"></loading-indicator>      
      <mat-card-subtitle>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <div>
          <mat-checkbox [(ngModel)]="this.user.isActive" name="user.isActive">Active</mat-checkbox>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field>
            <mat-select placeholder="Role" [(ngModel)]="user.roleId" name="user.roleId">
              <mat-option *ngFor="let role of roles" [value]="role.id">
                {{role.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field>
          <mat-select placeholder="Agency" [(ngModel)]="agent.agencyId" name="agency.id">
            <mat-option *ngFor="let agency of agencies" [value]="agency.id">
              {{agency.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [textMask]="validationService.EmailMask" [(ngModel)]="user.person.email" placeholder="Email" type="text"
            name="userpersonemail" #userpersonemail="ngModel" required emailvalid>
          <mat-error *ngIf="userpersonemail.errors?.required">Email is required</mat-error>
          <mat-error *ngIf="userpersonemail.errors?.emailInvalid">Email is not valid</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="user.person.firstName" placeholder="First Name" maxlength="200" type="text" name="userpersonfirstName"
            #userpersonfirstName="ngModel" required>
          <mat-error *ngIf="userpersonfirstName.errors?.required">First name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [(ngModel)]="user.person.lastName" #userpersonlastName="ngModel" placeholder="Last Name" type="text" maxlength="200"
            name="userpersonlastName" required>
          <mat-error *ngIf="userpersonlastName.errors?.required">Last name is required</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [textMask]="validationService.PhoneMask" [(ngModel)]="user.person.primaryPhone" placeholder="Primary Phone"
            type="text" name="userpersonprimaryPhone" #userpersonprimaryPhone="ngModel" required completed>
          <mat-error *ngIf="userpersonprimaryPhone.errors?.required">Phone number is required</mat-error>
          <mat-error *ngIf="userpersonprimaryPhone.errors?.notCompleted">Phone number is not valid</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput [textMask]="validationService.PhoneMask" [(ngModel)]="user.person.secondaryPhone" placeholder="Secondary Phone"
            type="text" name="userpersonsecondaryPhone" #userpersonsecondaryPhone="ngModel" completed>
          <mat-error *ngIf="userpersonsecondaryPhone.errors?.required">Phone number is required</mat-error>
          <mat-error *ngIf="userpersonsecondaryPhone.errors?.notCompleted">Phone number is not valid</mat-error>
        </mat-form-field>
      </div>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>map</mat-icon> Address
            </mat-panel-title>
          </mat-expansion-panel-header>
          <address-component [form]="userForm" [address]="user.person.address"></address-component>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
    <mat-card-actions>
      <loading-indicator *ngIf="loading"></loading-indicator>
      <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">Save</button>
      <button mat-raised-button color="warn" (click)="Cancel()" type="button">Cancel</button>
      <button mat-raised-button color="warn" (click)="ResetPassword()" type="button">Reset Password</button>
    </mat-card-actions>
  </mat-card>
</form>
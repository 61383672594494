import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { finalize } from 'rxjs/operators';
var SubscriptionTypeListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionTypeListComponent, _super);
    function SubscriptionTypeListComponent(errorTranslationService, dialogService, router, activatedRoute, subscriptionTypeService) {
        var _this = _super.call(this) || this;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.subscriptionTypeService = subscriptionTypeService;
        return _this;
    }
    SubscriptionTypeListComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    SubscriptionTypeListComponent.prototype.Edit = function (subscriptionType) {
        this.router.navigate(['/subscriptionType', subscriptionType.id]);
    };
    SubscriptionTypeListComponent.prototype.Add = function () {
        this.router.navigate(['/subscriptionType']);
    };
    SubscriptionTypeListComponent.prototype.Delete = function (subscriptionType) {
        var _this = this;
        this.dialogService.showConfirmDialog('Are you sure you want to remove this subscription type?', 'Remove Subscription Type').subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.busy();
                _this.subscriptionTypeService.deleteSubscriptionType(subscriptionType.id).pipe(finalize(function () { _this.ready(); })).subscribe(function () {
                    _this.Load();
                }, _this.errorTranslationService.ErrorHandler);
            }
        });
    };
    SubscriptionTypeListComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        return this.subscriptionTypeService.getSubscriptionTypes().pipe(finalize(function () { _this.ready(); })).subscribe(function (subscriptionTypes) {
            _this.subscriptionTypes = subscriptionTypes;
        }, this.errorTranslationService.ErrorHandler);
    };
    return SubscriptionTypeListComponent;
}(BaseComponent));
export { SubscriptionTypeListComponent };

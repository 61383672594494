import * as tslib_1 from "tslib";
import { shareReplay } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BaseComponent } from '@xactly-app/common';
import { SubscriptionService } from '@xactly-app/common';
import { HelpService } from './services/help.service';
import { CacheService } from '@xactly-app/common';
import { AuthenticationService } from '@xactly-app/common';
var AppComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppComponent, _super);
    function AppComponent(securityService, subscriptionService, helpService, cacheService) {
        var _this = _super.call(this) || this;
        _this.securityService = securityService;
        _this.subscriptionService = subscriptionService;
        _this.helpService = helpService;
        _this.cacheService = cacheService;
        _this.IsAuthorized = false;
        _this.Subscriptions = new Array();
        return _this;
    }
    AppComponent.prototype.ShowFaq = function () {
        this.helpService.ShowFaq();
    };
    AppComponent.prototype.LoadNavItems = function (isAuthorized) {
        var navigationItems = [];
        navigationItems.push({ name: 'Home', route: 'home' });
        if (isAuthorized) {
            if (this.securityService.HasAdminRole) {
                navigationItems.push({ name: 'Diagrams', route: 'diagrams' });
                navigationItems.push({ name: 'Agencies', route: 'agencies' });
                navigationItems.push({ name: 'Agents', route: 'agents' });
                navigationItems.push({ name: 'Roles', route: 'roles' });
                navigationItems.push({ name: 'Measurements', route: 'measurements' });
                navigationItems.push({ name: 'Subscription Types', route: 'subscriptionTypes' });
                navigationItems.push({ name: 'Settings', route: 'settings' });
                navigationItems.push({ name: 'Support Requests', route: 'support' });
            }
            else if (this.securityService.HasAgentRole) {
                if (this.IsSubscribed()) {
                    navigationItems.push({ name: 'My Diagrams', route: 'diagrams' });
                }
            }
            else if (this.securityService.HasManagerRole) {
                if (this.IsSubscribed()) {
                    navigationItems.push({ name: 'My Diagrams', route: 'diagrams' });
                }
                navigationItems.push({ name: 'Agents', route: 'agents' });
            }
            // navigationItems.push({ name: 'Subscriptions', route: 'subscriptions' });
            navigationItems.push({ name: 'Change Password', route: 'changepassword' });
        }
        else {
            // Not authorized
            navigationItems.push({ name: 'Register', route: 'registration' });
            navigationItems.push({ name: 'Forgot Password', route: 'resetpassword' });
        }
        this.navItems = navigationItems;
        console.log(JSON.stringify(this.navItems));
    };
    AppComponent.prototype.IsSubscribed = function () {
        return this.Subscriptions !== null && this.Subscriptions.length > 0;
    };
    AppComponent.prototype.GetActiveSubscriptions = function (isAuthorized) {
        var _this = this;
        if (!isAuthorized) {
            this.LoadNavItems(isAuthorized);
            this.ready();
        }
        else {
            if (!this.cacheService.has('SubscriptionServiceObservable')) {
                this.cacheService.set('SubscriptionServiceObservable', this.subscriptionService.getSubscriptionActives().pipe(shareReplay(1)));
            }
            this.cacheService
                .get('SubscriptionServiceObservable')
                .subscribe(function (subscriptions) {
                _this.Subscriptions = subscriptions;
                _this.LoadNavItems(isAuthorized);
                _this.ready();
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.navItems = [];
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.busy();
        this.navItems = [];
        this.securityService.getUser().then(function (user) {
            _this.IsAuthorized = _this.securityService.isLoggedIn();
            console.log('GETTING USER:' + _this.IsAuthorized);
            _this.GetActiveSubscriptions(_this.IsAuthorized);
        });
        this.securityService.LoginChanged.subscribe(function (isAuthorized) {
            _this.IsAuthorized = isAuthorized;
            _this.securityService.getUser().then(function (user) {
                console.log('GETTING USER SESSION:' + _this.IsAuthorized);
                _this.IsAuthorized = _this.securityService.isLoggedIn();
                _this.GetActiveSubscriptions(_this.IsAuthorized);
            });
        });
    };
    AppComponent.prototype.Login = function () {
        this.securityService.login();
    };
    AppComponent.prototype.Logout = function () {
        this.securityService.logout();
    };
    return AppComponent;
}(BaseComponent));
export { AppComponent };

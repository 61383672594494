import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable()
export class CellStateService {

    private selectionState;
    constructor(private winService: WindowService) { }

    public GetSelectionState(graph) {
        if (this.selectionState === null) {
            this.selectionState = this.CreateSelectionState(graph);
        }
        return this.selectionState;
    }

    public CreateSelectionState(graph) {
        let cells = graph.getSelectionCells();
        var result = this.InitSelectionState();
        for (var i = 0; i < cells.length; i++) {
            this.UpdateSelectionStateForCell(graph, result, cells[i], cells);
        }
        return result;
    }

    IsAutoSizeState(state) {
        return this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_AUTOSIZE, null) === '1';
    }

    IsImageState(state) {
        let shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);

        return (shape === 'label' || shape === 'image');
    }

    IsShadowState(state) {
        var shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);
        return (shape !== 'image');
    }

    InitSelectionState() {
        return {
            vertices: [], edges: [], x: null, y: null, width: null, height: null, style: {},
            containsImage: false, containsLabel: false, fill: true, glass: true, rounded: true,
            comic: true, autoSize: false, image: true, shadow: true, lineJumps: true
        };
    }


    IsFillState(state) {
        return state.view.graph.model.isVertex(state.cell) ||
            this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null) === 'arrow' ||
            this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null) === 'filledEdge' ||
            this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null) === 'flexArrow';
    }

    IsGlassState(state) {
        let shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);
        return (shape === 'label' || shape === 'rectangle' || shape === 'internalStorage' ||
            shape === 'ext' || shape === 'umlLifeline' || shape === 'swimlane' ||
            shape === 'process');
    }

    IsRoundedState(state) {
        var shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);

        return (shape === 'label' || shape === 'rectangle' || shape === 'internalStorage' || shape === 'corner' ||
            shape === 'parallelogram' || shape === 'swimlane' || shape === 'triangle' || shape === 'trapezoid' ||
            shape === 'ext' || shape === 'step' || shape === 'tee' || shape === 'process' || shape === 'link' ||
            shape === 'rhombus' || shape === 'offPageConnector' || shape === 'loopLimit' || shape === 'hexagon' ||
            shape === 'manualInput' || shape === 'curlyBracket' || shape === 'singleArrow' || shape === 'callout' ||
            shape === 'doubleArrow' || shape === 'flexArrow' || shape === 'card' || shape === 'umlLifeline');
    }

    IsLineJumpState(state) {
        let shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);
        return shape === 'connector' || shape === 'filledEdge';
    }

    IsComicState = function (state) {
        let shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);
        return this.winService.nativeWindow.mxUtils.indexOf(['label', 'rectangle', 'internalStorage', 'corner', 'parallelogram', 'note', 'collate',
            'swimlane', 'triangle', 'trapezoid', 'ext', 'step', 'tee', 'process', 'link', 'rhombus',
            'offPageConnector', 'loopLimit', 'hexagon', 'manualInput', 'singleArrow', 'doubleArrow',
            'flexArrow', 'filledEdge', 'card', 'umlLifeline', 'connector', 'folder', 'component', 'sortShape',
            'cross', 'umlFrame', 'cube', 'isoCube', 'isoRectangle', 'partialRectangle'], shape) >= 0;
    }

    UpdateSelectionStateForCell(graph, result, cell, cells) {
        if (graph.getModel().isVertex(cell)) {
            result.vertices.push(cell);
            let geo = graph.getCellGeometry(cell);

            if (geo !== null) {
                if (geo.width > 0) {
                    if (result.width === null) {
                        result.width = geo.width;
                    }
                    else if (result.width !== geo.width) {
                        result.width = '';
                    }
                }
                else {
                    result.containsLabel = true;
                }

                if (geo.height > 0) {
                    if (result.height === null) {
                        result.height = geo.height;
                    }
                    else if (result.height !== geo.height) {
                        result.height = '';
                    }
                }
                else {
                    result.containsLabel = true;
                }

                if (!geo.relative || geo.offset !== null) {
                    const x = (geo.relative) ? geo.offset.x : geo.x;
                    const y = (geo.relative) ? geo.offset.y : geo.y;

                    if (result.x === null) {
                        result.x = x;
                    }
                    else if (result.x !== x) {
                        result.x = '';
                    }

                    if (result.y === null) {
                        result.y = y;
                    }
                    else if (result.y !== y) {
                        result.y = '';
                    }
                }
            }
        } else if (graph.getModel().isEdge(cell)) {
            result.edges.push(cell);
        }

        const state = graph.view.getState(cell);

        if (state !== null) {
            result.autoSize = result.autoSize || this.IsAutoSizeState(state);
            result.glass = result.glass && this.IsGlassState(state);
            result.rounded = result.rounded && this.IsRoundedState(state);
            result.lineJumps = result.lineJumps && this.IsLineJumpState(state);
            result.comic = result.comic && this.IsComicState(state);
            result.image = result.image && this.IsImageState(state);
            result.shadow = result.shadow && this.IsShadowState(state);
            result.fill = result.fill && this.IsFillState(state);

            let shape = this.winService.nativeWindow.mxUtils.getValue(state.style, this.winService.nativeWindow.mxConstants.STYLE_SHAPE, null);
            result.containsImage = result.containsImage || shape === 'image';

            for (let key in state.style) {
                let value = state.style[key];

                if (value !== null) {
                    if (result.style[key] === null) {
                        result.style[key] = value;
                    }
                    else if (result.style[key] !== value) {
                        result.style[key] = '';
                    }
                }
            }
        }
    };
}

﻿import { Injectable } from '@angular/core';

@Injectable()
export class DateService {    
    constructor() {
    }            

    //So this is complicated.
    //  Need to convert the UTC date string to Local string.  Server-side always saves UTC dates.
    public parseDateToStringWithFormat(date: Date): string {
        let result: string;
        let dd = date.getDate().toString();
        let mm = (date.getMonth() + 1).toString();
        let hh = date.getHours().toString();
        let min = date.getMinutes().toString();
        dd = dd.length === 2 ? dd : "0" + dd;
        mm = mm.length === 2 ? mm : "0" + mm;
        hh = hh.length === 2 ? hh : "0" + hh;
        min = min.length === 2 ? min : "0" + min;
        result = [date.getFullYear(), '-', mm, '-', dd, 'T', hh, ':', min].join('');
        return result;
    }    

    public parseDateForTimePicker(date: Date): string {
        let result: string;
        let dd = date.getDate().toString();
        let mm = (date.getMonth() + 1).toString();
        let hh = date.getHours().toString();
        let min = date.getMinutes().toString();
        dd = dd.length === 2 ? dd : "0" + dd;
        mm = mm.length === 2 ? mm : "0" + mm;
        hh = hh.length === 2 ? hh : "0" + hh;
        min = min.length === 2 ? min : "0" + min;
        result = [mm, '/', dd, '/', date.getFullYear()].join('');
        return result;
    }    
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  public passwordResetRequest: PasswordResetRequest;
  constructor(public apiService: PasswordResetService,
    public router: Router,
    public errorTranslationService: ErrorTranslationService,
    public validationService: ValidationService,
    public dialogService: DialogService,
    public securityService: AuthenticationService,
    public activatedRoute: ActivatedRoute) {
    super();
    this.passwordResetRequest = new PasswordResetRequest();
    this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
  }

  ngOnInit() {

  }

  resetPassword() {
    this.busy();
    this.apiService.createPasswordResetResetPassword(this.passwordResetRequest).pipe(finalize(() => { this.ready(); })).subscribe(result => {
      if (result) {
        this.dialogService.showOkDialog('Password reset email has been sent.', 'Password Reset').subscribe(e => {
          this.securityService.login('/');
        });
      } else {
        this.dialogService.showOkDialog('Password reset failed, please try again or contact support.', 'Password Reset Error');
      }
    },
      this.errorTranslationService.ErrorHandler);
  }
}

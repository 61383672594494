import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var SubscriptionTypeService = /** @class */ (function () {
    function SubscriptionTypeService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    SubscriptionTypeService.prototype.getSubscriptionTypes = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription/Type");
    };
    SubscriptionTypeService.prototype.getSubscriptionType = function (id) {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription/Type/" + encodeURIComponent(id));
    };
    SubscriptionTypeService.prototype.getSubscriptionTypeAgencys = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription/Type/Agency");
    };
    SubscriptionTypeService.prototype.getSubscriptionTypeAgents = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Subscription/Type/Agent");
    };
    SubscriptionTypeService.prototype.createSubscriptionType = function (subscriptionType) {
        return this.httpService.post(this.configuration.ApiServer + "api/Subscription/Type", JSON.stringify(subscriptionType));
    };
    SubscriptionTypeService.prototype.deleteSubscriptionType = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Subscription/Type/" + encodeURIComponent(id));
    };
    SubscriptionTypeService.prototype.updateSubscriptionType = function (subscriptionType) {
        return this.httpService.put(this.configuration.ApiServer + "api/Subscription/Type", JSON.stringify(subscriptionType));
    };
    SubscriptionTypeService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionTypeService_Factory() { return new SubscriptionTypeService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: SubscriptionTypeService, providedIn: "root" });
    return SubscriptionTypeService;
}());
export { SubscriptionTypeService };

import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
var AgencyAddComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgencyAddComponent, _super);
    function AgencyAddComponent(router, errorTranslationService, validationService, dialogService, agencyService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.agencyService = agencyService;
        return _this;
    }
    AgencyAddComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    AgencyAddComponent.prototype.Load = function () {
        this.agency = new Agency();
    };
    AgencyAddComponent.prototype.Cancel = function () {
        this.router.navigate(['/agencies']);
    };
    AgencyAddComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.agencyService.createAgency(this.agency).pipe(finalize(function () { _this.ready(); })).subscribe(function (agency) {
            _this.agency = agency;
            _this.router.navigate(['/agencies']);
        }, this.errorTranslationService.ErrorHandler);
    };
    return AgencyAddComponent;
}(BaseComponent));
export { AgencyAddComponent };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common';
import { MeasurementUnit } from '@xactly-app/common';
import { Router } from '@angular/router';
import { ErrorTranslationService } from '@xactly-app/common';
import { ValidationService } from '@xactly-app/common';
import { DialogService } from '@xactly-app/common';
import { MeasurementUnitService } from '@xactly-app/common';
import { finalize } from 'rxjs/operators';
var MeasurementAddComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeasurementAddComponent, _super);
    function MeasurementAddComponent(router, errorTranslationService, validationService, dialogService, measurementUnitService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.measurementUnitService = measurementUnitService;
        return _this;
    }
    MeasurementAddComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    MeasurementAddComponent.prototype.Load = function () {
        this.measurementUnit = new MeasurementUnit();
    };
    MeasurementAddComponent.prototype.Cancel = function () {
        this.router.navigate(['/measurementUnits']);
    };
    MeasurementAddComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.measurementUnitService.createMeasurementUnit(this.measurementUnit).pipe(finalize(function () { _this.ready(); })).subscribe(function (measurementUnit) {
            _this.measurementUnit = measurementUnit;
            _this.router.navigate(['/measurementUnits']);
        }, this.errorTranslationService.ErrorHandler);
    };
    return MeasurementAddComponent;
}(BaseComponent));
export { MeasurementAddComponent };

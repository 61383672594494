import * as tslib_1 from "tslib";
import { AfterViewChecked, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { MeasurementUnit } from '@xactly-app/common/src/lib/models/MeasurementUnit';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { MeasurementUnitService } from '@xactly-app/common/src/lib/services/MeasurementUnitService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';
var MeasurementEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeasurementEditComponent, _super);
    function MeasurementEditComponent(winService, stateService, measurementUnitService, dialogService, validationService, errorTranslationService, activatedRoute, router) {
        var _this = _super.call(this) || this;
        _this.winService = winService;
        _this.stateService = stateService;
        _this.measurementUnitService = measurementUnitService;
        _this.dialogService = dialogService;
        _this.validationService = validationService;
        _this.errorTranslationService = errorTranslationService;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.measurementUnit = new MeasurementUnit();
        _this.measurementUnit = new MeasurementUnit();
        return _this;
    }
    MeasurementEditComponent.prototype.ngAfterViewChecked = function () {
    };
    MeasurementEditComponent.prototype.ngAfterViewInit = function () {
    };
    MeasurementEditComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    MeasurementEditComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.measurementUnitService.updateMeasurementUnit(this.measurementUnit).pipe(finalize(function () { _this.ready(); })).subscribe(function (measurementUnit) {
            _this.measurementUnit = measurementUnit;
            _this.router.navigate(['/measurements']);
        }, this.errorTranslationService.ErrorHandler);
    };
    MeasurementEditComponent.prototype.Cancel = function () {
        this.router.navigate(['/measurements']);
    };
    MeasurementEditComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        this.activatedRoute.paramMap
            .pipe(switchMap(function (params) {
            return _this.measurementUnitService.getMeasurementUnit(params.get('id')).pipe(finalize(function () { _this.ready(); }));
        }), finalize(function () { _this.ready(); })).subscribe(function (measurementUnit) {
            _this.measurementUnit = measurementUnit;
        }, this.errorTranslationService.ErrorHandler);
    };
    return MeasurementEditComponent;
}(BaseComponent));
export { MeasurementEditComponent };

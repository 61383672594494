import { Shape } from "./shape";
import { SideCoordinate } from "./side-coordinate";

export class Rectangle extends Shape {

    public constructor() {
        super();
        this.Name = 'rectangle';
        this.AddTop(this.Top);
        this.AddBottom(this.Bottom);
        this.AddRight(this.Right);
        this.AddLeft(this.Left);
    }
    
    private AddBottom(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    }

    private AddTop(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    }

    private AddRight(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    }

    private AddLeft(sideCoordinates: SideCoordinate[]) {        
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: 'N' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: 'E' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: 'S' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: 'W' });
    }  
}

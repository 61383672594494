import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionService } from '@xactly-app/common/src/lib/services/SubscriptionService';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
var SubscriptionListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionListComponent, _super);
    function SubscriptionListComponent(subscriptionService, subscriptionTypeService, errorTranslationService, agentService, agencyService, dialogService, userService, router, activatedRoute) {
        var _this = _super.call(this) || this;
        _this.subscriptionService = subscriptionService;
        _this.subscriptionTypeService = subscriptionTypeService;
        _this.errorTranslationService = errorTranslationService;
        _this.agentService = agentService;
        _this.agencyService = agencyService;
        _this.dialogService = dialogService;
        _this.userService = userService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.subscriptionInformationList = [];
        return _this;
    }
    SubscriptionListComponent.prototype.ngOnInit = function () {
        this.LoadSubscriptions();
    };
    SubscriptionListComponent.prototype.Add = function () {
        this.router.navigate(['/subscription/add']);
    };
    SubscriptionListComponent.prototype.LoadSubscriptions = function () {
        var _this = this;
        this.busy();
        var subscriptionTypeMap = new Map();
        var agentMap = new Map();
        var userMap = new Map();
        var agencyMap = new Map();
        forkJoin(this.subscriptionService.getSubscriptions(), this.subscriptionTypeService.getSubscriptionTypes(), this.agentService.getAgents(), this.userService.getUsers(), this.agencyService.getAgencys()).pipe(finalize(function () { return _this.ready(); })).subscribe(function (results) {
            var subscriptions = results[0];
            var subscriptionTypes = results[1];
            var agents = results[2];
            var users = results[3];
            var agencies = results[4];
            subscriptionTypes.forEach(function (subscriptionType) {
                subscriptionTypeMap[subscriptionType.id] = subscriptionType;
            });
            users.forEach(function (user) {
                userMap[user.id] = user;
            });
            agents.forEach(function (agent) {
                agentMap[agent.id] = agent;
            });
            agencies.forEach(function (agency) {
                agencyMap[agency.id] = agency;
            });
            subscriptions.forEach(function (subscription) {
                var subscriptionType = subscriptionTypeMap[subscription.subscriptionTypeId];
                var agency = null;
                var agent = null;
                var user = null;
                if (Utility.IsEmptyOrNull(subscription.agencyId)) {
                    agent = agentMap[subscription.agentId];
                    if (Utility.IsEmptyOrNull(agent.userId)) {
                        agency = agencyMap[agent.agencyId];
                    }
                    else {
                        user = userMap[agent.userId];
                    }
                }
                else {
                    agency = agencyMap[subscription.agencyId];
                }
                _this.subscriptionInformationList.push({
                    description: subscriptionType.description,
                    startDate: new Date(subscription.startDate),
                    duration: subscriptionType.durationDays,
                    maximumUsers: subscriptionType.maximumUsers,
                    name: Utility.IsEmptyOrNull(subscription.agencyId) ? user.person.firstName + ', ' + user.person.lastName : agency.name
                });
            });
        });
    };
    return SubscriptionListComponent;
}(BaseComponent));
export { SubscriptionListComponent };

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Registration } from '../models/Registration';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public createRegistration(registration: Registration): Observable<Registration> {
        return this.httpService.post<Registration>(
            `${this.configuration.ApiServer}api/Registration`,
            JSON.stringify(registration)
        );
    }
}

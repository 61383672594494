import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { Registration } from '@xactly-app/common/src/lib/models/Registration';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { RegistrationService } from '@xactly-app/common/src/lib/services/RegistrationService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'registration-component',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent extends BaseComponent implements OnInit {
    public registration: Registration;
    public disclaimerAccepted = false;

    constructor(
        public userService: UserService,
        public agentService: AgentService,
        public router: Router,
        public errorTranslationService: ErrorTranslationService,
        public validationService: ValidationService,
        public dialogService: DialogService,
        public registrationService: RegistrationService
    ) {
        super();
        this.registration = new Registration();
        this.registration.agent = new Agent();
        this.registration.user = new User();
        this.registration.user.person = new Person();
        this.registration.user.person.address = new Address();
        this.registration.changePasswordUrl = Utility.GetServer() + 'changepassword';
    }

    public register() {
        this.busy();
        this.registrationService
            .createRegistration(this.registration)
            .pipe(
                finalize(() => {
                    this.ready();
                })
            )
            .subscribe(resultRegistration => {
                this.dialogService
                    .showOkDialog(
                        'You have been registered successfully.  You will receive an email with instructions on how to set your password. Please ensure that this email is not in your spam folder.',
                        'Registration'
                    )
                    .subscribe(e => {
                        this.router.navigate(['/home']);
                    });
            }, this.errorTranslationService.ErrorHandler);
    }

    ngOnInit() {}
}

import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var SettingService = /** @class */ (function () {
    function SettingService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    SettingService.prototype.getSettings = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Setting");
    };
    SettingService.prototype.getSetting = function (name) {
        return this.httpService.get(this.configuration.ApiServer + "api/Setting/" + encodeURIComponent(name));
    };
    SettingService.prototype.updateSetting = function (setting) {
        return this.httpService.put(this.configuration.ApiServer + "api/Setting", JSON.stringify(setting));
    };
    SettingService.prototype.createSetting = function (setting) {
        return this.httpService.post(this.configuration.ApiServer + "api/Setting", JSON.stringify(setting));
    };
    SettingService.prototype.deleteSetting = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Setting/" + encodeURIComponent(id));
    };
    SettingService.ngInjectableDef = i0.defineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: SettingService, providedIn: "root" });
    return SettingService;
}());
export { SettingService };

import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';
import { Utility } from '../utility';
import { ErrorCodes } from '../enums/error-codes.enum';
@Injectable()
export class ErrorTranslationService {
    private static dialogServiceSingleton: DialogService;

    constructor(private dialogService: DialogService) {
        ErrorTranslationService.dialogServiceSingleton = dialogService;
    }

    public static GetErrorObject(error: any): any {
        if (error.constructor === 'test'.constructor) {
            try {
                // This is a string, most likely json, convert to object.
                error = error.json(); // convert response to contents.
            } catch (exception) {
                alert('Error parsing ' + error);
            }
        }
        return error;
    }

    public static GetErrorMessage(error: any): string {
        let errorMessage = 'Unknown error';
        // let errorObject = ErrorTranslationService.GetErrorObject(error);

        if (!Utility.IsEmptyOrNull(error.body)) {
            errorMessage = error.body;
        } else if (!Utility.IsEmptyOrNull(error._body)) {
            errorMessage = error._body;
            if (!Utility.IsNull(error.statusText)) {
                if (error.statusText === '') {
                    errorMessage = 'There was an error processing your request, services could not be reached at this time. Error:' + error._body;
                }
            }
        } else {
            errorMessage = 'An unexpected error was encountered, Support has been notified.  Please try this operation again.';
            if (!Utility.IsEmptyOrNull(error.error)) {
                if (!Utility.IsEmptyOrNull(error.error.exception)) {
                    if (!Utility.IsEmptyOrNull(error.error.exception.Message)) {
                        errorMessage = error.error.exception.Message;
                    }
                } else {
                    if (error.error === 'SubscriptionRequired') {
                        errorMessage = 'In order to create or edit diagrams a subscription is required.';
                    }
                }
            }
        }
        return errorMessage;
    }

    public static GetErrorCode(error: any): ErrorCodes {
        let errorCode: ErrorCodes = ErrorCodes.Unknown;
        if (!Utility.IsEmptyOrNull(error)) {
            if (!Utility.IsEmptyOrNull(error.status)) {
                if (error.status === 401) {
                    errorCode = ErrorCodes.AccessDenied;
                } else if (error.status === 403) {
                    errorCode = ErrorCodes.Forbidden;
                }
            }
            if (!Utility.IsEmptyOrNull(error.exception) /*&& check exception message for keyword mongo*/) {
                errorCode = ErrorTranslationService.HandleMongoException(error.exception);
            }

            if (!Utility.IsEmptyOrNull(error) && !Utility.IsEmptyOrNull(error.error) && !Utility.IsEmptyOrNull(error.error.exception)) {
                errorCode = ErrorTranslationService.HandleMongoException(error.error.exception);
            }
        }
        return errorCode;
    }

    private static HandleMongoException(exception: any): ErrorCodes {

        let errorCode: ErrorCodes = ErrorCodes.Unknown;
        if (!Utility.IsEmptyOrNull(exception.writeError)) {
            errorCode = ErrorTranslationService.HandleMongoWriteError(exception.writeError);
        } else {

        }

        // TODO: ... add more handlers based on exception structure.
        // https://github.com/mongodb/mongo/blob/v2.4/docs/errors.md
        return errorCode;
    }

    private static HandleMongoWriteError(writeError: any): ErrorCodes {
        let errorCode: ErrorCodes = ErrorCodes.Unknown;
        if (writeError.code) {
            const writeErrorCode = writeError.code;
            if (writeErrorCode === 11000) {
                errorCode = ErrorCodes.ObjectAlreadyExistsInDatabase;
            }
        }
        return errorCode;
    }

    public ErrorHandler(error) {
        const errorCode = ErrorTranslationService.GetErrorCode(error);
        if (errorCode === ErrorCodes.ObjectAlreadyExistsInDatabase) {
            ErrorTranslationService.dialogServiceSingleton.showOkDialog('This item already exists in the system.', 'Error');
        } else if (errorCode === ErrorCodes.Forbidden) {
            ErrorTranslationService.dialogServiceSingleton.showOkDialog('You do not have access to this operation.', 'Error');
        } else if (errorCode === ErrorCodes.AccessDenied) {
            ErrorTranslationService.dialogServiceSingleton.showOkDialog('Your session has expired, you will need to refresh your browser and log in again.', 'Session Expired');
        } else {
            ErrorTranslationService.dialogServiceSingleton.showOkDialog(ErrorTranslationService.GetErrorMessage(error), 'Error');
        }
    }
}

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from '../models/Permission';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable()
export class PermissionService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }
    public getPermissions(): Observable<Permission[]> {
        return this.httpService.get(`${this.configuration.UserManagementApiServer}api/Permission`);
    }
    public getPermission(id: string): Observable<Permission> {
        return this.httpService.get(
            `${this.configuration.UserManagementApiServer}api/Permission/${encodeURIComponent(id)}`
        );
    }
    public updatePermission(permission: Permission): Observable<Permission> {
        return this.httpService.put(
            `${this.configuration.UserManagementApiServer}api/Permission`,
            JSON.stringify(permission)
        );
    }
    public createPermission(permission: Permission): Observable<Permission> {
        return this.httpService.post(
            `${this.configuration.UserManagementApiServer}api/Permission`,
            JSON.stringify(permission)
        );
    }
    public deletePermission(id: string): Observable<boolean> {
        return this.httpService.delete(
            `${this.configuration.UserManagementApiServer}api/Permission?id=${encodeURIComponent(id)}`
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize, take } from 'rxjs/operators';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';

@Component({
    selector: 'users-view-component',
    templateUrl: './users-view.component.html',
    styleUrls: ['./users-view.component.css']
})
export class UsersViewComponent extends BaseComponent implements OnInit {

    private agentUsers: AgentUser[];
    public showInstructions: boolean;

    constructor(private apiService: UserService,
        private agentService: AgentService,
        private agencyService: AgencyService,
        private errorTranslationService: ErrorTranslationService) {
        super();
    }

    userDeleted(user: User) {
        this.load();
    }

    userChanged(user: User) {

    }

    load() {
        this.agentUsers = new Array<AgentUser>();
        this.busy();
        this.apiService.getUsers().pipe(finalize(() => { this.ready(); })).subscribe(
            users => {
                users.forEach((user: User, index: number, array: User[]) => {
                    const agentUser = new AgentUser();
                    agentUser.user = user;
                    agentUser.agent = new Agent();
                    agentUser.agent.agencyId = '';
                    agentUser.agency = new Agency();
                    this.agentUsers.push(agentUser);

                    this.agentService.getAgentUser(user.id).pipe(finalize(() => { this.ready(); })).subscribe(
                        agent => {
                            if (agent !== null) {
                                agentUser.agent = agent;

                                if (agent.agencyId !== null) {
                                    this.agencyService.getAgency(agent.agencyId)
                                    .pipe(take(1), finalize(() => { this.ready(); })).subscribe(agency => {
                                        if (agency !== null) {
                                            agentUser.agency = agency;
                                        }
                                    });
                                } else {
                                    agent.agencyId = '0';
                                }
                            }
                        });
                });
            },
            this.errorTranslationService.ErrorHandler);
    }
    ngOnInit() {
        this.showInstructions = true;
        this.load();
    }

}

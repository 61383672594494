import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { Role } from '@xactly-app/common/src/lib/models/Role';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { finalize } from 'rxjs/operators';
import { environment } from 'apps/xactly-app/src/environments/environment';
import { Utility } from '@xactly-app/common/src/lib/utility';

@Component({
  selector: 'app-agent-add',
  templateUrl: './agent-add.component.html',
  styleUrls: ['./agent-add.component.scss']
})
export class AgentAddComponent extends BaseComponent implements OnInit {
  public agency: Agency;
  public agent: Agent;
  public user: User;
  public agencies: Array<Agency>;
  public roles: Array<Role>;
  public passwordResetRequest: PasswordResetRequest;

  constructor(
    public passwordResetService: PasswordResetService,
    public agentService: AgentService,
    public router: Router,
    public errorTranslationService: ErrorTranslationService,
    public validationService: ValidationService,
    public dialogService: DialogService,
    public agencyService: AgencyService,
    public rolesService: RoleService,
    public userService: UserService) {
    super();

    this.passwordResetRequest = new PasswordResetRequest();
    this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
  }

  ngOnInit() {
    this.agency = new Agency();
    this.agent = new Agent();
    this.user = new User();
    this.user.person = new Person();
    this.user.person.address = new Address();
    this.Load();
  }

  private Load() {
    this.busy();
    this.agencyService.getAgencys().pipe(finalize(() => { this.ready(); })).subscribe(
      agencies => {
        this.busy();
        this.agencies = agencies;
        this.agency.id = agencies[0].id;
        this.rolesService.getRoles().pipe(finalize(() => { this.ready(); })).subscribe(
          roles => {
            this.roles = roles;
            this.user.roleId = roles[0].id;
          },
          this.errorTranslationService.ErrorHandler);
      },
      this.errorTranslationService.ErrorHandler);
  }

  public Cancel() {
    this.router.navigate(['/agents']);
  }

  public Save() {
    this.busy();
    this.user.tenantId = this.agency.id;
    this.userService.createUser(this.user).pipe(finalize(() => { this.ready(); })).subscribe(
      user => {
        this.busy();
        this.agent.userId = user.id;
        this.agent.agencyId = this.agency.id;
        this.agentService.createAgent(this.agent).pipe(finalize(() => { this.ready(); })).subscribe(
          agent => {
            this.busy();
            this.passwordResetRequest.email = user.username;
            this.passwordResetService.createPasswordResetNewAccountPassword(this.passwordResetRequest)
            .pipe(finalize(() => { this.ready(); })).subscribe(result => {
              if (result) {
                this.dialogService.showOkDialog('New agent has been added to the system.  The user will be sent an email to complete registration.', 'New Account Creation')
                .subscribe(e => {
                  this.router.navigate(['/agents']);
                });
              }
            },
              this.errorTranslationService.ErrorHandler);
          },
          this.errorTranslationService.ErrorHandler);
      },
      this.errorTranslationService.ErrorHandler);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Permission } from '@xactly-app/common/src/lib/models/Permission';
import { Role } from '@xactly-app/common/src/lib/models/Role';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';

@Component({
    selector: 'role-component',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {

    @Input() role: Role;
    @Input() permissions: Permission[];
    constructor(private apiService: RoleService, private errorTranslationService: ErrorTranslationService) { }

    private getPermission(id: string): Permission {

        if (this.permissions === null) {
            return null;
        }
        return this.permissions.find((permission: Permission, index, obj) => {
            return permission.id === id;
        });
    }

    ngOnInit() {

    }

    public save() {
        this.apiService.updateRole(this.role).subscribe(
            result => {
                alert('Role saved');
            },
            this.errorTranslationService.ErrorHandler);
    }

}

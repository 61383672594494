﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionType } from '../models/SubscriptionType';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionTypeService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getSubscriptionTypes(): Observable<SubscriptionType[]> {
        return this.httpService.get<SubscriptionType[]>(`${this.configuration.ApiServer}api/Subscription/Type`);
    }

    public getSubscriptionType(id: string): Observable<SubscriptionType> {
        return this.httpService.get<SubscriptionType>(
            `${this.configuration.ApiServer}api/Subscription/Type/${encodeURIComponent(id)}`
        );
    }

    public getSubscriptionTypeAgencys(): Observable<SubscriptionType[]> {
        return this.httpService.get<SubscriptionType[]>(`${this.configuration.ApiServer}api/Subscription/Type/Agency`);
    }

    public getSubscriptionTypeAgents(): Observable<SubscriptionType[]> {
        return this.httpService.get<SubscriptionType[]>(`${this.configuration.ApiServer}api/Subscription/Type/Agent`);
    }

    public createSubscriptionType(subscriptionType: SubscriptionType): Observable<SubscriptionType> {
        return this.httpService.post<SubscriptionType>(
            `${this.configuration.ApiServer}api/Subscription/Type`,
            JSON.stringify(subscriptionType)
        );
    }

    public deleteSubscriptionType(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(
            `${this.configuration.ApiServer}api/Subscription/Type/${encodeURIComponent(id)}`
        );
    }

    public updateSubscriptionType(subscriptionType: SubscriptionType): Observable<SubscriptionType> {
        return this.httpService.put<SubscriptionType>(
            `${this.configuration.ApiServer}api/Subscription/Type`,
            JSON.stringify(subscriptionType)
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Permission } from '@xactly-app/common/src/lib/models/Permission';
import { Role } from '@xactly-app/common/src/lib/models/Role';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PermissionService } from '@xactly-app/common/src/lib/services/PermissionService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'roles-view-component',
    templateUrl: './roles-view.component.html',
    styleUrls: ['./roles-view.component.css']
})
export class RolesViewComponent extends BaseComponent implements OnInit {
    public roles: Role[];
    public permissions: Permission[];
    public showInstructions: Boolean = true;
    constructor(public apiService: RoleService, public permissionService : PermissionService, public errorTranslationService: ErrorTranslationService) {
        super();
    }
  
    ngOnInit() {
        this.busy();
        this.permissionService.getPermissions().pipe(finalize(() => { this.ready(); })).subscribe(permissions => {
            this.permissions = permissions;
            this.apiService.getRoles().subscribe(roles => { 
                this.roles = roles; 
            }, this.errorTranslationService.ErrorHandler);
        },
            this.errorTranslationService.ErrorHandler)
    }

}

import { SideCoordinate } from './side-coordinate';
export class Shape {    
    public Name: string;
    protected Top: SideCoordinate[];
    protected Bottom: SideCoordinate[];
    protected Left: SideCoordinate[];
    protected Right: SideCoordinate[];

    public constructor(){
        this.Top = [];
        this.Bottom = [];
        this.Left = [];
        this.Right = [];
    }

    public GetTop() : SideCoordinate[]
    {
        return this.Top;
    }

    public GetBottom() : SideCoordinate[]
    {
        return this.Bottom;
    }

    public GetLeft() : SideCoordinate[]
    {
        return this.Left;
    }

    public GetRight() : SideCoordinate[]
    {
        return this.Right;
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common';
import { AgentUser } from '@xactly-app/common';
import { Subscription } from '@xactly-app/common';
import { AgentUserService } from '@xactly-app/common';
import { SubscriptionService } from '@xactly-app/common';
import { forkJoin, Observable } from 'rxjs';
import { shareReplay, finalize, take } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common';
import { CacheService } from '@xactly-app/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent extends BaseComponent implements OnInit {
    public IsAuthorized = false;
    public agentUser: AgentUser;
    public Subscriptions: Subscription[] = new Array<Subscription>();
    constructor(public securityService: AuthenticationService,
        public subscriptionService: SubscriptionService,
        public agentUserService: AgentUserService,
        public router: Router,
        public cacheService: CacheService) {
        super();
    }

    public UserHasFreeSubscription() {
        return false;
        // return this.agentUser != null && this.agentUser.agent.hasFreeSubscription;
    }

    ngOnInit() {
        this.busy();
        this.securityService.getUser().then((user) => {
            if (user) {
                this.IsAuthorized = true;
            } else {
                this.IsAuthorized = false;
            }
            if (this.IsAuthorized) {
                if (!this.cacheService.has('AgentCurrentUserObservable')) {
                    this.cacheService.set('AgentCurrentUserObservable',
                        this.agentUserService.getAgentUserCurrent().pipe(shareReplay(1)));
                }
                this.cacheService.get<Observable<AgentUser>>('AgentCurrentUserObservable')
                    .pipe(take(1), finalize(() => this.ready()))
                    .subscribe((agentCurrentUser) => {
                        this.agentUser = agentCurrentUser;
                    });

                if (!this.cacheService.has('SubscriptionServiceObservable')) {
                    this.cacheService.set('SubscriptionServiceObservable',
                        this.subscriptionService.getSubscriptionActives().pipe(shareReplay(1)));
                }
                this.cacheService.get<Observable<Subscription[]>>('SubscriptionServiceObservable')
                    .pipe(take(1), finalize(() => this.ready()))
                    .subscribe((subscriptions) => {
                        this.Subscriptions = subscriptions;
                        this.ready();
                    });
            } else {
                this.ready();
            }

        });
    }

    public IsSubscribed() {
        return true;
        // return this.Subscriptions !== null && this.Subscriptions.length > 0;
    }

    public IsAdmin() {
        return this.securityService.HasAdminRole;
    }

    public Logout() {
        this.securityService.logout();
    }

    public Login() {
        this.securityService.login();
    }

    public Register() {
        this.router.navigate(['/registration']);
    }

    public OpenDiagrams() {
        this.router.navigate(['/diagrams']);
    }

    public OpenSubscriptions() {
        this.router.navigate(['/subscription/add']);
    }
}

// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var Diagram = /** @class */ (function (_super) {
    tslib_1.__extends(Diagram, _super);
    function Diagram() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.gridSize = 0;
        _this.graphScale = 0;
        _this.pageName = null;
        _this.name = null;
        _this.value = null;
        _this.description = null;
        _this.measurementUnitId = null;
        _this.userId = null;
        _this.user = null;
        return _this;
    }
    return Diagram;
}(DynamoEntity));
export { Diagram };

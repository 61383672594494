import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';
var AgentListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentListComponent, _super);
    function AgentListComponent(errorTranslationService, dialogService, router, activatedRoute, agentUserService, agentService, userService) {
        var _this = _super.call(this) || this;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.agentUserService = agentUserService;
        _this.agentService = agentService;
        _this.userService = userService;
        return _this;
    }
    AgentListComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    AgentListComponent.prototype.Edit = function (agentUser) {
        this.router.navigate(['/agent', agentUser.agent.id]);
    };
    AgentListComponent.prototype.Add = function () {
        this.router.navigate(['/agent']);
    };
    AgentListComponent.prototype.Delete = function (agentUser) {
        var _this = this;
        this.dialogService.showConfirmDialog('Are you sure you want to remove this agent?', 'Remove Agent').subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.busy();
                _this.agentService.deleteAgent(agentUser.agent.id).pipe(finalize(function () { _this.ready(); })).subscribe(function () {
                    _this.Load();
                }, _this.errorTranslationService.ErrorHandler);
            }
        });
    };
    AgentListComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        return this.agentUserService.getAgentUsers().pipe(finalize(function () { _this.ready(); })).subscribe(function (agentUsers) {
            _this.agentUsers = agentUsers;
        }, this.errorTranslationService.ErrorHandler);
    };
    return AgentListComponent;
}(BaseComponent));
export { AgentListComponent };

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common';
import { MeasurementUnit } from '@xactly-app/common';
import { Router } from '@angular/router';
import { ErrorTranslationService } from '@xactly-app/common';
import { ValidationService } from '@xactly-app/common';
import { DialogService } from '@xactly-app/common';
import { MeasurementUnitService } from '@xactly-app/common';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-measurement-add',
  templateUrl: './measurement-add.component.html',
  styleUrls: ['./measurement-add.component.scss']
})
export class MeasurementAddComponent extends BaseComponent implements OnInit {
  public measurementUnit: MeasurementUnit;
  constructor(
    public router: Router,
    public errorTranslationService: ErrorTranslationService,
    public validationService: ValidationService,
    public dialogService: DialogService,
    public measurementUnitService: MeasurementUnitService) {
    super();
  }

  ngOnInit() {
    this.Load();
  }

  private Load() {
    this.measurementUnit = new MeasurementUnit();
  }

  public Cancel() {
    this.router.navigate(['/measurementUnits']);
  }

  public Save() {
    this.busy();
    this.measurementUnitService.createMeasurementUnit(this.measurementUnit).pipe(finalize(() => { this.ready(); })).subscribe(
      measurementUnit => {
        this.measurementUnit = measurementUnit;
        this.router.navigate(['/measurementUnits']);
      },
      this.errorTranslationService.ErrorHandler);
  }
}

<instruction-card [message]="'This page displays all measurements'" [title]="'Measurements'"></instruction-card>
<mat-card>
  <mat-card-content>
    <loading-indicator *ngIf="loading"></loading-indicator>
    <button mat-button color="primary" (click)="Add()" title='Add Measurement'>
      Add Measurement
      <mat-icon>note_add</mat-icon>
    </button>
    <div *ngIf="!loading" class="grid">
      <wj-flex-grid [itemsSource]="measurementUnits" [headersVisibility]="1">
        <wj-flex-grid-column [allowSorting]="false" [header]="'Actions'" [width]="90" [isReadOnly]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button mat-icon-button (click)="Edit(cell.item)" title='Edit Measurement'>
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="Delete(cell.item)" title='Remove Measurement'>
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Short Name'" [minWidth]="200" [binding]="'shortName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Squared Name'" [minWidth]="200" [binding]="'squaredName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Single Name'" [minWidth]="200" [binding]="'singleName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Plural Name'" [minWidth]="200" [binding]="'pluralName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Description'" [minWidth]="200" [binding]="'description'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
import { ConfigurationModel } from '@xactly-app/common/src/lib/shared-settings/configuration-model';

export const TestConfiguration: ConfigurationModel = {
    authority: 'https://test-identity.xactly.ca',
    redirectUri: 'https://test.xactly.ca/callback',
    silentRenewUri: 'https://test.xactly.ca/assets/silent-refresh.html',
    responseMode: 'query',
    responseType: 'code',
    clientId: 'Rms',
    scopes: 'openid RmsApi',
    postLogoutRedirectUri: 'https://test.xactly.ca/',
    debugLogging: false,
    UserManagementApiServer: `https://test-user.xactly.ca/`,
    ApiServer: `https://test-api.xactly.ca/`,
    log_console_debug_active: false,
    log_console_warning_active: false
};

import { ConfigurationModel } from '@xactly-app/common/src/lib/shared-settings/configuration-model';

export const DevConfiguration: ConfigurationModel = {
    authority: 'http://localhost:5000',
    redirectUri: 'http://localhost:7000/callback',
    silentRenewUri: 'http://localhost:7000/assets/silent-refresh.html',
    responseMode: 'query',
    responseType: 'code',
    clientId: 'Rms',
    scopes: 'openid RmsApi',
    postLogoutRedirectUri: 'http://localhost:7000/',
    debugLogging: true,
    UserManagementApiServer: 'http://localhost:5002/',
    ApiServer: 'http://localhost:5001/',
    log_console_debug_active: false,
    log_console_warning_active: false
};

﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';

export class Agency extends DynamoEntity {
        
    public name : string = null;    
    public agentCount : number = 0;    
    public isSystem : boolean = false;
}

import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { switchMap, finalize, take } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UserSupportRequestService } from '@xactly-app/common/src/lib/services/UserSupportRequestService';
import { Component, OnInit } from '@angular/core';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { of } from 'rxjs';
import { SupportRequest } from '@xactly-app/common/src/lib/models/SupportRequest';
import { UserSupportRequest } from '@xactly-app/common/src/lib/models/UserSupportRequest';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';

@Component({
  selector: 'app-support-edit',
  templateUrl: './support-edit.component.html',
  styleUrls: ['./support-edit.component.css']
})
export class SupportEditComponent extends BaseComponent implements OnInit {
  public supportRequest: SupportRequest = new SupportRequest();
  public adding: boolean;
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private userSupportRequestService: UserSupportRequestService,
    private activatedRoute: ActivatedRoute,
    private errorTranslationService: ErrorTranslationService,
    private securityService: AuthenticationService) {
    super();
  }

  ngOnInit() {
    this.Load();
  }

  public Cancel() {
    if (this.securityService.HasAdminRole) {
      this.router.navigate(['/support']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  public Save() {
    this.busy();
    let observable = this.userSupportRequestService.updateSupport(this.supportRequest);
    if (this.adding) {
      observable = this.userSupportRequestService.createSupport(this.supportRequest)
    }
    observable.pipe(take(1), finalize(() => { this.ready(); })).subscribe((userSupportRequest) => {
      this.supportRequest = userSupportRequest.supportRequest;
      if (this.adding) {
        this.dialogService.showOkDialog('Your support request has been submitted, you will be contacted as soon as possible',
          'Support Request Submitted').subscribe(() => {
            if (this.securityService.HasAdminRole) {
              this.router.navigate(['/support']);
            } else {
              this.router.navigate(['/home']);
            }
          });
      }
    }, this.errorTranslationService.ErrorHandler);
  }

  public Load() {
    this.busy();
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          if (params.get('id') === '0') {
            this.adding = true;
            const newUserSupportRequest = new UserSupportRequest();
            newUserSupportRequest.supportRequest = new SupportRequest();
            newUserSupportRequest.supportRequest.message = '';
            return of(newUserSupportRequest);
          }
          this.adding = false;
          return this.userSupportRequestService.getSupport(params.get('id'));
        }
        ),
        take(1),
        finalize(() => { this.ready(); })
      ).subscribe(
        existingUserSupportRequest => {
          this.supportRequest = existingUserSupportRequest.supportRequest;
        },
        this.errorTranslationService.ErrorHandler);
  }

}

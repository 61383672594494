﻿
// Generated using Typewritter, please do not modify!

import { User } from './User';
import { SupportRequest } from './SupportRequest';

export class UserSupportRequest {
        
    public user : User = null;    
    public supportRequest : SupportRequest = null;
}

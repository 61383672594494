import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { Role } from '@xactly-app/common/src/lib/models/Role';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';
import { environment } from 'apps/xactly-app/src/environments/environment';
import { Utility } from '@xactly-app/common/src/lib/utility';

@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.scss']
})
export class AgentEditComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public agency: Agency = new Agency();
  public user: User = new User();
  public agencies: Array<Agency> = new Array<Agency>();
  public roles: Array<Role> = new Array<Role>();
  public agent: Agent = new Agent();
  public passwordResetRequest: PasswordResetRequest;

  constructor(public winService: WindowService,
    public stateService: CellStateService,
    public agentService: AgentService,
    public agencyService: AgencyService,
    public dialogService: DialogService,
    public userService: UserService,
    public validationService: ValidationService,
    private roleService: RoleService,
    public errorTranslationService: ErrorTranslationService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public passwordResetService: PasswordResetService,
    public agentUserService: AgentUserService) {
    super();
    this.user.person = new Person();
    this.user.person.address = new Address();
    this.passwordResetRequest = new PasswordResetRequest();
    this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
  }

  ngAfterViewChecked() {
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.Load();
  }

  public ResetPassword() {
    this.busy();
    this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
    this.passwordResetRequest.email = this.user.username;
    this.passwordResetService.createPasswordResetResetPassword(this.passwordResetRequest)
      .pipe(finalize(() => { this.ready(); })).subscribe(result => {
        if (result) {
          this.ready();
          this.dialogService.showOkDialog('Password reset request has been sent to: ' + this.user.username, 'Password Reset')
            .subscribe(e => {
            });
        }
      });
  }

  public Save() {
    this.busy();

    const agentUser = new AgentUser();
    this.user.tenantId = this.agent.agencyId;
    agentUser.user = this.user;
    agentUser.agent = this.agent;

    this.agentUserService.updateAgentUser(agentUser).pipe(finalize(() => { this.ready(); })).subscribe(
      (agentUserResult) => {
        this.user = agentUserResult.user;
        this.agent = agentUserResult.agent;
        this.router.navigate(['/agents']);
      },
      this.errorTranslationService.ErrorHandler);
  }

  public Cancel() {
    this.router.navigate(['/agents']);
  }

  public Load() {
    this.busy();
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.agentService.getAgent(params.get('id')).pipe(finalize(() => { }))
        ),
        finalize(() => { })
      ).subscribe(
        agent => {
          this.busy();
          this.agent = agent;
          this.userService.getUser(agent.userId).pipe(finalize(() => { this.ready(); })).subscribe(
            user => {
              this.busy();
              this.user = user;
              this.agencyService.getAgencys().pipe(finalize(() => { this.ready(); })).subscribe(
                agencies => {
                  this.busy();
                  this.agencies = agencies;
                  this.roleService.getRoles().pipe(finalize(() => { this.ready(); })).subscribe(
                    roles => {
                      this.roles = roles;
                    },
                    this.errorTranslationService.ErrorHandler);
                },
                this.errorTranslationService.ErrorHandler);
            },
            this.errorTranslationService.ErrorHandler);
        },
        this.errorTranslationService.ErrorHandler);
  }
}

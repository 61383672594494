import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';
var AgencyListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgencyListComponent, _super);
    function AgencyListComponent(errorTranslationService, dialogService, router, activatedRoute, agencyService, userService) {
        var _this = _super.call(this) || this;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.agencyService = agencyService;
        _this.userService = userService;
        return _this;
    }
    AgencyListComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    AgencyListComponent.prototype.Edit = function (agency) {
        this.router.navigate(['/agency', agency.id]);
    };
    AgencyListComponent.prototype.Add = function () {
        this.router.navigate(['/agency']);
    };
    AgencyListComponent.prototype.Delete = function (agency) {
        var _this = this;
        this.dialogService.showConfirmDialog('Are you sure you want to remove this agency?', 'Remove Agency').subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.busy();
                _this.agencyService.deleteAgency(agency.id).pipe(finalize(function () { _this.ready(); })).subscribe(function () {
                }, _this.errorTranslationService.ErrorHandler);
            }
        });
    };
    AgencyListComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        return this.agencyService.getAgencys().pipe(finalize(function () { _this.ready(); })).subscribe(function (agencies) {
            _this.agencies = agencies;
        }, this.errorTranslationService.ErrorHandler);
    };
    return AgencyListComponent;
}(BaseComponent));
export { AgencyListComponent };

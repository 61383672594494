import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { MeasurementUnit } from '@xactly-app/common/src/lib/models/MeasurementUnit';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { MeasurementUnitService } from '@xactly-app/common/src/lib/services/MeasurementUnitService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-measurement-edit',
  templateUrl: './measurement-edit.component.html',
  styleUrls: ['./measurement-edit.component.scss']
})
export class MeasurementEditComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public measurementUnit: MeasurementUnit = new MeasurementUnit();

  constructor(public winService: WindowService,
    public stateService: CellStateService,
    public measurementUnitService: MeasurementUnitService,
    public dialogService: DialogService,
    public validationService: ValidationService,
    public errorTranslationService: ErrorTranslationService,
    public activatedRoute: ActivatedRoute,
    public router: Router) {
    super();
    this.measurementUnit = new MeasurementUnit();
  }

  ngAfterViewChecked() {
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.Load();
  }

  public Save() {
    this.busy();
    this.measurementUnitService.updateMeasurementUnit(this.measurementUnit).pipe(finalize(() => { this.ready(); })).subscribe(
      (measurementUnit) => {
        this.measurementUnit = measurementUnit;
        this.router.navigate(['/measurements']);
      },
      this.errorTranslationService.ErrorHandler);
  }

  public Cancel() {
    this.router.navigate(['/measurements']);
  }

  public Load() {
    this.busy();
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.measurementUnitService.getMeasurementUnit(params.get('id')).pipe(finalize(() => { this.ready(); }))
        ),
        finalize(() => { this.ready(); })
      ).subscribe(
        measurementUnit => {
          this.measurementUnit = measurementUnit;
        },
        this.errorTranslationService.ErrorHandler);
  }
}

﻿
// Generated using Typewritter, please do not modify!


export class PasswordInformation {
        
    public userName : string = null;    
    public oldPassword : string = null;    
    public newPassword : string = null;    
    public resetCode : string = null;
}

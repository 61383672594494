import { HttpService } from './http-service';
import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var AgencyService = /** @class */ (function () {
    function AgencyService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    AgencyService.prototype.getAgencys = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Agency");
    };
    AgencyService.prototype.getAgency = function (id) {
        return this.httpService.get(this.configuration.ApiServer + "api/Agency/" + encodeURIComponent(id));
    };
    AgencyService.prototype.updateAgency = function (agency) {
        return this.httpService.put(this.configuration.ApiServer + "api/Agency", JSON.stringify(agency));
    };
    AgencyService.prototype.createAgency = function (agency) {
        return this.httpService.post(this.configuration.ApiServer + "api/Agency", JSON.stringify(agency));
    };
    AgencyService.prototype.deleteAgency = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Agency/" + encodeURIComponent(id));
    };
    AgencyService.ngInjectableDef = i0.defineInjectable({ factory: function AgencyService_Factory() { return new AgencyService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: AgencyService, providedIn: "root" });
    return AgencyService;
}());
export { AgencyService };

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  private snackBar: MatSnackBar;
  public panelOpenState = false;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    this.snackBar = data.snackBarReference;
  }

  public Close() {
    this.snackBar.dismiss();
  }

  ngOnInit() {
  }

}

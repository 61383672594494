<instruction-card [message]="'This page displays all users in the system'" [title]="'Users'"></instruction-card>
<mat-card>
    <mat-card-content>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <div *ngIf="!loading" class="grid">
            <wj-flex-grid [itemsSource]="agentUsers">
                <wj-flex-grid-column [header]="'First Name'" [minWidth]="200" [binding]="'user.person.firstName'" [isReadOnly]="true"></wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Last Name'" [minWidth]="200" [binding]="'user.person.lastName'" [isReadOnly]="true"></wj-flex-grid-column>
                <wj-flex-grid-column [header]="'User Name'" [minWidth]="200" [binding]="'user.username'" [isReadOnly]="true"></wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Active'" [minWidth]="200" [binding]="'user.isActive'" [isReadOnly]="true"></wj-flex-grid-column>
                <ng-template wjFlexGridDetail let-item="item">
                    <user-component [agentUser]="item" (userDeleted)="userDeleted($event)" (userChanged)="userChanged($event)"></user-component>
                </ng-template>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
        </div>
        <loading-indicator *ngIf="loading"></loading-indicator>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
</mat-card>
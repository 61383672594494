import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatToolbarModule, MatSidenavModule, MatListModule, MatCardModule,
    MatInputModule, MatSliderModule, MatRadioModule, MatSelectModule, MatTabsModule,
    MatCheckboxModule, MatDatepickerModule, MatButtonModule, MatProgressSpinnerModule,
    MatProgressBarModule, MatDialogModule, MatStepperModule, MatTooltipModule, MatTableModule,
    MatIconModule, MatExpansionModule, MatChipsModule, MatSlideToggleModule, MatSnackBarModule,
    MatMenuModule
} from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatInputModule,
        MatSliderModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatStepperModule,
        MatTooltipModule,
        MatTableModule,
        MatIconModule,
        MatExpansionModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatMenuModule
    ],
    exports: [
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatInputModule,
        MatSliderModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatStepperModule,
        MatTooltipModule,
        MatTableModule,
        MatIconModule,
        MatExpansionModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatMenuModule
    ]
})
export class MaterialModule { }

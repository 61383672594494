import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Address } from '@xactly-app/common';
import { ValidationService } from '@xactly-app/common';

@Component({
    selector: 'address-component',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
    @Input() address: Address;
    @Input('form') form: NgForm;
    @Input() isReadOnly: boolean;

    constructor(public validationService: ValidationService) {

    }

    ngOnInit() {


    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-type-list',
  templateUrl: './subscription-type-list.component.html',
  styleUrls: ['./subscription-type-list.component.scss']
})
export class SubscriptionTypeListComponent extends BaseComponent implements OnInit {

  public subscriptionTypes: SubscriptionType[];
  constructor(
    public errorTranslationService: ErrorTranslationService,
    public dialogService: DialogService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public subscriptionTypeService: SubscriptionTypeService) {
    super();
  }

  ngOnInit() {
    this.Load();
  }

  protected Edit(subscriptionType: SubscriptionType) {
    this.router.navigate(['/subscriptionType', subscriptionType.id]);
  }

  public Add() {
    this.router.navigate(['/subscriptionType']);
  }

  protected Delete(subscriptionType: SubscriptionType) {
    this.dialogService.showConfirmDialog('Are you sure you want to remove this subscription type?', 'Remove Subscription Type').subscribe(
      dialogResult => {
        if (dialogResult) {
          this.busy();
          this.subscriptionTypeService.deleteSubscriptionType(subscriptionType.id).pipe(finalize(() => { this.ready(); })).subscribe(
            () => {
              this.Load();
            },
            this.errorTranslationService.ErrorHandler);
        }
      }
    );
  }

  protected Load() {
    this.busy();
    return this.subscriptionTypeService.getSubscriptionTypes().pipe(finalize(() => { this.ready(); })).subscribe(
      subscriptionTypes => {
        this.subscriptionTypes = subscriptionTypes;
      },
      this.errorTranslationService.ErrorHandler);
  }
}

<instruction-card [message]="'This page displays all roles in the system'" [title]="'Roles'"></instruction-card>
<mat-card>
    <mat-card-content>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <div *ngIf="!loading" class="grid">
            <wj-flex-grid [itemsSource]="roles">
                <wj-flex-grid-column [header]="'Name'" [minWidth]="200" [binding]="'name'" [isReadOnly]="true"></wj-flex-grid-column>
                <wj-flex-grid-column [header]="'Description'" [minWidth]="200" [binding]="'description'" [isReadOnly]="true"></wj-flex-grid-column>
                <ng-template wjFlexGridDetail let-item="item">
                    <role-component [role]="item" [permissions]="permissions"></role-component>
                </ng-template>
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
        </div>
        <loading-indicator *ngIf="loading"></loading-indicator>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
</mat-card>
<br>
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';
var ResetPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResetPasswordComponent, _super);
    function ResetPasswordComponent(apiService, router, errorTranslationService, validationService, dialogService, securityService, activatedRoute) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.securityService = securityService;
        _this.activatedRoute = activatedRoute;
        _this.passwordResetRequest = new PasswordResetRequest();
        _this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
        return _this;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
    };
    ResetPasswordComponent.prototype.resetPassword = function () {
        var _this = this;
        this.busy();
        this.apiService.createPasswordResetResetPassword(this.passwordResetRequest).pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
            if (result) {
                _this.dialogService.showOkDialog('Password reset email has been sent.', 'Password Reset').subscribe(function (e) {
                    _this.securityService.login('/');
                });
            }
            else {
                _this.dialogService.showOkDialog('Password reset failed, please try again or contact support.', 'Password Reset Error');
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    return ResetPasswordComponent;
}(BaseComponent));
export { ResetPasswordComponent };

import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Router } from '@angular/router';
import * as Oidc from 'oidc-client';
import { AppConfigService } from './app-config.service';
import LocalWebStorageStore from '../models/memory.storage';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(configService, router) {
        var _this = this;
        this.router = router;
        this.loginChanged = new Subject();
        this.configuration = configService.getConfig();
        var memoryStorage = new LocalWebStorageStore();
        var storageSettings = {
            prefix: 'oidc.',
            store: memoryStorage
        };
        var settings = {
            authority: this.configuration.authority,
            client_id: this.configuration.clientId,
            redirect_uri: this.configuration.redirectUri,
            silent_redirect_uri: this.configuration.silentRenewUri,
            response_type: this.configuration.responseType,
            post_logout_redirect_uri: this.configuration.postLogoutRedirectUri,
            scope: this.configuration.scopes,
            response_mode: this.configuration.responseMode,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true
            // userStore: new Oidc.WebStorageStateStore(storageSettings)  Uncomment to switch to memory storage
        };
        if (this.configuration.debugLogging) {
            Oidc.Log.logger = console;
            Oidc.Log.level = Oidc.Log.DEBUG;
        }
        this.userManager = new UserManager(settings);
        this.userManager.getUser().then(function (user) {
            _this.user = user;
            _this.loginChanged.next(_this.isLoggedIn());
        });
        this.userManager.events.addAccessTokenExpiring(function () {
            _this.userManager.getUser().then(function (user) {
                _this.user = user;
            });
            if (_this.configuration.debugLogging) {
                console.log('Token about to expire');
            }
        });
        this.userManager.events.addUserSessionChanged(function () {
            _this.userManager.getUser().then(function (user) {
                console.log('Session changed');
                _this.user = user;
                _this.loginChanged.next(_this.isLoggedIn());
            });
        });
        this.userManager.events.addAccessTokenExpired(function () {
            _this.userManager.getUser().then(function (user) {
                _this.user = user;
            });
            if (_this.configuration.debugLogging) {
                console.log('Token expired');
            }
        });
        this.userManager.events.addSilentRenewError(function (error) {
            console.log(error);
        });
    }
    AuthenticationService.prototype.getUser = function () {
        return this.userManager.getUser();
    };
    Object.defineProperty(AuthenticationService.prototype, "LoginChanged", {
        get: function () {
            return this.loginChanged;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.getAuthorizationHeaderValue = function () {
        if (this.isLoggedIn()) {
            return this.user.token_type + " " + this.user.access_token;
        }
        else {
            return '';
        }
    };
    AuthenticationService.prototype.isLoggedIn = function () {
        return this.user != null && !this.user.expired;
    };
    AuthenticationService.prototype.login = function (redirectRoute, otac, extraQueryParams) {
        if (redirectRoute === void 0) { redirectRoute = '/'; }
        if (otac === void 0) { otac = null; }
        if (extraQueryParams === void 0) { extraQueryParams = {}; }
        if (otac === null) {
            this.userManager.signinRedirect({ state: redirectRoute, extraQueryParams: extraQueryParams });
        }
        else {
            this.userManager.signinRedirect({ acr_values: 'otp:' + otac, state: redirectRoute, extraQueryParams: extraQueryParams });
        }
    };
    Object.defineProperty(AuthenticationService.prototype, "UserLastName", {
        get: function () {
            if (!this.isLoggedIn()) {
                return '';
            }
            return this.user.profile.family_name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "UserFirstName", {
        get: function () {
            if (!this.isLoggedIn()) {
                return '';
            }
            return this.user.profile.given_name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "UserId", {
        get: function () {
            if (!this.isLoggedIn()) {
                return '';
            }
            return this.user.profile.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "HasAgentRole", {
        get: function () {
            if (!this.isLoggedIn()) {
                return false;
            }
            return this.user.profile.role === 'agent';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "HasManagerRole", {
        get: function () {
            if (!this.isLoggedIn()) {
                return false;
            }
            return this.user.profile.role === 'manager';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "HasAdminRole", {
        get: function () {
            if (!this.isLoggedIn()) {
                return false;
            }
            return this.user.profile.role === 'administrator';
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.logout = function () {
        this.userManager.signoutRedirect();
    };
    AuthenticationService.prototype.callback = function () {
        var _this = this;
        this.userManager
            .signinRedirectCallback()
            .then(function (user) {
            _this.user = user;
            _this.loginChanged.next(_this.isLoggedIn());
            if (typeof user.state === 'undefined' || user.state === '') {
                _this.router.navigate(['/']);
            }
            else {
                _this.router.navigate([user.state]);
            }
        })
            .catch(function (err) {
            _this.router.navigate(['/']);
        });
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.AppConfigService), i0.inject(i2.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };

<instruction-card [message]="'Enter the email address you used to create your account in order to reset your password.  You will receive an email with further instructions.'"
    [title]="'Password Reset'">
</instruction-card>
<div>
    <form #userForm="ngForm" (ngSubmit)="resetPassword()">
        <mat-card>
            <mat-card-content>
                <div>
                    <mat-form-field>
                        <input matInput [textMask]="validationService.EmailMask" [(ngModel)]="passwordResetRequest.email"
                            placeholder="Email" type="text" name="userpersonemail" #userpersonemail="ngModel" required
                            emailvalid>
                        <mat-error *ngIf="userpersonemail.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="userpersonemail.errors?.emailInvalid">Email is not valid</mat-error>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <loading-indicator *ngIf="loading"></loading-indicator>
                <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">Reset
                    Password</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
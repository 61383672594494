// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var Agent = /** @class */ (function (_super) {
    tslib_1.__extends(Agent, _super);
    function Agent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userId = null;
        _this.agencyId = null;
        _this.hasFreeSubscription = false;
        return _this;
    }
    return Agent;
}(DynamoEntity));
export { Agent };

import { Component } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.html',
    styleUrls: ['./loading-indicator.scss']
})
export class LoadingIndicatorComponent {

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { Payment } from '@xactly-app/common/src/lib/models/Payment';
import { Subscription } from '@xactly-app/common/src/lib/models/Subscription';
import { SubscriptionRequest } from '@xactly-app/common/src/lib/models/SubscriptionRequest';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SettingService } from '@xactly-app/common/src/lib/services/SettingService';
import { SubscriptionService } from '@xactly-app/common/src/lib/services/SubscriptionService';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ParentSubscriptionType } from './parent-subscription-type';
import { CacheService } from '@xactly-app/common/src/lib/services/cache.service';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';

@Component({
  selector: 'app-subscription-add',
  templateUrl: './subscription-add.component.html',
  styleUrls: ['./subscription-add.component.scss']
})
export class SubscriptionAddComponent extends BaseComponent implements OnInit {
  public subscriptions: Subscription[] = [];
  public subscriptionTypes: SubscriptionType[] = [];
  public selectedSubscriptionType: SubscriptionType = null;
  public parentSubscriptionTypes: ParentSubscriptionType[] = [];
  public selectedParentSubscriptionType: ParentSubscriptionType = new ParentSubscriptionType();
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public agentUser: AgentUser;
  public groupName: string;
  public federalTaxRate: string;
  public stateTaxRate: string;

  constructor(private subscriptionTypeService: SubscriptionTypeService,
    private subscriptionService: SubscriptionService,
    private formBuilder: FormBuilder,
    private agentUserService: AgentUserService,
    private errorTranslationService: ErrorTranslationService,
    private dialogService: DialogService,
    public router: Router,
    public securityService: AuthenticationService,
    private settingService: SettingService,
    private cacheService: CacheService) {
    super();
  }

  ngOnInit() {

    this.settingService.getSetting('FederalTaxRate').subscribe((setting) => {
      this.federalTaxRate = setting.value;
    }, this.errorTranslationService.ErrorHandler);

    this.settingService.getSetting('StateTaxRate').subscribe((setting) => {
      this.stateTaxRate = setting.value;
    }, this.errorTranslationService.ErrorHandler);

    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.LoadSubscriptions();
  }

  public GetFederalTaxAmount() {
    if (Utility.IsNull(this.selectedSubscriptionType) ||
      Utility.IsNull(this.selectedSubscriptionType.price)) {
      return 0;
    }

    return (this.selectedSubscriptionType.price *
      (parseFloat(this.federalTaxRate) / 100));
  }

  public GetStateTaxAmount() {
    if (this.selectedSubscriptionType === null ||
      typeof this.selectedSubscriptionType.price === 'undefined' ||
      this.selectedSubscriptionType.price === null) {
      return 0;
    }

    return (this.selectedSubscriptionType.price *
      (parseFloat(this.stateTaxRate) / 100));
  }

  public GetTotalCost() {
    if (this.selectedSubscriptionType === null ||
      typeof this.selectedSubscriptionType.price === 'undefined' ||
      this.selectedSubscriptionType.price === null) {
      return 0;
    }
    return this.selectedSubscriptionType.price + this.GetFederalTaxAmount();
  }

  public GetSubscriptionTypes() {
    const isAgencyLevel = this.selectedParentSubscriptionType.isAgencySubscription;
    return this.subscriptionTypes.filter((value, index, array) => {
      let subscriptionHasEnoughUsers = false;
      if (isAgencyLevel) {
        subscriptionHasEnoughUsers = this.agentUser.agency.isSystem || this.agentUser.agency.agentCount <= value.maximumUsers;
      } else {
        subscriptionHasEnoughUsers = true;
      }
      return value.isAgencyLevel === isAgencyLevel && subscriptionHasEnoughUsers;
    });
  }

  protected LoadParentSubscriptionTypes(agentUser: AgentUser) {
    this.parentSubscriptionTypes = [];
    let parentSubscriptionType = new ParentSubscriptionType();
    parentSubscriptionType.description = 'Multiple user subscription';
    parentSubscriptionType.isAgencySubscription = true;
    this.parentSubscriptionTypes.push(parentSubscriptionType);

    if (!this.securityService.HasManagerRole) {
      parentSubscriptionType = new ParentSubscriptionType();
      parentSubscriptionType.description = 'Single user subscription';
      parentSubscriptionType.isAgencySubscription = false;
      this.parentSubscriptionTypes.push(parentSubscriptionType);
    }
  }

  public UserHasFreeSubscription() {
    return this.subscriptionTypes.some((subscriptionType) => subscriptionType.price === 0);
  }

  protected LoadSubscriptions() {
    this.busy();
    this.subscriptionService.getSubscriptions().pipe(
      switchMap((subscriptions) => {
        this.subscriptions = subscriptions;
        return this.subscriptionTypeService.getSubscriptionTypes().pipe(
          switchMap((subscriptionTypes) => {
            return this.agentUserService.getAgentUserCurrent().pipe(map((agentUser) => {
              this.agentUser = agentUser;
              if (agentUser.agent.hasFreeSubscription) {
                this.subscriptionTypes = subscriptionTypes;
              } else {
                this.subscriptionTypes = subscriptionTypes.filter((subscriptionType) => subscriptionType.price > 0);
              }
              this.LoadParentSubscriptionTypes(agentUser);
            }));
          })
        );
      }),
      finalize(() => {
        this.ready();
      })
    ).subscribe(() => { },
      this.errorTranslationService.ErrorHandler);
  }

  SubmitPayment(payment: Payment) {
    const groupNameRequired = this.agentUser.agency.isSystem && this.selectedParentSubscriptionType.isAgencySubscription;

    if (groupNameRequired) {
      if (Utility.IsEmptyOrNull(this.groupName)) {
        this.dialogService.showOkDialog('Please provide a name for your group or agency.', 'Group Name').subscribe();
        return;
      }
    }

    this.busy();
    const subscriptionRequest = new SubscriptionRequest();
    subscriptionRequest.newAgencyName = this.groupName;
    subscriptionRequest.payment = new Payment();

    if (payment != null) {
      subscriptionRequest.payment.creditCardNumber = payment.creditCardNumber;
      subscriptionRequest.payment.expiryDate = payment.expiryDate;
      subscriptionRequest.subscriptionTypeId = this.selectedSubscriptionType.id;
      subscriptionRequest.payment.orderId = '';
      subscriptionRequest.payment.amount = '0';
      subscriptionRequest.payment.name = payment.name;
    }

    let logOffRequired = false;
    this.subscriptionService.createSubscriptionPurchase(subscriptionRequest).pipe(
      finalize(() => {
        this.cacheService.invalidate('SubscriptionServiceObservable');
        this.cacheService.invalidate('AgentCurrentUserObservable');
        this.ready();
      }),
      switchMap((subscription) => {
        if (typeof subscription.agencyId !== 'undefined' && subscription.agencyId != null && subscription.agencyId.length > 0) {
          if (!this.securityService.HasManagerRole && !this.securityService.HasAdminRole) {
            logOffRequired = true;
            return this.dialogService.showOkDialog('You are now subscribed!  Because your user is now part of a new agency you will need to login again.  You will now be logged off',
              'Subscription');
          }
        }
        return this.dialogService.showOkDialog('You are now subscribed!', 'Subscription');
      }))
      .subscribe(() => {
        if (logOffRequired) {
          this.securityService.logout();
        } else {
          this.router.navigate(['/home']);
        }
      }, this.errorTranslationService.ErrorHandler);
  }

}

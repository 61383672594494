﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Diagram } from '../models/Diagram';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class DiagramService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getDiagramCount(): Observable<Diagram> {
        return this.httpService.get<Diagram>(`${this.configuration.ApiServer}api/Diagram/Count`);
    }

    public getDiagramPageSizePages(pageSize: number, page: number): Observable<Diagram[]> {
        return this.httpService.get<Diagram[]>(
            `${this.configuration.ApiServer}api/Diagram/PageSize/${pageSize}/Page/${page}`
        );
    }

    public getDiagramChildrens(id: string): Observable<Diagram[]> {
        return this.httpService.get<Diagram[]>(
            `${this.configuration.ApiServer}api/Diagram/${encodeURIComponent(id)}/Children`
        );
    }

    public getDiagram(id: string): Observable<Diagram> {
        return this.httpService.get<Diagram>(`${this.configuration.ApiServer}api/Diagram/${encodeURIComponent(id)}`);
    }

    public updateDiagram(diagram: Diagram): Observable<Diagram> {
        return this.httpService.put<Diagram>(`${this.configuration.ApiServer}api/Diagram`, JSON.stringify(diagram));
    }

    public createDiagram(diagram: Diagram): Observable<Diagram> {
        return this.httpService.post<Diagram>(`${this.configuration.ApiServer}api/Diagram`, JSON.stringify(diagram));
    }

    public deleteDiagramPage(id: string, rangeId: string): Observable<boolean> {
        return this.httpService.delete<boolean>(
            `${this.configuration.ApiServer}api/Diagram/${encodeURIComponent(id)}/Page/${encodeURIComponent(rangeId)}`
        );
    }

    public deleteDiagram(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(`${this.configuration.ApiServer}api/Diagram/${encodeURIComponent(id)}`);
    }
}

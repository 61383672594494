import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { finalize } from 'rxjs/operators';
import { Utility } from '@xactly-app/common/src/lib/utility';
var AgentAddComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentAddComponent, _super);
    function AgentAddComponent(passwordResetService, agentService, router, errorTranslationService, validationService, dialogService, agencyService, rolesService, userService) {
        var _this = _super.call(this) || this;
        _this.passwordResetService = passwordResetService;
        _this.agentService = agentService;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.agencyService = agencyService;
        _this.rolesService = rolesService;
        _this.userService = userService;
        _this.passwordResetRequest = new PasswordResetRequest();
        _this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
        return _this;
    }
    AgentAddComponent.prototype.ngOnInit = function () {
        this.agency = new Agency();
        this.agent = new Agent();
        this.user = new User();
        this.user.person = new Person();
        this.user.person.address = new Address();
        this.Load();
    };
    AgentAddComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        this.agencyService.getAgencys().pipe(finalize(function () { _this.ready(); })).subscribe(function (agencies) {
            _this.busy();
            _this.agencies = agencies;
            _this.agency.id = agencies[0].id;
            _this.rolesService.getRoles().pipe(finalize(function () { _this.ready(); })).subscribe(function (roles) {
                _this.roles = roles;
                _this.user.roleId = roles[0].id;
            }, _this.errorTranslationService.ErrorHandler);
        }, this.errorTranslationService.ErrorHandler);
    };
    AgentAddComponent.prototype.Cancel = function () {
        this.router.navigate(['/agents']);
    };
    AgentAddComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.user.tenantId = this.agency.id;
        this.userService.createUser(this.user).pipe(finalize(function () { _this.ready(); })).subscribe(function (user) {
            _this.busy();
            _this.agent.userId = user.id;
            _this.agent.agencyId = _this.agency.id;
            _this.agentService.createAgent(_this.agent).pipe(finalize(function () { _this.ready(); })).subscribe(function (agent) {
                _this.busy();
                _this.passwordResetRequest.email = user.username;
                _this.passwordResetService.createPasswordResetNewAccountPassword(_this.passwordResetRequest)
                    .pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
                    if (result) {
                        _this.dialogService.showOkDialog('New agent has been added to the system.  The user will be sent an email to complete registration.', 'New Account Creation')
                            .subscribe(function (e) {
                            _this.router.navigate(['/agents']);
                        });
                    }
                }, _this.errorTranslationService.ErrorHandler);
            }, _this.errorTranslationService.ErrorHandler);
        }, this.errorTranslationService.ErrorHandler);
    };
    return AgentAddComponent;
}(BaseComponent));
export { AgentAddComponent };

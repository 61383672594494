import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { MeasurementUnit } from '@xactly-app/common/src/lib/models/MeasurementUnit';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { MeasurementUnitService } from '@xactly-app/common/src/lib/services/MeasurementUnitService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-measurement-list',
  templateUrl: './measurement-list.component.html',
  styleUrls: ['./measurement-list.component.scss']
})
export class MeasurementListComponent extends BaseComponent implements OnInit {

  public measurementUnits: MeasurementUnit[];
  public displayedColumns: string[];

  constructor(
    public errorTranslationService: ErrorTranslationService,
    public dialogService: DialogService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public measurementService: MeasurementUnitService,
    public userService: UserService) {

    super();
  }

  ngOnInit() {
    this.Load();
  }

  protected Edit(measurement: MeasurementUnit) {
    this.router.navigate(['/measurement', measurement.id]);
  }

  public Add() {
    this.router.navigate(['/measurement']);
  }

  protected Delete(measurement: MeasurementUnit) {
    this.dialogService.showConfirmDialog('Are you sure you want to remove this measurement?', 'Remove MeasurementUnit').subscribe(
      dialogResult => {
        if (dialogResult) {
          this.busy();
          this.measurementService.deleteMeasurementUnit(measurement.id).pipe(finalize(() => { this.ready(); })).subscribe(
            () => {
            },
            this.errorTranslationService.ErrorHandler);
        }
      }
    );
  }

  protected Load() {
    this.busy();
    return this.measurementService.getMeasurementUnits().pipe(finalize(() => { this.ready(); })).subscribe(
      measurementUnits => {
        this.measurementUnits = measurementUnits;
      },
      this.errorTranslationService.ErrorHandler);

  }
}

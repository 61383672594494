<instruction-card [message]="'This page displays all agencies'" [title]="'Agencies'"></instruction-card>
<mat-card>
  <mat-card-content>    
    <loading-indicator *ngIf="loading"></loading-indicator>
    <button mat-button color="primary" (click)="Add()" title='Add Agent'>
        Add Agency
        <mat-icon>note_add</mat-icon>
    </button>
    <div *ngIf="!loading" class="grid">
      <wj-flex-grid [itemsSource]="agencies" [headersVisibility]="1">
        <wj-flex-grid-column [allowSorting]="false" [header]="'Actions'" [width]="90" [isReadOnly]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button mat-icon-button (click)="Edit(cell.item)" title='Edit Agency'>
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="Delete(cell.item)" title='Remove Agency'>
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Agency Name'" [minWidth]="200" [binding]="'name'" [isReadOnly]="true"></wj-flex-grid-column> 
        <wj-flex-grid-column [header]="'Maximum Agents'" [minWidth]="200" [binding]="'maximumAgents'" [isReadOnly]="true"></wj-flex-grid-column>        
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
﻿
// Generated using Typewritter, please do not modify!


export class DynamoEntity {
        
    public id : string = null;    
    public rangeId : string = null;    
    public modifiedDate : string = '';
}

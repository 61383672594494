﻿// Do not modify manually, created by TypeWriter

import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http-service';
import { Observable } from 'rxjs';
import { DateService } from '../services/date.service';
import { Diagram } from '../models/Diagram';
import { UserSupportRequest } from '../models/UserSupportRequest';
import { SupportRequest } from '../models/SupportRequest';
import { MeasurementUnit } from '../models/MeasurementUnit';
import { Setting } from '../models/Setting';
import { Agent } from '../models/Agent';
import { Agency } from '../models/Agency';
import { AgentUser } from '../models/AgentUser';
import { Subscription } from '../models/Subscription';
import { SubscriptionType } from '../models/SubscriptionType';
import { Registration } from '../models/Registration';
import { SubscriptionRequest } from '../models/SubscriptionRequest';
import { AppConfigService } from './app-config.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';

@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getAgencys(): Observable<Agency[]> {
        return this.httpService.get<Agency[]>(`${this.configuration.ApiServer}api/Agency`);
    }

    public getAgency(id: string): Observable<Agency> {
        return this.httpService.get<Agency>(`${this.configuration.ApiServer}api/Agency/${encodeURIComponent(id)}`);
    }

    public updateAgency(agency: Agency): Observable<Agency> {
        return this.httpService.put<Agency>(`${this.configuration.ApiServer}api/Agency`, JSON.stringify(agency));
    }

    public createAgency(agency: Agency): Observable<Agency> {
        return this.httpService.post<Agency>(`${this.configuration.ApiServer}api/Agency`, JSON.stringify(agency));
    }

    public deleteAgency(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(`${this.configuration.ApiServer}api/Agency/${encodeURIComponent(id)}`);
    }
}

<instruction-card [message]="'This page displays all settings in the system'" [title]="'Settings'">
</instruction-card>
<form #settingsForm="ngForm" (ngSubmit)="save()">
    <mat-card>
        <mat-card-subtitle>
            Use caution when changing these settings
        </mat-card-subtitle>
        <mat-card-content>
            <div>
                <div *ngFor="let setting of settings">
                    <mat-form-field class="input-full-width">
                        <input matInput [(ngModel)]="setting.value" [placeholder]="setting.name" type="text" [name]="setting.name">
                    </mat-form-field>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <loading-indicator *ngIf="loading"></loading-indicator>
            <button [disabled]="loading" mat-raised-button color="primary" type="submit">Save</button>
        </mat-card-actions>
    </mat-card>
</form>
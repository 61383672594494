import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss']
})
export class OkDialogComponent implements OnInit {

  public message: string;
  public title: string;

  constructor(public dialogRef: MatDialogRef<OkDialogComponent>) { }

  ngOnInit() {
  }

  public setup(message: string, title: string)  {
      this.message = message;
      this.title = title;
  }
}

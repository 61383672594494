<instruction-card [message]="'Please fill out this form to register as a new user.  You will receive an email with further instructions.'"
    [title]="'Registration'">
</instruction-card>
<form #userForm="ngForm" (ngSubmit)="register()">
    <mat-card>
        <mat-card-content>
            <loading-indicator *ngIf="loading"></loading-indicator>
            <div>
                <mat-form-field>
                    <input matInput [textMask]="validationService.EmailMask" [(ngModel)]="registration.user.person.email"
                        placeholder="Email" type="text" name="userpersonemail" #userpersonemail="ngModel" required
                        emailvalid>
                    <mat-error *ngIf="userpersonemail.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="userpersonemail.errors?.emailInvalid">Email is not valid</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="registration.user.person.firstName" placeholder="First Name" maxlength="200"
                        type="text" name="userpersonfirstName" #userpersonfirstName="ngModel" required>
                    <mat-error *ngIf="userpersonfirstName.errors?.required">First name is required</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="registration.user.person.lastName" #userpersonlastName="ngModel"
                        placeholder="Last Name" type="text" maxlength="200" name="userpersonlastName" required>
                    <mat-error *ngIf="userpersonlastName.errors?.required">Last name is required</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [textMask]="validationService.PhoneMask" [(ngModel)]="registration.user.person.primaryPhone"
                        placeholder="Primary Phone (optional)" type="text" name="userpersonprimaryPhone" #userpersonprimaryPhone="ngModel"
                        completed>
                </mat-form-field>
            </div>           
            <mat-accordion>
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>map</mat-icon> Address (Optional)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <address-component [form]="userForm" [address]="registration.user.person.address"></address-component>
                </mat-expansion-panel>
            </mat-accordion>
            <br>
            <p>
                <strong>Disclaimer:</strong> The Xactly application is strictly an area calculator and is accurate to +/- 0.02% rounded to the
                second decimal. Calculations are accurate only according to the information input into the application and we
                are not responsible for inaccurate measurements or numbers input into the app. The Xactly application is an area
                calculator only and not meant for construction purposes. The measurement calculations herein are
                provided for the sole use of the client who retained Capital Measurements Inc, AJK Technologies or
                Kaixo for use of the Xactly application. As such, Capital Measurements Inc, AJK Technologies, or Kaixo makes
                no representation or warranty whatsoever with respect to the measurements within. Capital Measurments
                Inc, AKJ Technologies or Kaixo shall not be responsible for any injury, loss or damage of any kind
                arising from the use of or calculations made taken herein, or the contents of the within calculated
                drawing.
            </p>
            <div>
                <mat-checkbox name="disclaimerAcceptedCheckBox" [(ngModel)]="disclaimerAccepted">I have read and fully accept the disclaimer above</mat-checkbox>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <loading-indicator *ngIf="loading"></loading-indicator>
            <button [disabled]="!userForm.valid || !disclaimerAccepted || loading" mat-raised-button color="primary"
                type="submit">Register
                <mat-icon>done</mat-icon></button>
        </mat-card-actions>
    </mat-card>
</form>
﻿
// Generated using Typewritter, please do not modify!

import { Address } from './Address';

export class Person {
        
    public firstName : string = null;    
    public lastName : string = null;    
    public primaryPhone : string = null;    
    public secondaryPhone : string = null;    
    public email : string = null;    
    public socialInsuranceNumber : string = null;    
    public birthDate : string = '';    
    public address : Address = null;
}

<div *ngIf="IsAuthorized && securityService?.UserLastName">
    <instruction-card [message]="'Welcome ' + securityService?.UserFirstName + ', ' + securityService?.UserLastName"
        [dismissable]='false' [title]="''">
    </instruction-card>
</div>

<div *ngIf="!IsAuthorized || !securityService?.UserLastName">
    <instruction-card [message]="''" [dismissable]='false' [title]="">
    </instruction-card>
</div>

<div class="container">
    <header>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <div class="container text-center">
            <h1>Welcome to Xactly!</h1>
            <p class="lead">Easily create diagrams that calculate exact area.</p>
            <p *ngIf="!IsAuthorized && !loading" class="lead">Registration is free, join today!</p>
        </div>
        <div>
            <button mat-button *ngIf="!IsAuthorized && !loading" color="primary" (click)="Login()" title='Login'>
                Login
                <mat-icon>lock</mat-icon>
            </button>
            <button mat-button *ngIf="!IsAuthorized && !loading" color="primary" (click)="Register()" title='Register'>
                Register Here!
                <mat-icon>person_add</mat-icon>
            </button>
            <button mat-button *ngIf="IsAuthorized && !loading && (IsSubscribed() || IsAdmin())" color="primary"
                (click)="OpenDiagrams()" title='Diagrams'>
                Edit Diagrams
                <mat-icon>create</mat-icon>
            </button>
            <ngb-alert *ngIf='IsAuthorized && !loading && !IsSubscribed() && !IsAdmin() && !UserHasFreeSubscription()'
                [dismissible]="false" type='info'>
                You currently do not have any active subscriptions, use the subscribe button below to create one.
            </ngb-alert>
            <ngb-alert *ngIf='IsAuthorized && !loading && UserHasFreeSubscription()' [dismissible]="false"
                type='success'>
                You have a free trial single user subscription available, try us out!  Click subscribe now and choose the free subscription.
            </ngb-alert>
            <button mat-button *ngIf="IsAuthorized && !loading && !IsSubscribed() && !IsAdmin()" color="primary"
                (click)="OpenSubscriptions()" title='Subscriptions'>
                Subscribe Now!
                <mat-icon>card_giftcard</mat-icon>
            </button>
        </div>
    </header>
    <header>
        <div class="card">
            <div class="card-body">
                <img class="large_image" src="./assets/images/diagram_canvas.png">
            </div>
        </div>
    </header>
</div>
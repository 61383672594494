/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./loading-indicator";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingIndicatorComponent, data: {} });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_7 = "indeterminate"; _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 100 : null); var currVal_5 = i1.ɵnov(_v, 2).value; var currVal_6 = i1.ɵnov(_v, 2).mode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i7.LoadingIndicatorComponent, [], null, null)], null, null); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("loading-indicator", i7.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, {}, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };

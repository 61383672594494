import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@xactly-app/common';

@Component({
    selector: 'app-callback',
    template: '<div><loading-indicator></loading-indicator></div>'
})
export class CallbackComponent implements OnInit {
    constructor(private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.authenticationService.callback();
    }
}

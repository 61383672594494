import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Payment } from '@xactly-app/common/src/lib/models/Payment';
import { SubscriptionRequest } from '@xactly-app/common/src/lib/models/SubscriptionRequest';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SettingService } from '@xactly-app/common/src/lib/services/SettingService';
import { SubscriptionService } from '@xactly-app/common/src/lib/services/SubscriptionService';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ParentSubscriptionType } from './parent-subscription-type';
import { CacheService } from '@xactly-app/common/src/lib/services/cache.service';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';
var SubscriptionAddComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionAddComponent, _super);
    function SubscriptionAddComponent(subscriptionTypeService, subscriptionService, formBuilder, agentUserService, errorTranslationService, dialogService, router, securityService, settingService, cacheService) {
        var _this = _super.call(this) || this;
        _this.subscriptionTypeService = subscriptionTypeService;
        _this.subscriptionService = subscriptionService;
        _this.formBuilder = formBuilder;
        _this.agentUserService = agentUserService;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.securityService = securityService;
        _this.settingService = settingService;
        _this.cacheService = cacheService;
        _this.subscriptions = [];
        _this.subscriptionTypes = [];
        _this.selectedSubscriptionType = null;
        _this.parentSubscriptionTypes = [];
        _this.selectedParentSubscriptionType = new ParentSubscriptionType();
        return _this;
    }
    SubscriptionAddComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingService.getSetting('FederalTaxRate').subscribe(function (setting) {
            _this.federalTaxRate = setting.value;
        }, this.errorTranslationService.ErrorHandler);
        this.settingService.getSetting('StateTaxRate').subscribe(function (setting) {
            _this.stateTaxRate = setting.value;
        }, this.errorTranslationService.ErrorHandler);
        this.firstFormGroup = this.formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this.formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
        this.thirdFormGroup = this.formBuilder.group({
            thirdCtrl: ['', Validators.required]
        });
        this.LoadSubscriptions();
    };
    SubscriptionAddComponent.prototype.GetFederalTaxAmount = function () {
        if (Utility.IsNull(this.selectedSubscriptionType) ||
            Utility.IsNull(this.selectedSubscriptionType.price)) {
            return 0;
        }
        return (this.selectedSubscriptionType.price *
            (parseFloat(this.federalTaxRate) / 100));
    };
    SubscriptionAddComponent.prototype.GetStateTaxAmount = function () {
        if (this.selectedSubscriptionType === null ||
            typeof this.selectedSubscriptionType.price === 'undefined' ||
            this.selectedSubscriptionType.price === null) {
            return 0;
        }
        return (this.selectedSubscriptionType.price *
            (parseFloat(this.stateTaxRate) / 100));
    };
    SubscriptionAddComponent.prototype.GetTotalCost = function () {
        if (this.selectedSubscriptionType === null ||
            typeof this.selectedSubscriptionType.price === 'undefined' ||
            this.selectedSubscriptionType.price === null) {
            return 0;
        }
        return this.selectedSubscriptionType.price + this.GetFederalTaxAmount();
    };
    SubscriptionAddComponent.prototype.GetSubscriptionTypes = function () {
        var _this = this;
        var isAgencyLevel = this.selectedParentSubscriptionType.isAgencySubscription;
        return this.subscriptionTypes.filter(function (value, index, array) {
            var subscriptionHasEnoughUsers = false;
            if (isAgencyLevel) {
                subscriptionHasEnoughUsers = _this.agentUser.agency.isSystem || _this.agentUser.agency.agentCount <= value.maximumUsers;
            }
            else {
                subscriptionHasEnoughUsers = true;
            }
            return value.isAgencyLevel === isAgencyLevel && subscriptionHasEnoughUsers;
        });
    };
    SubscriptionAddComponent.prototype.LoadParentSubscriptionTypes = function (agentUser) {
        this.parentSubscriptionTypes = [];
        var parentSubscriptionType = new ParentSubscriptionType();
        parentSubscriptionType.description = 'Multiple user subscription';
        parentSubscriptionType.isAgencySubscription = true;
        this.parentSubscriptionTypes.push(parentSubscriptionType);
        if (!this.securityService.HasManagerRole) {
            parentSubscriptionType = new ParentSubscriptionType();
            parentSubscriptionType.description = 'Single user subscription';
            parentSubscriptionType.isAgencySubscription = false;
            this.parentSubscriptionTypes.push(parentSubscriptionType);
        }
    };
    SubscriptionAddComponent.prototype.UserHasFreeSubscription = function () {
        return this.subscriptionTypes.some(function (subscriptionType) { return subscriptionType.price === 0; });
    };
    SubscriptionAddComponent.prototype.LoadSubscriptions = function () {
        var _this = this;
        this.busy();
        this.subscriptionService.getSubscriptions().pipe(switchMap(function (subscriptions) {
            _this.subscriptions = subscriptions;
            return _this.subscriptionTypeService.getSubscriptionTypes().pipe(switchMap(function (subscriptionTypes) {
                return _this.agentUserService.getAgentUserCurrent().pipe(map(function (agentUser) {
                    _this.agentUser = agentUser;
                    if (agentUser.agent.hasFreeSubscription) {
                        _this.subscriptionTypes = subscriptionTypes;
                    }
                    else {
                        _this.subscriptionTypes = subscriptionTypes.filter(function (subscriptionType) { return subscriptionType.price > 0; });
                    }
                    _this.LoadParentSubscriptionTypes(agentUser);
                }));
            }));
        }), finalize(function () {
            _this.ready();
        })).subscribe(function () { }, this.errorTranslationService.ErrorHandler);
    };
    SubscriptionAddComponent.prototype.SubmitPayment = function (payment) {
        var _this = this;
        var groupNameRequired = this.agentUser.agency.isSystem && this.selectedParentSubscriptionType.isAgencySubscription;
        if (groupNameRequired) {
            if (Utility.IsEmptyOrNull(this.groupName)) {
                this.dialogService.showOkDialog('Please provide a name for your group or agency.', 'Group Name').subscribe();
                return;
            }
        }
        this.busy();
        var subscriptionRequest = new SubscriptionRequest();
        subscriptionRequest.newAgencyName = this.groupName;
        subscriptionRequest.payment = new Payment();
        if (payment != null) {
            subscriptionRequest.payment.creditCardNumber = payment.creditCardNumber;
            subscriptionRequest.payment.expiryDate = payment.expiryDate;
            subscriptionRequest.subscriptionTypeId = this.selectedSubscriptionType.id;
            subscriptionRequest.payment.orderId = '';
            subscriptionRequest.payment.amount = '0';
            subscriptionRequest.payment.name = payment.name;
        }
        var logOffRequired = false;
        this.subscriptionService.createSubscriptionPurchase(subscriptionRequest).pipe(finalize(function () {
            _this.cacheService.invalidate('SubscriptionServiceObservable');
            _this.cacheService.invalidate('AgentCurrentUserObservable');
            _this.ready();
        }), switchMap(function (subscription) {
            if (typeof subscription.agencyId !== 'undefined' && subscription.agencyId != null && subscription.agencyId.length > 0) {
                if (!_this.securityService.HasManagerRole && !_this.securityService.HasAdminRole) {
                    logOffRequired = true;
                    return _this.dialogService.showOkDialog('You are now subscribed!  Because your user is now part of a new agency you will need to login again.  You will now be logged off', 'Subscription');
                }
            }
            return _this.dialogService.showOkDialog('You are now subscribed!', 'Subscription');
        }))
            .subscribe(function () {
            if (logOffRequired) {
                _this.securityService.logout();
            }
            else {
                _this.router.navigate(['/home']);
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    return SubscriptionAddComponent;
}(BaseComponent));
export { SubscriptionAddComponent };

import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserSupportRequestService } from '@xactly-app/common/src/lib/services/UserSupportRequestService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { PagingBaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
var SupportListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SupportListComponent, _super);
    function SupportListComponent(supportService, errorTranslationService, dialogService, router) {
        var _this = _super.call(this) || this;
        _this.supportService = supportService;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        return _this;
    }
    SupportListComponent.prototype.ngOnInit = function () {
        this.LoadPageSize();
    };
    SupportListComponent.prototype.PageChanged = function (page) {
        this.Load(page);
    };
    SupportListComponent.prototype.Edit = function (userSupportRequest) {
        this.router.navigate(['/support', userSupportRequest.supportRequest.id]);
    };
    SupportListComponent.prototype.Add = function () {
        this.router.navigate(['/support', '0']);
    };
    SupportListComponent.prototype.LoadPageSize = function () {
        var _this = this;
        this.busy();
        this.supportService.getSupportCount().pipe(take(1)).subscribe(function (count) {
            _this.totalCount = count;
            _this.totalPages = Math.ceil(count / _this.pageSize);
            if (_this.currentPage > _this.totalPages) {
                _this.currentPage = _this.totalPages;
            }
            if (_this.totalPages === 0 || _this.totalPages === 1) {
                _this.currentPage = 1;
            }
            _this.Load(_this.currentPage);
        }, this.errorTranslationService.ErrorHandler);
    };
    SupportListComponent.prototype.Load = function (page) {
        var _this = this;
        this.busy();
        this.supportService.getSupportPageSizePages(this.pageSize, page).pipe(take(1), finalize(function () { _this.ready(); })).subscribe(function (supportRequests) {
            _this.supportRequests = supportRequests;
        }, this.errorTranslationService.ErrorHandler);
    };
    SupportListComponent.prototype.Delete = function (userSupportRequest) {
        var _this = this;
        this.dialogService.showConfirmDialog('Are you sure you want to remove this support request?', 'Remove Support Request').subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.busy();
                _this.supportService.deleteSupport(userSupportRequest.supportRequest.id)
                    .pipe(take(1)).subscribe(function (result) {
                    _this.LoadPageSize();
                }, _this.errorTranslationService.ErrorHandler);
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    return SupportListComponent;
}(PagingBaseComponent));
export { SupportListComponent };

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PasswordReset } from '../models/PasswordReset';
import { PasswordResetRequest } from '../models/PasswordResetRequest';
import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import { ConfigurationModel } from '../shared-settings/configuration-model';

@Injectable({
    providedIn: 'root'
})
export class PasswordResetService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }
    public createPasswordResetNewAccountPassword(resetRequest: PasswordResetRequest): Observable<PasswordReset> {
        return this.httpService.post(
            `${this.configuration.UserManagementApiServer}api/PasswordReset/NewAccountPassword`,
            resetRequest
        );
    }
    public createPasswordResetResetPassword(resetRequest: PasswordResetRequest): Observable<PasswordReset> {
        return this.httpService.post(
            `${this.configuration.UserManagementApiServer}api/PasswordReset/ResetPassword`,
            resetRequest
        );
    }
}

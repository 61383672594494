import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var PasswordResetService = /** @class */ (function () {
    function PasswordResetService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    PasswordResetService.prototype.createPasswordResetNewAccountPassword = function (resetRequest) {
        return this.httpService.post(this.configuration.UserManagementApiServer + "api/PasswordReset/NewAccountPassword", resetRequest);
    };
    PasswordResetService.prototype.createPasswordResetResetPassword = function (resetRequest) {
        return this.httpService.post(this.configuration.UserManagementApiServer + "api/PasswordReset/ResetPassword", resetRequest);
    };
    PasswordResetService.ngInjectableDef = i0.defineInjectable({ factory: function PasswordResetService_Factory() { return new PasswordResetService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: PasswordResetService, providedIn: "root" });
    return PasswordResetService;
}());
export { PasswordResetService };

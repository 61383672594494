﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';

export class SupportRequest extends DynamoEntity {
        
    public userId : string = null;    
    public message : string = null;    
    public read : boolean = false;
}

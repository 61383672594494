import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var DiagramService = /** @class */ (function () {
    function DiagramService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    DiagramService.prototype.getDiagramCount = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/Diagram/Count");
    };
    DiagramService.prototype.getDiagramPageSizePages = function (pageSize, page) {
        return this.httpService.get(this.configuration.ApiServer + "api/Diagram/PageSize/" + pageSize + "/Page/" + page);
    };
    DiagramService.prototype.getDiagramChildrens = function (id) {
        return this.httpService.get(this.configuration.ApiServer + "api/Diagram/" + encodeURIComponent(id) + "/Children");
    };
    DiagramService.prototype.getDiagram = function (id) {
        return this.httpService.get(this.configuration.ApiServer + "api/Diagram/" + encodeURIComponent(id));
    };
    DiagramService.prototype.updateDiagram = function (diagram) {
        return this.httpService.put(this.configuration.ApiServer + "api/Diagram", JSON.stringify(diagram));
    };
    DiagramService.prototype.createDiagram = function (diagram) {
        return this.httpService.post(this.configuration.ApiServer + "api/Diagram", JSON.stringify(diagram));
    };
    DiagramService.prototype.deleteDiagramPage = function (id, rangeId) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Diagram/" + encodeURIComponent(id) + "/Page/" + encodeURIComponent(rangeId));
    };
    DiagramService.prototype.deleteDiagram = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/Diagram/" + encodeURIComponent(id));
    };
    DiagramService.ngInjectableDef = i0.defineInjectable({ factory: function DiagramService_Factory() { return new DiagramService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: DiagramService, providedIn: "root" });
    return DiagramService;
}());
export { DiagramService };

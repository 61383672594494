import { Shape } from "./shape";
import { SideCoordinate } from "./side-coordinate";

export class EquilateralTriangle extends Shape {
    public constructor() {
        super();
        this.Name = 'equilateral-triangle';
        this.AddTop(this.Top);
        this.AddBottom(this.Bottom);
        this.AddRight(this.Right);
        this.AddLeft(this.Left);
    }
    private AddBottom(sideCoordinates: SideCoordinate[]) {        
        let y = 86.602540378443864676372317075294;
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 50, endY: y, side: '2' });
        sideCoordinates.push({ startX: 50, startY: y, endX: 0, endY: 0, side: '3' });
    }

    private AddTop(sideCoordinates: SideCoordinate[]) {        
        let y = 86.602540378443864676372317075294;
        sideCoordinates.push({ startX: 50, startY: 0, endX: 100, endY: y, side: '1' });
        sideCoordinates.push({ startX: 100, startY: y, endX: 0, endY: y, side: '2' });
        sideCoordinates.push({ startX: 0, startY: y, endX: 50, endY: 0, side: '3' });
    }

    private AddRight(sideCoordinates: SideCoordinate[]) {        
        let x = 86.602540378443864676372317075294;
        sideCoordinates.push({ startX: 0, startY: 0, endX: x, endY: 50, side: '1' });
        sideCoordinates.push({ startX: x, startY: 50, endX: 0, endY: 100, side: '2' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: '3' });
    }

    private AddLeft(sideCoordinates: SideCoordinate[]) {        
        let x = 86.602540378443864676372317075294;
        sideCoordinates.push({ startX: 0, startY: 50, endX: x, endY: 0, side: '1' });
        sideCoordinates.push({ startX: x, startY: 0, endX: x, endY: 100, side: '2' });
        sideCoordinates.push({ startX: x, startY: 100, endX: 0, endY: 50, side: '3' });
    }    
}


import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { MeasurementUnitService } from '@xactly-app/common/src/lib/services/MeasurementUnitService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';
var MeasurementListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeasurementListComponent, _super);
    function MeasurementListComponent(errorTranslationService, dialogService, router, activatedRoute, measurementService, userService) {
        var _this = _super.call(this) || this;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.measurementService = measurementService;
        _this.userService = userService;
        return _this;
    }
    MeasurementListComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    MeasurementListComponent.prototype.Edit = function (measurement) {
        this.router.navigate(['/measurement', measurement.id]);
    };
    MeasurementListComponent.prototype.Add = function () {
        this.router.navigate(['/measurement']);
    };
    MeasurementListComponent.prototype.Delete = function (measurement) {
        var _this = this;
        this.dialogService.showConfirmDialog('Are you sure you want to remove this measurement?', 'Remove MeasurementUnit').subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.busy();
                _this.measurementService.deleteMeasurementUnit(measurement.id).pipe(finalize(function () { _this.ready(); })).subscribe(function () {
                }, _this.errorTranslationService.ErrorHandler);
            }
        });
    };
    MeasurementListComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        return this.measurementService.getMeasurementUnits().pipe(finalize(function () { _this.ready(); })).subscribe(function (measurementUnits) {
            _this.measurementUnits = measurementUnits;
        }, this.errorTranslationService.ErrorHandler);
    };
    return MeasurementListComponent;
}(BaseComponent));
export { MeasurementListComponent };

﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';

export class SubscriptionType extends DynamoEntity {
        
    public name : string = null;    
    public description : string = null;    
    public price : number = 0;    
    public maximumUsers : number = 0;    
    public durationDays : number = 0;    
    public isSystem : boolean = false;    
    public isAgencyLevel : boolean = false;
}

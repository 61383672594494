import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import * as i0 from "@angular/core";
import * as i1 from "./http-service";
import * as i2 from "./date.service";
import * as i3 from "./app-config.service";
var MeasurementUnitService = /** @class */ (function () {
    function MeasurementUnitService(httpService, dateService, configService) {
        this.httpService = httpService;
        this.dateService = dateService;
        this.configuration = configService.getConfig();
    }
    MeasurementUnitService.prototype.getMeasurementUnits = function () {
        return this.httpService.get(this.configuration.ApiServer + "api/MeasurementUnit");
    };
    MeasurementUnitService.prototype.getMeasurementUnit = function (id) {
        return this.httpService.get(this.configuration.ApiServer + "api/MeasurementUnit/" + encodeURIComponent(id));
    };
    MeasurementUnitService.prototype.updateMeasurementUnit = function (measurementUnit) {
        return this.httpService.put(this.configuration.ApiServer + "api/MeasurementUnit", JSON.stringify(measurementUnit));
    };
    MeasurementUnitService.prototype.createMeasurementUnit = function (measurementUnit) {
        return this.httpService.post(this.configuration.ApiServer + "api/MeasurementUnit", JSON.stringify(measurementUnit));
    };
    MeasurementUnitService.prototype.deleteMeasurementUnit = function (id) {
        return this.httpService.delete(this.configuration.ApiServer + "api/MeasurementUnit/" + encodeURIComponent(id));
    };
    MeasurementUnitService.ngInjectableDef = i0.defineInjectable({ factory: function MeasurementUnitService_Factory() { return new MeasurementUnitService(i0.inject(i1.HttpService), i0.inject(i2.DateService), i0.inject(i3.AppConfigService)); }, token: MeasurementUnitService, providedIn: "root" });
    return MeasurementUnitService;
}());
export { MeasurementUnitService };

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PasswordInformation } from '../models/PasswordInformation';
import { User } from '../models/User';
import { DateService } from '../services/date.service';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';
import { ConfigurationModel } from '../shared-settings/configuration-model';

@Injectable()
export class UserService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }
    public getUsers(): Observable<User[]> {
        return this.httpService.get(`${this.configuration.UserManagementApiServer}api/User`);
    }
    public getUser(id: string): Observable<User> {
        return this.httpService.get(`${this.configuration.UserManagementApiServer}api/User/${encodeURIComponent(id)}`);
    }
    public updateUser(user: User): Observable<User> {
        return this.httpService.put(`${this.configuration.UserManagementApiServer}api/User`, JSON.stringify(user));
    }
    public createUserRegister(user: User): Observable<User> {
        return this.httpService.post(
            `${this.configuration.UserManagementApiServer}api/User/Register`,
            JSON.stringify(user)
        );
    }
    public createUser(user: User): Observable<User> {
        return this.httpService.post(`${this.configuration.UserManagementApiServer}api/User`, JSON.stringify(user));
    }
    public deleteUser(id: string): Observable<boolean> {
        return this.httpService.delete(
            `${this.configuration.UserManagementApiServer}api/User/${encodeURIComponent(id)}`
        );
    }
    public updateUserChangePassword(passwordChange: PasswordInformation): Observable<User> {
        return this.httpService.put(
            `${this.configuration.UserManagementApiServer}api/User/ChangePassword`,
            JSON.stringify(passwordChange)
        );
    }
}

import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.scss']
})
export class AgencyEditComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public agency: Agency = new Agency();

  constructor(public winService: WindowService,
    public stateService: CellStateService,
    public agencyService: AgencyService,
    public dialogService: DialogService,
    public validationService: ValidationService,
    public errorTranslationService: ErrorTranslationService,
    public activatedRoute: ActivatedRoute,
    public router: Router) {
    super();
    this.agency = new Agency();
  }

  ngAfterViewChecked() {
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.Load();
  }

  public Save() {
    this.busy();
    this.agencyService.updateAgency(this.agency).pipe(finalize(() => { this.ready(); })).subscribe(
      (agency) => {
        this.agency = agency;
        this.router.navigate(['/agencies']);
      },
      this.errorTranslationService.ErrorHandler);
  }

  public Cancel() {
    this.router.navigate(['/agencies']);
  }

  public Load() {
    this.busy();
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.agencyService.getAgency(params.get('id')).pipe(finalize(() => { this.ready(); }))
        ),
        finalize(() => { this.ready(); })
      ).subscribe(
        agency => {
          this.busy();
          this.agency = agency;
        },
        this.errorTranslationService.ErrorHandler);
  }
}

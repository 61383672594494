import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { SettingService } from '@xactly-app/common/src/lib/services/SettingService';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { finalize } from 'rxjs/operators';
var SettingsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsComponent, _super);
    function SettingsComponent(apiService, errorTranslationService, dialogService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.errorTranslationService = errorTranslationService;
        _this.dialogService = dialogService;
        return _this;
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.busy();
        this.apiService.getSettings().pipe(finalize(function () { _this.ready(); }))
            .subscribe(function (settings) {
            _this.settings = settings;
        }, this.errorTranslationService.ErrorHandler);
    };
    SettingsComponent.prototype.saveSetting = function (setting) {
        var _this = this;
        this.busy();
        this.apiService.updateSetting(setting).pipe(finalize(function () { _this.ready(); }))
            .subscribe(function (u) {
            if (_this.saveSettingStack.length > 0) {
                _this.saveSetting(_this.saveSettingStack.pop());
            }
            else {
                _this.dialogService.showOkDialog('Settings were saved successfully.', 'Save').subscribe();
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    SettingsComponent.prototype.save = function () {
        this.saveSettingStack = [];
        for (var _i = 0, _a = this.settings; _i < _a.length; _i++) {
            var setting = _a[_i];
            this.saveSettingStack.push(setting);
        }
        this.saveSetting(this.saveSettingStack.pop());
    };
    return SettingsComponent;
}(BaseComponent));
export { SettingsComponent };

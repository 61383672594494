<form #userForm="ngForm" (ngSubmit)="Save()">
    <mat-card>
      <mat-card-header>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <mat-card-title>
          <h2>Edit Agency</h2>
        </mat-card-title>
        <mat-card-subtitle>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>                                
        <div>
          <mat-form-field>
            <input matInput [(ngModel)]="agency.name" placeholder="Agency Name" maxlength="200" type="text" name="agency.name"
              #agencyname="ngModel" required>
            <mat-error *ngIf="agencyname.errors?.required">Agency name is required</mat-error>
          </mat-form-field>
        </div>              
      </mat-card-content>
      <mat-card-actions>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">Save</button>
        <button mat-raised-button color="warn" (click)="Cancel()" type="button">Cancel</button>        
      </mat-card-actions>
    </mat-card>
  </form>
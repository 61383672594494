<div *ngIf="!hasResetCode()">
    <instruction-card [message]="'This page will allow you to change your password'" [title]="'Change Password'">
    </instruction-card>
</div>
<div *ngIf="hasResetCode()">
    <instruction-card [message]="'Welcome to Xactly!  Please set your password on this page.  You can then use this password to login using your email address.'"
        [title]="'Set Password'">
    </instruction-card>
</div>
<form #userForm="ngForm" (ngSubmit)="changePassword()">
    <mat-card>       
        <mat-card-content>
            <div *ngIf="!hasResetCode()">
                <mat-form-field>
                    <input matInput [(ngModel)]="passwordInformation.oldPassword" placeholder="Old Password" maxlength="200" type="password"
                        #oldPasswordField="ngModel" name="oldPasswordField" required>
                    <mat-error *ngIf="oldPasswordField.errors?.required">Old password is required</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="passwordInformation.newPassword" placeholder="New Password" maxlength="200" type="password"
                        #newPasswordField="ngModel" name="newPasswordField" required>
                    <mat-error *ngIf="newPasswordField.errors?.required">New password is required</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="passwordConfirm" placeholder="Confirm Password" maxlength="200" type="password" #passwordConfirmField="ngModel"
                        name="passwordConfirmField" required>
                    <mat-error *ngIf="passwordConfirmField.errors?.required">Confirm password is required</mat-error>
                </mat-form-field>
            </div>
            <mat-error *ngIf="passwordConfirmField.valid && newPasswordField.valid && passwordConfirm !== passwordInformation.newPassword">New password and confirm password do not match</mat-error>
        </mat-card-content>
        <mat-card-actions>
            <loading-indicator *ngIf="loading"></loading-indicator>
            <button [disabled]="!userForm.valid || !passwordConfirmField.valid || !newPasswordField.valid || passwordConfirm !== passwordInformation.newPassword || loading" mat-raised-button color="primary" type="submit">Change Password</button>
        </mat-card-actions>
    </mat-card>
</form>
import { Injectable } from '@angular/core';
import * as concaveman from 'concaveman';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Diagram } from '../models/Diagram';
import { MeasurementUnit } from '../models/MeasurementUnit';
import { DiagramService } from './DiagramService';
import { ErrorTranslationService } from './error-translation.service';
import { MeasurementUnitService } from './MeasurementUnitService';
import { WindowService } from './window.service';
import { Utility } from '../utility';
import { SideCoordinate } from '../shapes/side-coordinate';
import { EquilateralTriangle } from '../shapes/equilateral-triangle';
import { Line } from '../shapes/line';
import { RightTriangle } from '../shapes/right-triangle';
import { Trapezoid } from '../shapes/trapezoid';
import { Rectangle } from '../shapes/rectangle';

declare var mxClient;
declare var Graph;
declare var mxEvent;
declare var mxConstants;
declare var mxUtils;
declare var SVGElement;
declare var mxEventObject;
declare var mxCodec;
declare var mxRectangle;
declare var mxResources;
declare var mxPrintPreview;
declare var mxKeyHandler;
declare var mxVertexHandler;
declare var mxPoint;
declare var mxUndoManager;
declare var mxGraphHandler;
declare var mxGraph;
declare var mxEdgeHandler;
declare var mxResources;
declare var mxTooltipHandler;

@Injectable()
export class GraphService {
  protected static Singleton: GraphService;
  private graph;
  private diagramMap = new Map<string, Diagram>();
  private diagramXmlMap = new Map<string, string>();
  private parentDiagram: Diagram;
  private scale = 1;
  private measurementUnit: MeasurementUnit;
  private undoManager;
  private measurementUnits: MeasurementUnit[];
  private editorContainer: Element;
  private loading = false;
  private saveRequired = false;
  private currentDiagramId = '';
  private isMultiSelect = false;

  constructor(private winService: WindowService,
    private diagramService: DiagramService,
    private measurementUnitService: MeasurementUnitService,
    public errorTranslationService: ErrorTranslationService) {
    GraphService.Singleton = this;
    this.InitializeLibraryOverrides();
    this.measurementUnit = new MeasurementUnit();
    this.measurementUnit.singleName = 'Meter';
    this.measurementUnit.squaredName = 'm²';
    this.measurementUnit.shortName = 'm';
    this.measurementUnit.pluralName = 'Meters';
    this.measurementUnit.description = 'Meters';
  }

  protected static createHint() {
    const hint = document.createElement('div');
    hint.className = 'geHint';
    hint.style.whiteSpace = 'nowrap';
    hint.style.position = 'absolute';
    return hint;
  }

  private InitializeLibraryOverrides() {

    mxGraph.prototype.isCellBendable = function (cell) {
      return false;
      // var state = this.view.getState(cell);
      // var style = (state != null) ? state.style : this.getCellStyle(cell);
      // return this.isCellsBendable() && !this.isCellLocked(cell) && style[mxConstants.STYLE_BENDABLE] != 0;
    };

    mxTooltipHandler.prototype.enabled = false;

    mxTooltipHandler.prototype.mouseMove = (sender, me) => { };

    mxTooltipHandler.prototype.init = () => { };

    mxTooltipHandler.prototype.show = (tip, x, y) => { };
    mxGraph.prototype.selectCellForEvent = function (cell, evt) {
      const isSelected = this.isCellSelected(cell);

      if (GraphService.Singleton.isMultiSelect || this.isToggleEvent(evt)) {
        if (isSelected) {
          this.removeSelectionCell(cell);
        } else {
          this.addSelectionCell(cell);
        }
      } else if (!isSelected || this.getSelectionCount() !== 1) {
        this.setSelectionCell(cell);
      }
    };

    mxVertexHandler.prototype.updateHint = function (me) {
      if (this.index !== mxEvent.LABEL_HANDLE) {
        if (this.hint == null) {
          this.hint = GraphService.createHint();
          this.state.view.graph.container.appendChild(this.hint);
        }

        if (this.index === mxEvent.ROTATION_HANDLE) {
          this.hint.innerHTML = this.currentAlpha + '&deg;';
        } else {
          const unitScale = GraphService.Singleton.GetScale();
          this.hint.innerHTML = (this.bounds.width * unitScale / this.state.view.scale).toFixed(2)
            + ' x ' +
            (this.bounds.height * unitScale / this.state.view.scale).toFixed(2);
        }

        const rot = (this.currentAlpha != null) ? this.currentAlpha : this.state.style[mxConstants.STYLE_ROTATION] || '0';
        let bb = mxUtils.getBoundingBox(this.bounds, rot);

        if (bb == null) {
          bb = this.bounds;
        }

        this.hint.style.left = bb.x + Math.round((bb.width - this.hint.clientWidth) / 2) + 'px';
        this.hint.style.top = (bb.y + bb.height + 12) + 'px';

        if (this.linkHint != null) {
          this.linkHint.style.display = 'none';
        }
      }
    };

    mxGraphHandler.prototype.mouseUp = function (sender, me) {
      if (!me.isConsumed()) {
        const graph = this.graph;
        // console.log('Graph Service initialized.')
        if (this.cell != null && this.first != null && this.shape != null &&
          this.currentDx != null && this.currentDy != null) {
          const cell = me.getCell();
          if (this.connectOnDrop && this.target == null && cell != null && graph.getModel().isVertex(cell) &&
            graph.isCellConnectable(cell) && graph.isEdgeValid(null, this.cell, cell)) {
            graph.connectionHandler.connect(this.cell, cell, me.getEvent());
          } else {
            const clone = graph.isCloneEvent(me.getEvent()) && graph.isCellsCloneable() && this.isCloneEnabled();
            const scale = graph.getView().scale;
            const dx = this.roundLength(this.currentDx / scale);
            const dy = this.roundLength(this.currentDy / scale);
            const target = this.target;

            if (graph.isSplitEnabled() && graph.isSplitTarget(target, this.cells, me.getEvent())) {
              graph.splitEdge(target, this.cells, null, dx, dy);
            } else {
              // Jeff: had to override the target parameter to null otherwise cells were being reparented...
              // no option to disable this. This was the only change to this method.
              this.moveCells(this.cells, dx, dy, clone, null, me.getEvent());
            }
          }
        } else if (this.isSelectEnabled() && this.delayedSelection && this.cell != null) {
          this.selectDelayed(me);
        }
      }

      // Consumes the event if a cell was initially clicked
      if (this.cellWasClicked) {
        this.consumeMouseEvent(mxEvent.MOUSE_UP, me);
      }

      this.reset();
    };

    SVGElement.prototype.getTransformToElement = SVGElement.prototype.getTransformToElement || function (elem) {
      return elem.getScreenCTM().inverse().multiply(this.getScreenCTM());
    };

    mxEdgeHandler.prototype.start = function (x, y, index) {
      this.startX = x;
      this.startY = y;

      this.isSource = (this.bends == null) ? false : index === 0;
      this.isTarget = (this.bends == null) ? false : index === this.bends.length - 1;
      this.isLabel = index === mxEvent.LABEL_HANDLE;

      if (this.isSource || this.isTarget) {
        const cell = this.state.cell;
        if (cell.style.includes('shapeLine')) {
          const terminal = this.graph.model.getTerminal(cell, this.isSource);
          if ((terminal == null && this.graph.isTerminalPointMovable(cell, this.isSource)) ||
            (terminal != null && this.graph.isCellDisconnectable(cell, terminal, this.isSource))) {
            this.index = index;
          }
        }
      } else {
        this.index = index;
      }

      // Hides other custom handles
      if (this.index <= mxEvent.CUSTOM_HANDLE && this.index > mxEvent.VIRTUAL_HANDLE) {
        if (this.customHandles != null) {
          for (let i = 0; i < this.customHandles.length; i++) {
            if (i !== mxEvent.CUSTOM_HANDLE - this.index) {
              this.customHandles[i].setVisible(false);
            }
          }
        }
      }
    };

    if (typeof mxGraph.prototype.isCellMovableEx === 'undefined') {
      mxGraph.prototype.isCellMovableEx = this.winService.nativeWindow.mxGraph.prototype.isCellMovable;
      mxGraph.prototype.isCellMovable = function (cell) {
        const selectedCells = this.getSelectionCells();
        let nonGroupSelected = false;
        for (let i = 0; i < selectedCells.length; i++) {
          if (!selectedCells[0].style.includes('shapeLine') &&
            !selectedCells[0].style.includes('group') &&
            selectedCells[0].id !== 'text-cell' &&
            selectedCells[0].id !== 'description-cell' &&
            selectedCells[0].id !== 'diagram-text-cell') {
            nonGroupSelected = true;
          }
        }
        if (nonGroupSelected) {
          return false;
        } else {
          return this.isCellMovableEx(cell);
        }
      };
    }

    if (typeof mxGraph.prototype.isCellsMovableEx === 'undefined') {
      mxGraph.prototype.isCellsMovableEx = this.winService.nativeWindow.mxGraph.prototype.isCellsMovable;
      mxGraph.prototype.isCellsMovable = function (cell) {
        const selectedCells = this.getSelectionCells();
        let nonGroupSelected = false;
        for (let i = 0; i < selectedCells.length; i++) {
          if (!selectedCells[0].style.includes('shapeLine') &&
            !selectedCells[0].style.includes('group') &&
            selectedCells[0].id !== 'text-cell' &&
            selectedCells[0].id !== 'description-cell' &&
            selectedCells[0].id !== 'diagram-text-cell') {
            nonGroupSelected = true;
          }
        }
        if (nonGroupSelected) {
          return false;
        } else {
          return this.isCellsMovableEx(cell);
        }
      };
    }

    mxGraph.prototype.splitEnabled = function (cell) {
      return false;
    };

    mxGraph.prototype.isCellRotatable = function (cell) {
      return false;
    };

    if (typeof mxGraph.prototype.isCellResizableEx === 'undefined') {
      mxGraph.prototype.isCellResizableEx = this.winService.nativeWindow.mxGraph.prototype.isCellResizable;
      mxGraph.prototype.isCellResizable = function (cell) {
        // console.log('isCellResizable CALLED');
        const selectedCells = this.getSelectionCells();
        let nonGroupSelected = false;
        for (let i = 0; i < selectedCells.length; i++) {
          if (!selectedCells[0].style.includes('shapeLine') &&
            !selectedCells[0].style.includes('group') &&
            selectedCells[0].id !== 'text-cell' &&
            selectedCells[0].id !== 'description-cell' &&
            selectedCells[0].id !== 'diagram-text-cell') {
            nonGroupSelected = true;
          }
        }
        if (nonGroupSelected) {
          // console.log('isCellResizable false');
          return false;
        } else {
          return this.isCellResizableEx(cell);
        }
      };
    }

    if (typeof mxGraph.prototype.isCellsResizableEx === 'undefined') {
      mxGraph.prototype.isCellsResizableEx = this.winService.nativeWindow.mxGraph.prototype.isCellsResizable;
      mxGraph.prototype.isCellsResizable = function (cell) {
        //  console.log('isCellsResizable CALLED');
        const selectedCells = this.getSelectionCells();
        let nonGroupSelected = false;
        for (let i = 0; i < selectedCells.length; i++) {
          if (!selectedCells[0].style.includes('shapeLine') &&
            !selectedCells[0].style.includes('group') &&
            selectedCells[0].id !== 'text-cell' &&
            selectedCells[0].id !== 'description-cell' &&
            selectedCells[0].id !== 'diagram-text-cell') {
            nonGroupSelected = true;
          }
        }
        if (nonGroupSelected) {
          //  console.log('isCellsResizable false');
          return false;
        } else {
          return this.isCellsResizableEx(cell);
        }
      };
    }
  }

  private LoadGraph(diagram: Diagram) {
    if (this.IsGraphReady()) {
      this.currentDiagramId = diagram.id;
      this.SetScale(diagram.graphScale);
      this.SetGridSize(diagram.gridSize);
      const xml = this.GetXmlCache(diagram.id);
      if (!Utility.IsEmptyOrNull(xml)) {
        this.SetModelXml(xml);
      } else {
        this.SetModelXml('');
      }
      this.AddDescriptionCell(diagram.pageName);
      this.AddDiagramTextCell(this.parentDiagram.description);
      this.ClearUndo();
      this.loading = false;
    }
  }

  private DeleteCache(key: string) {
    delete this.diagramXmlMap[key];
    delete this.diagramMap[key];
  }
  private SetXmlCache(key: string, xml: string) {
    this.diagramXmlMap[key] = xml;
  }

  private GetXmlCache(key: string): string {
    return this.diagramXmlMap[key];
  }

  private SetDiagramCache(key: string, diagram: Diagram) {
    this.diagramMap[key] = diagram;
  }

  private GetDiagramCache(key: string): Diagram {
    return this.diagramMap[key];
  }

  public Load(parentDiagramId: string, editorContainer: Element) {
    this.loading = true;
    const complete = new Subject<boolean>();
    this.editorContainer = editorContainer;
    this.GetDiagram(parentDiagramId).subscribe((parentDiagram: Diagram) => {
      forkJoin(
        this.GetDiagramChildren(parentDiagram.id),
        this.GetMeasurements()
      )
        .subscribe(results => {
          let index = 0;
          this.measurementUnits = results[1];
          this.parentDiagram = parentDiagram;
          this.currentDiagramId = parentDiagram.id;

          if (Utility.IsEmptyOrNull(this.parentDiagram.value)) {
            this.parentDiagram.graphScale = 0.02;
            this.parentDiagram.gridSize = 10;
          }

          if (Utility.IsEmptyOrNull(parentDiagram.pageName)) {
            parentDiagram.pageName = 'Main Floor';
          }

          this.SetDiagramCache(parentDiagram.id, parentDiagram);
          this.SetXmlCache(parentDiagram.id, parentDiagram.value);

          results[0].forEach((child) => {
            index++;
            if (Utility.IsEmptyOrNull(child.pageName)) {
              child.pageName = 'Floor ' + index.toString();
            }
            this.SetDiagramCache(child.id, child);
            this.SetXmlCache(child.id, child.value);
          });

          let meterMeasurement = null;
          if (typeof parentDiagram.measurementUnitId === 'undefined' || parentDiagram.measurementUnitId === null) {
            meterMeasurement = this.measurementUnits.find(x => x.singleName === 'Meter');
            parentDiagram.measurementUnitId = meterMeasurement.id;
            this.Measurement = meterMeasurement;
          } else {
            meterMeasurement = this.measurementUnits.find(x => x.id === parentDiagram.measurementUnitId);
            this.Measurement = meterMeasurement;
          }
          this.ShowGraph(editorContainer).subscribe(() => {
            this.LoadGraph(parentDiagram);
            complete.next(true);
          });
        },
          this.errorTranslationService.ErrorHandler);
    });
    return complete;
  }

  public LoadDiagram(id: string) {
    let complete = new Subject<boolean>();
    if (this.loading) {
      complete = new BehaviorSubject<boolean>(true);
      return complete;
    }
    this.loading = true;
    const cachedDiagram = this.GetDiagramCache(id);
    if (Utility.IsNull(cachedDiagram) || Utility.IsNull(this.GetXmlCache(cachedDiagram.id))) {
      this.GetDiagram(id).subscribe((diagram) => {
        this.currentDiagramId = id;
        this.SetXmlCache(id, diagram.value);
        this.SetDiagramCache(id, diagram);
        let meterMeasurement = null;
        if (Utility.IsNull(diagram.measurementUnitId)) {
          meterMeasurement = this.measurementUnits.find(x => x.singleName === 'Meter');
          diagram.measurementUnitId = meterMeasurement.id;
          this.Measurement = meterMeasurement;
        } else {
          meterMeasurement = this.measurementUnits.find(x => x.id === diagram.measurementUnitId);
          this.Measurement = meterMeasurement;
        }
        this.editorContainer.children[0].innerHTML = '';
        this.ShowGraph(this.editorContainer).subscribe(() => {
          this.LoadGraph(diagram);
          complete.next(true);
        });
      },
        this.errorTranslationService.ErrorHandler);
    } else {
      this.currentDiagramId = id;
      let meterMeasurement = null;
      if (Utility.IsNull(cachedDiagram.measurementUnitId)) {
        meterMeasurement = this.measurementUnits.find(x => x.singleName === 'Meter');
        cachedDiagram.measurementUnitId = meterMeasurement.id;
        this.Measurement = meterMeasurement;
      } else {
        meterMeasurement = this.measurementUnits.find(x => x.id === cachedDiagram.measurementUnitId);
        this.Measurement = meterMeasurement;
      }
      this.editorContainer.children[0].innerHTML = '';
      this.ShowGraph(this.editorContainer).subscribe(() => {
        this.LoadGraph(cachedDiagram);
        complete.next(true);
      });
    }
    return complete;
  }

  public ShowGraph(graphElement: Element) {
    const complete = new Subject<boolean>();
    const editorUiInit = this.winService.nativeWindow.EditorUi.prototype.init;
    this.winService.nativeWindow.EditorUi.prototype.init = function () {
      editorUiInit.apply(this, arguments);
    };
    // Adds required resources (disables loading of fallback properties, this can only
    // be used if we know that all keys are defined in the language specific file)
    mxResources.loadDefaultBundle = false;
    const bundle = this.winService.nativeWindow.mxResources.getDefaultBundle(
      this.winService.nativeWindow.RESOURCE_BASE, this.winService.nativeWindow.mxLanguage) ||
      this.winService.nativeWindow.mxResources.getSpecialBundle(
        this.winService.nativeWindow.RESOURCE_BASE, this.winService.nativeWindow.mxLanguage);
    // Fixes possible asynchronous requests
    this.winService.nativeWindow.mxUtils.getAll([bundle, this.winService.nativeWindow.STYLE_PATH + '/default.xml'], (xhr) => {
      // Adds bundle text to resources
      this.winService.nativeWindow.mxResources.parse(xhr[0].getText());
      // Configures the default graph theme
      const themes = new Object();
      themes[this.winService.nativeWindow.Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();
      // Main
      this.winService.nativeWindow.EditorUIInstance = null;
      this.winService.nativeWindow.EditorUIInstance =
        new this.winService.nativeWindow.EditorUi(
          new this.winService.nativeWindow.Editor(this.winService.nativeWindow.urlParams['chrome'] === '0', themes),
          graphElement);

      this.undoManager = new mxUndoManager();
      this.graph = this.winService.nativeWindow.EditorUIInstance.editor.graph;

      this.graph.connectionArrowsEnabled = false;
      this.InitializeUndoManager(this.graph, this.undoManager);

      this.graph.addListener(this.winService.nativeWindow.mxEvent.CELLS_RESIZED, (sender, evt) => {
        setTimeout(() => { this.UpdateAllSizeLabels(); }, 200);
      });

      // this.graph.model.addListener(this.winService.nativeWindow.mxEvent.CHANGE, (sender, evt) => {
      //   if (!this.loading) {
      //     const xml = this.GetModelXml();
      //     this.SetXmlCache(this.currentDiagramId, xml);
      //   }
      // });

      // this.graph.model.addListener(this.winService.nativeWindow.mxEvent.NOTIFY, (sender, evt) => {
      //   if (!this.loading) {
      //     const xml = this.GetModelXml();
      //     this.SetXmlCache(this.currentDiagramId, xml);
      //   }
      // });

      this.graph.model.addListener(this.winService.nativeWindow.mxEvent.END_EDIT, (sender, evt) => {
        if (!this.loading) {
          const xml = this.GetModelXml();
          this.SetXmlCache(this.currentDiagramId, xml);
        }
      });

      this.graph.convertValueToString = (cell) => {
        if (mxUtils.isNode(cell.value)) {
          return cell.value.getAttribute('label', '');
        } else {
          return cell.value;
        }
      };

      const cellLabelChanged = this.graph.cellLabelChanged;
      this.graph.cellLabelChanged = function (cell, newValue, autoSize) {
        if (mxUtils.isNode(cell.value)) {
          // Clones the value for correct undo/redo
          const elt = cell.value.cloneNode(true);
          elt.setAttribute('label', newValue);
          newValue = elt;
        } else {
          cell.value = newValue;
        }
        cellLabelChanged.apply(this, arguments);
      };
      complete.next(true);
    }, () => {
      document.body.innerHTML = '<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
    });
    return complete;
  }

  public ToggleMultiSelect() {
    this.isMultiSelect = !this.isMultiSelect;
  }

  public GridSize(size: number) {
    this.graph.gridSize(size);
  }

  public ZoomIn() {
    this.graph.zoomIn();
  }

  public ZoomOut() {
    this.graph.zoomOut();
  }

  public FitWindow() {
    this.graph.fit();
  }

  public FitPage() {
    const fmt = this.graph.pageFormat;
    const ps = this.graph.pageScale;
    const cw = this.graph.container.clientWidth - 10;
    const ch = this.graph.container.clientHeight - 10;
    const scale = Math.floor(20 * Math.min(cw / fmt.width / ps, ch / fmt.height / ps)) / 20;
    this.graph.zoomTo(scale);

    if (mxUtils.hasScrollbars(this.graph.container)) {
      const pad = this.graph.getPagePadding();
      this.graph.container.scrollTop = pad.y * this.graph.view.scale;
      this.graph.container.scrollLeft = Math.min(pad.x * this.graph.view.scale,
        (this.graph.container.scrollWidth - this.graph.container.clientWidth) / 2);
    }
  }

  public SendToFront() {
    this.graph.orderCells(false);
  }

  public SendToBack() {
    this.graph.orderCells(true);
  }

  private GetPathCoordinates(cell: any, path: any, terminalOnly: boolean): Array<Array<number>> {
    const totalLength = Math.ceil(path.getTotalLength());
    const points = [];

    if (terminalOnly) {
      const abspoint0 = path.getPointAtLength(0);
      const abspoint1 = path.getPointAtLength(totalLength);
      abspoint0.x = abspoint0.x / this.graph.view.scale;
      abspoint0.y = abspoint0.y / this.graph.view.scale;
      abspoint1.x = abspoint1.x / this.graph.view.scale;
      abspoint1.y = abspoint1.y / this.graph.view.scale;
      points.push([abspoint0.x, abspoint0.y, cell]);
      points.push([abspoint1.x, abspoint1.y, cell]);
    } else {
      for (let index = 0; index < path.getTotalLength(); index++) {
        const point = path.getPointAtLength(index);
        point.x = point.x / this.graph.view.scale;
        point.y = point.y / this.graph.view.scale;
        points.push([point.x, point.y, cell]);
      }
    }

    return points;
  }

  private GetCellPoints(cell, points: Array<Array<number>> = null, terminalOnly: boolean): Array<Array<number>> {
    if (cell.children != null && cell.children.length > 0) {
      cell.children.forEach((child) => {
        this.GetCellPoints(child, points, terminalOnly);
      });
    } else {
      const shape = this.graph.view.getState(cell).shape;
      if (shape.node.children.length > 0) {
        const path = shape.node.children[0];
        if (typeof path !== 'undefined' && path !== null) {
          points = this.GetPathCoordinates(cell, path, terminalOnly);
        }
      }
    }
    return points;
  }

  public TranslatePoint(point: number[]): number[] {
    let dx = this.graph.container.scrollLeft / this.graph.view.scale - this.graph.view.translate.x;
    let dy = this.graph.container.scrollTop / this.graph.view.scale - this.graph.view.translate.y;

    const layout = this.graph.getPageLayout();
    const page = this.graph.getPageSize();
    dx = Math.max(dx, layout.x * page.width);
    dy = Math.max(dy, layout.y * page.height);
    return [point[0] - this.graph.view.translate.x, point[1] - this.graph.view.translate.y];
  }

  private PointDifference(coordinateOne: Array<number>, coordinateTwo: Array<number>): number {
    return Math.abs(coordinateOne[0] - coordinateTwo[0]) + Math.abs(coordinateOne[1] - coordinateTwo[1]);
  }

  public GetDistanceBetweenPoints(x0: number, y0: number, x1: number, y1: number): number {
    const a = x0 - x1;
    const b = y0 - y1;
    return Math.sqrt(a * a + b * b);
  }

  public SortCells(cells) {
    const points: Array<Array<number>> = new Array<Array<number>>();
    cells.forEach((cell) => {
      const cellPoints = this.GetCellPoints(cell, null, false);
      cellPoints.forEach((point: number[]) => {
        const translatedPoints = <any[]>this.TranslatePoint(point);
        translatedPoints.push(cell);
        points.push(translatedPoints);
      });
    });

    const polygonPoints = <Array<any>>concaveman(points);
    const sortedCellList = [];
    polygonPoints.forEach((polygonPoint) => {
      let found = false;
      cells = cells.filter((selectedCell) => {
        if (!found && selectedCell.mxObjectId === polygonPoint[2].mxObjectId) {
          sortedCellList.push(selectedCell);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });
    return sortedCellList;
  }

  private GetCellSortedPoints(cells): Array<Array<Array<number>>> {
    const previousCell = null;
    const nextCell = null;
    let currentCell = null;
    const sortedCellList = this.SortCells(cells);
    const sortedCellPoints = new Array<Array<Array<number>>>();
    for (let cellIndex = 0; cellIndex < sortedCellList.length; cellIndex++) {
      currentCell = sortedCellList[cellIndex];
      let startPointX = 0;
      let startPointY = 0;
      let endPointX = 0;
      let endPointY = 0;
      const cellPoints = this.GetCellPoints(currentCell, null, true);
      startPointX = cellPoints[0][0];
      startPointY = cellPoints[0][1];
      endPointX = cellPoints[1][0];
      endPointY = cellPoints[1][1];
      sortedCellPoints.push([[startPointX, startPointY], [endPointX, endPointY]]);
    }
    return sortedCellPoints;
  }

  public SortCoordinates(coordinates: SideCoordinate[]) {
    let nextSideCoordinate: SideCoordinate = null;
    let previousSideCoordinate: SideCoordinate = null;
    let currentSideCoordinate: SideCoordinate = null;
    for (let index = 0; index < coordinates.length; index++) {

      currentSideCoordinate = coordinates[index];
      if (index + 1 < coordinates.length) {
        nextSideCoordinate = coordinates[index + 1];
      } else {
        nextSideCoordinate = null;
      }

      if (index - 1 >= 0) {
        previousSideCoordinate = coordinates[index - 1];
      } else {
        previousSideCoordinate = null;
      }

      if (nextSideCoordinate == null) {
        if (previousSideCoordinate !== null) {
          const startAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.startX,
            currentSideCoordinate.startY,
            previousSideCoordinate.startX,
            previousSideCoordinate.startY);
          const startAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.startX,
            currentSideCoordinate.startY,
            previousSideCoordinate.endX,
            previousSideCoordinate.endY);
          const endAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.endX,
            currentSideCoordinate.endY,
            previousSideCoordinate.startX,
            previousSideCoordinate.startY);
          const endAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.endX,
            currentSideCoordinate.endY,
            previousSideCoordinate.endX,
            previousSideCoordinate.endY);

          const min = Math.min(startAndStart, startAndEnd, endAndStart, endAndEnd);

          if (startAndStart === min) {
            const startX = previousSideCoordinate.startX;
            const startY = previousSideCoordinate.startY;
            const endX = previousSideCoordinate.endX;
            const endY = previousSideCoordinate.endY;

            previousSideCoordinate.startY = endY;
            previousSideCoordinate.startX = endX;
            previousSideCoordinate.endX = startX;
            previousSideCoordinate.endY = startY;
          } else if (startAndEnd === min) {
            // do nothing... we are good
          } else if (endAndStart === min) {
            let startX = currentSideCoordinate.startX;
            let startY = currentSideCoordinate.startY;
            let endX = currentSideCoordinate.endX;
            let endY = currentSideCoordinate.endY;

            currentSideCoordinate.startY = endY;
            currentSideCoordinate.startX = endX;
            currentSideCoordinate.endX = startX;
            currentSideCoordinate.endY = startY;

            startX = previousSideCoordinate.startX;
            startY = previousSideCoordinate.startY;
            endX = previousSideCoordinate.endX;
            endY = previousSideCoordinate.endY;

            previousSideCoordinate.startY = endY;
            previousSideCoordinate.startX = endX;
            previousSideCoordinate.endX = startX;
            previousSideCoordinate.endY = startY;
          } else if (endAndEnd === min) {
            const startX = currentSideCoordinate.startX;
            const startY = currentSideCoordinate.startY;
            const endX = currentSideCoordinate.endX;
            const endY = currentSideCoordinate.endY;

            currentSideCoordinate.startY = endY;
            currentSideCoordinate.startX = endX;
            currentSideCoordinate.endX = startX;
            currentSideCoordinate.endY = startY;
          }
        }
      } else {
        const startAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.startX,
          currentSideCoordinate.startY,
          nextSideCoordinate.startX,
          nextSideCoordinate.startY);
        const startAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.startX,
          currentSideCoordinate.startY,
          nextSideCoordinate.endX,
          nextSideCoordinate.endY);
        const endAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.endX,
          currentSideCoordinate.endY,
          nextSideCoordinate.startX,
          nextSideCoordinate.startY);
        const endAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.endX,
          currentSideCoordinate.endY,
          nextSideCoordinate.endX,
          nextSideCoordinate.endY);

        const min = Math.min(startAndStart, startAndEnd, endAndStart, endAndEnd);
        if (startAndStart === min) {
          const startX = currentSideCoordinate.startX;
          const startY = currentSideCoordinate.startY;
          const endX = currentSideCoordinate.endX;
          const endY = currentSideCoordinate.endY;

          currentSideCoordinate.startY = endY;
          currentSideCoordinate.startX = endX;
          currentSideCoordinate.endX = startX;
          currentSideCoordinate.endY = startY;
        } else if (startAndEnd === min) {
          let startX = currentSideCoordinate.startX;
          let startY = currentSideCoordinate.startY;
          let endX = currentSideCoordinate.endX;
          let endY = currentSideCoordinate.endY;

          currentSideCoordinate.startY = endY;
          currentSideCoordinate.startX = endX;
          currentSideCoordinate.endX = startX;
          currentSideCoordinate.endY = startY;

          startX = nextSideCoordinate.startX;
          startY = nextSideCoordinate.startY;
          endX = nextSideCoordinate.endX;
          endY = nextSideCoordinate.endY;

          nextSideCoordinate.startY = endY;
          nextSideCoordinate.startX = endX;
          nextSideCoordinate.endX = startX;
          nextSideCoordinate.endY = startY;
        } else if (endAndStart === min) {
          // do nothing... we are good
        } else if (endAndEnd === min) {
          const startX = nextSideCoordinate.startX;
          const startY = nextSideCoordinate.startY;
          const endX = nextSideCoordinate.endX;
          const endY = nextSideCoordinate.endY;

          nextSideCoordinate.startY = endY;
          nextSideCoordinate.startX = endX;
          nextSideCoordinate.endX = startX;
          nextSideCoordinate.endY = startY;
        }
      }
    }
    return coordinates;
  }

  public CreateLinesFromShapeSelection() {
    const cell = this.GetSelectedOneShape();
    if (cell !== null && this.IsCustomShape(cell)) {
      cell.children.forEach((childCell) => {
        childCell.style = childCell.style.replace('shapeSide', 'shapeLine');
      });
      this.RemoveGroup([cell]);
    }
  }


  public CreateShapeFromSelection() {
    this.graph.getModel().beginUpdate();
    try {

      const selectedCells = this.graph.getSelectionCells();
      const cellPoints = this.GetCellSortedPoints(selectedCells);
      let sideCoordinates = new Array<SideCoordinate>();
      cellPoints.forEach((points: number[][]) => {
        const sideCoordinate = new SideCoordinate();
        sideCoordinate.startX = this.TranslatePoint(points[0])[0];
        sideCoordinate.startY = this.TranslatePoint(points[0])[1];
        sideCoordinate.endX = this.TranslatePoint(points[1])[0];
        sideCoordinate.endY = this.TranslatePoint(points[1])[1];
        sideCoordinate.side = '1';
        sideCoordinates.push(sideCoordinate);
      });

      sideCoordinates = this.SortCoordinates(sideCoordinates);
      this.AddShape(sideCoordinates, 'customShape', false, true);
      this.DeleteSelectedShapes();
    }
    finally {
      // Updates the display
      this.graph.getModel().endUpdate();
      this.RefreshAll();
    }
  }

  public FitPageWidth() {
    const fmt = this.graph.pageFormat;
    const ps = this.graph.pageScale;
    const cw = this.graph.container.clientWidth - 10;

    const scale = Math.floor(20 * cw / fmt.width / ps) / 20;
    this.graph.zoomTo(scale);

    if (mxUtils.hasScrollbars(this.graph.container)) {
      const pad = this.graph.getPagePadding();
      this.graph.container.scrollLeft = Math.min(pad.x * this.graph.view.scale,
        (this.graph.container.scrollWidth - this.graph.container.clientWidth) / 2);
    }
  }

  public GetCellById(id: string) {
    let foundCell = null;
    const totalArea = 0;
    const allCells = this.graph.getChildCells();
    allCells.forEach(
      (cell) => {
        if (cell.id === id) {
          foundCell = cell;
          return;
        }
      });
    return foundCell;
  }

  private GetAreaTextCell() {
    let cell = this.GetCellById('text-cell');
    if (cell === null) {
      const freeInsertPoint = this.GetFreeInsertPoint();
      const geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
      cell = new this.winService.nativeWindow.mxCell(null, geometry,
        'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
      cell.setConnectable(false);
      cell.id = 'text-cell';
      cell.setVertex(true);
      this.graph.addCells([cell], this.graph.getDefaultParent());
    }
    return cell;
  }

  private GetDiagramTextCell() {
    let cell = this.GetCellById('diagram-text-cell');
    if (cell === null) {
      const freeInsertPoint = this.GetFreeInsertPoint();
      const geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
      cell = new this.winService.nativeWindow.mxCell(null, geometry,
        'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
      cell.setConnectable(false);
      cell.id = 'diagram-text-cell';
      cell.setVertex(true);
      this.graph.addCells([cell], this.graph.getDefaultParent());
    }
    return cell;
  }

  private GetDescriptionTextCell() {
    let cell = this.GetCellById('description-cell');
    if (cell === null) {
      const freeInsertPoint = this.GetFreeInsertPoint();
      const geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
      cell = new this.winService.nativeWindow.mxCell(null, geometry,
        'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
      cell.setConnectable(false);
      cell.id = 'description-cell';
      cell.setVertex(true);
      this.graph.addCells([cell], this.graph.getDefaultParent());
    }
    return cell;
  }

  private CreateCell(startX: number, startY: number, endX: number, endY: number, side, isShapeSide: boolean) {
    let styleExtras = 'shapeSide;endArrow=none;resizable=1;movable=1;editable=0;cloneable=0;deletable=1;connectable=0;html=1;strokeColor=#000000';
    if (!isShapeSide) {
      styleExtras = 'shapeLine;endArrow=none;resizable=1;movable=1;editable=0;cloneable=0;deletable=1;connectable=1;html=1;strokeColor=#000000';
    }
    const startPoint = new this.winService.nativeWindow.mxPoint(startX, startY);
    const endPoint = new this.winService.nativeWindow.mxPoint(endX, endY);
    const geometry = new this.winService.nativeWindow.mxGeometry(startX, startY, endY - startY, .1);
    geometry.setTerminalPoint(startPoint, true);
    geometry.setTerminalPoint(endPoint, false);
    const cell = new this.winService.nativeWindow.mxCell(null, geometry, styleExtras);
    cell.edge = true;
    const doc = mxUtils.createXmlDocument();
    const node = doc.createElement('SideData');
    node.setAttribute('label', '');
    node.setAttribute('hidden', '0');
    node.setAttribute('side', side);
    node.setAttribute('sideThickness', '0');
    cell.value = node;
    return cell;
  }

  public SetScale(scale: number) {
    if (this.IsGraphReady()) {
      this.scale = parseFloat(scale.toFixed(2));
      this.UpdateAllSizeLabels();
      this.GetArea();
      this.graph.refresh();
    }
  }

  public GetScale() {
    return this.scale;
  }

  public GetGraph() {
    if (this.IsGraphReady()) {
      return this.graph;
    } else {
      return null;
    }
  }

  private UpdateCellSizeLabels(cell) {
    if (cell.style.includes('group') || (cell.children !== null && cell.children.length > 0)) {
      cell.children.forEach((child) => {
        this.UpdateCellSizeLabels(child);
      });
    } else {
      const state = this.graph.view.getState(cell);
      if (typeof state !== 'undefined' && state !== null && state.shape !== null) {

        if (this.IsSideLabelHidden(cell)) {
          this.SetCellAttribute(cell, 'label', '');
        } else {
          if (state.shape.node.children.length > 0) {
            const path = state.shape.node.children[0];
            const length = path.getTotalLength() * this.scale / this.graph.view.scale;
            const thickness = this.GetSideThickness(cell);
            if (thickness !== 0) {
              this.SetCellAttribute(cell, 'label', length.toFixed(2) + ' (' + thickness + ') ' + this.measurementUnit.shortName);
            } else {
              this.SetCellAttribute(cell, 'label', length.toFixed(2) + ' ' + this.measurementUnit.shortName);
            }
          }
        }
      }
    }
  }

  public GetSideLength(cell) {
    let length = 0;
    const state = this.graph.view.getState(cell);
    if (typeof state !== 'undefined' && state !== null && state.shape !== null) {
      if (state.shape.node.children.length > 0) {
        const path = state.shape.node.children[0];
        length = path.getTotalLength() * this.scale / this.graph.view.scale;
      }
    }
    return length;
  }

  private UpdateAllSizeLabels() {
    if (!this.IsGraphReady()) {
      return;
    }

    this.graph.getModel().beginUpdate();
    try {
      let allCells = this.graph.getChildVertices(this.graph.getDefaultParent());
      allCells.forEach(
        (cell) => {
          if (!this.IsTextCell(cell)) {
            this.UpdateCellSizeLabels(cell);
          }
        }
      );

      allCells = this.graph.getChildEdges();
      allCells.forEach(
        (cell) => {
          if (!this.IsTextCell(cell)) {
            this.UpdateCellSizeLabels(cell);
          }
        }
      );

      this.graph.refresh();
    }
    finally {
      // Updates the display
      this.graph.getModel().endUpdate();
      this.graph.refresh();
    }
  }

  public SetSelectedCellsAttribute(key: string, value: string) {
    const cells = this.graph.getSelectionCells();
    cells.forEach((cell) => {
      if (mxUtils.isNode(cell.value)) {
        cell.value.setAttribute(key, value);
      }
    });
  }

  public SetCellAttribute(cell, key: string, value: string) {
    if (mxUtils.isNode(cell.value)) {
      cell.value.setAttribute(key, value);
    }
    const xml = this.GetModelXml();
    this.SetXmlCache(this.currentDiagramId, xml);
  }

  public GetCellAttribute(cell, key: string) {
    let value = '';
    if (mxUtils.isNode(cell.value)) {
      value = cell.value.getAttribute(key, '');
    }
    return value;
  }

  public GetSideDirection(cell) {
    return this.GetCellAttribute(cell, 'side');
  }

  public GetInsertPoint() {
    const gs = this.graph.getGridSize();
    let dx = this.graph.container.scrollLeft / this.graph.view.scale - this.graph.view.translate.x;
    let dy = this.graph.container.scrollTop / this.graph.view.scale - this.graph.view.translate.y;

    const layout = this.graph.getPageLayout();
    const page = this.graph.getPageSize();
    dx = Math.max(dx, layout.x * page.width);
    dy = Math.max(dy, layout.y * page.height);

    return new mxPoint(this.graph.snap(dx + gs), this.graph.snap(dy + gs));
  }

  public GetFreeInsertPoint() {
    const view = this.graph.view;
    const bds = this.graph.getGraphBounds();
    const pt = this.GetInsertPoint();

    // Places at same x-coord and 2 grid sizes below existing graph
    const x = this.graph.snap(Math.round(Math.max(pt.x, bds.x / view.scale - view.translate.x +
      ((bds.width === 0) ? this.graph.gridSize : 0))));
    const y = this.graph.snap(Math.round(Math.max(pt.y, (bds.y + bds.height) / view.scale - view.translate.y +
      ((bds.height === 0) ? 1 : 2) * this.graph.gridSize)));

    return new mxPoint(x, y);
  }

  public AddLine() {
    const shape = new Line();
    this.AddShape(shape.GetTop(), shape.Name, true, false);
  }

  public AddVerticalLine() {
    const shape = new Line();
    this.AddShape(shape.GetBottom(), shape.Name, true, false);
  }

  public AddTopEquiliateralTriangle() {
    const shape = new EquilateralTriangle();
    this.AddShape(shape.GetTop(), shape.Name);
  }

  public AddBottomEquiliateralTriangle() {
    const shape = new EquilateralTriangle();
    this.AddShape(shape.GetBottom(), shape.Name);
  }

  public AddLeftEquiliateralTriangle() {
    const shape = new EquilateralTriangle();
    this.AddShape(shape.GetLeft(), shape.Name);
  }

  public AddRightEquiliateralTriangle() {
    const shape = new EquilateralTriangle();
    this.AddShape(shape.GetRight(), shape.Name);
  }

  public AddTopRightTriangle() {
    const shape = new RightTriangle();
    this.AddShape(shape.GetTop(), shape.Name);
  }

  public AddBottomRightTriangle() {
    const shape = new RightTriangle();
    this.AddShape(shape.GetBottom(), shape.Name);
  }

  public AddLeftRightTriangle() {
    const shape = new RightTriangle();
    this.AddShape(shape.GetLeft(), shape.Name);
  }

  public AddRightRightTriangle() {
    const shape = new RightTriangle();
    this.AddShape(shape.GetRight(), shape.Name);
  }

  public AddRectangle() {
    const shape = new Rectangle();
    this.AddShape(shape.GetTop(), shape.Name);
  }

  public AddRightTrapezoid() {
    const shape = new Trapezoid();
    this.AddShape(shape.GetRight(), shape.Name);
  }

  public AddLeftTrapezoid() {
    const shape = new Trapezoid();
    this.AddShape(shape.GetLeft(), shape.Name);
  }

  public AddTopTrapezoid() {
    const shape = new Trapezoid();
    this.AddShape(shape.GetTop(), shape.Name);
  }

  public AddBottomTrapezoid() {
    const shape = new Trapezoid();
    this.AddShape(shape.GetBottom(), shape.Name);
  }

  private CreateGroup(cells, shapeType) {
    const group = this.graph.groupCells(null, 0, cells);
    const doc = mxUtils.createXmlDocument();
    const node = doc.createElement('CellData');
    node.setAttribute('label', '');
    node.setAttribute('negative', '0');
    node.setAttribute('shapeType', shapeType);
    group.value = node;
    return group;
  }

  private RemoveGroup(cells) {
    this.graph.ungroupCells(cells);
  }

  public DeleteSelectedShapes() {
    this.graph.removeCells(this.graph.getSelectionCells(), true);
    this.graph.escape();
    this.RefreshAll();
  }

  private GetShapeType(cell) {
    let shapeType = '';
    if (cell.style.includes('group')) {
      shapeType = this.GetCellAttribute(cell, 'shapeType');
    }
    return shapeType;
  }

  private AddShape(sideCoordinates: SideCoordinate[], name: string, findFreeinsertPoint: boolean = true, groupSides: boolean = true) {
    const parent = this.graph.getDefaultParent();
    // Adds cells to the model in a single step
    this.graph.getModel().beginUpdate();
    try {
      const cells = [];
      const freeInsertPoint = this.GetFreeInsertPoint();

      if (!findFreeinsertPoint) {
        freeInsertPoint.x = 0;
        freeInsertPoint.y = 0;
      }

      sideCoordinates.forEach((sideCoordinate) => {
        cells.push(this.CreateCell(sideCoordinate.startX + freeInsertPoint.x,
          sideCoordinate.startY + freeInsertPoint.y,
          sideCoordinate.endX + freeInsertPoint.x,
          sideCoordinate.endY + freeInsertPoint.y,
          sideCoordinate.side, groupSides));
      });
      this.graph.addCells(cells, parent);
      if (groupSides) {
        this.CreateGroup(cells, name);
      }
    }
    finally {
      // Updates the display
      this.graph.getModel().endUpdate();
      this.UpdateAllSizeLabels();
    }
  }

  private GetPointsFromPath(path: any, sideDirection, sideLengthDictionary) {
    const totalLength = Math.ceil(path.getTotalLength());
    const points = [];
    const abspoint0 = path.getPointAtLength(0);
    const abspoint1 = path.getPointAtLength(totalLength);

    abspoint0.x = abspoint0.x / this.graph.view.scale;
    abspoint0.y = abspoint0.y / this.graph.view.scale;
    abspoint1.x = abspoint1.x / this.graph.view.scale;
    abspoint1.y = abspoint1.y / this.graph.view.scale;
    points.push(abspoint0);
    points.push(abspoint1);
    return points;
  }

  private GetXPointsFromPath(points: any[], xPoints: number[]): number[] {
    if (xPoints === null) {
      xPoints = new Array<number>();
    }
    points.forEach((point) => {
      xPoints.push(point.x);
    });

    return xPoints;
  }

  private GetYPointsFromPath(points: any[], yPoints: number[]): number[] {

    if (yPoints === null) {
      yPoints = new Array<number>();
    }
    points.forEach((point) => {
      yPoints.push(point.y);
    });

    return yPoints;
  }

  private PopulateCellPoints(cell, xPoints: number[], yPoints: number[], sideLengthDictionary) {
    if (cell.children != null && cell.children.length > 0) {
      sideLengthDictionary = {};
      cell.children.forEach((child) => {
        sideLengthDictionary[this.GetSideDirection(child)] = this.GetSideThickness(child);
      });
      cell.children.forEach((child) => {
        this.PopulateCellPoints(child, xPoints, yPoints, sideLengthDictionary);
      });
    } else {
      const shape = this.graph.view.getState(cell).shape;
      if (shape.node.children.length > 0) {
        const path = shape.node.children[0];
        const sideDirection = this.GetSideDirection(cell);
        if (typeof path !== 'undefined' && path !== null) {
          const points = this.GetPointsFromPath(path, sideDirection, sideLengthDictionary);
          xPoints = this.GetXPointsFromPath(points, xPoints);
          yPoints = this.GetYPointsFromPath(points, yPoints);
        }
      }
    }
  }

  public IsGraphReady() {
    return typeof this.graph !== 'undefined' && this.graph !== null;
  }

  public GetArea() {
    if (this.IsGraphReady()) {
      const allCells = this.graph.getChildCells();
      let totalArea = 0;
      allCells.forEach(
        (cell) => {
          if (!this.IsTextCell(cell) && this.IsShape(cell)) {
            const xPoints = new Array<number>();
            const yPoints = new Array<number>();
            this.PopulateCellPoints(cell, xPoints, yPoints, null);
            let area = this.GetPolygonArea(xPoints, yPoints);
            const negative = this.GetCellAttribute(cell, 'negative') === '1';
            if (negative) {
              area = area * -1;
            }
            totalArea += area;
          }
        }
      );

      const areaTextCell = this.GetAreaTextCell();
      areaTextCell.value = totalArea.toFixed(2) + ' ' + this.measurementUnit.squaredName;
      return totalArea;
    } else {
      return 0;
    }
  }

  public AddDescriptionCell(description: string) {
    const textCell = this.GetDescriptionTextCell();
    textCell.value = description;
    this.RefreshAll();
  }

  public AddDiagramTextCell(diagramDescription: string) {
    const textCell = this.GetDiagramTextCell();
    textCell.value = diagramDescription;
    this.RefreshAll();
  }

  private GetPolygonArea(xPoints: number[], yPoints: number[]) {
    const numPoints = xPoints.length;
    let area = 0;  // Accumulates area in the loop
    let j = numPoints - 1;  // The last vertex is the 'previous' one to the first

    for (let i = 0; i < numPoints; i++) {
      area = area + ((xPoints[j] * this.scale + xPoints[i] * this.scale) * (yPoints[j] * this.scale - yPoints[i] * this.scale));
      j = i;  // j is previous vertex to i
    }

    area = area / 2 * -1;

    if (area < 0) {
      area = area * -1;
    }
    return area;
  }

  private RefreshLabels() {
    this.UpdateAllSizeLabels();
  }

  public RefreshAll(asynchronous = false) {
    if (asynchronous) {
      setTimeout(() => {
        this.RefreshAll(false);
      }, 100);
    } else {
      this.graph.refresh();
      this.GetArea();
      this.UpdateAllSizeLabels();
      this.graph.refresh();
    }
  }

  public IsSide(cell) {
    if (cell === null) {
      return false;
    }
    return cell.style.includes('shapeSide') || cell.style.includes('shapeLine');
  }

  public IsShapeLine(cell) {
    if (cell === null) {
      return false;
    }
    return cell.style.includes('shapeLine');
  }

  public IsShape(cell) {
    if (cell === null) { return false; }
    return cell.style.includes('group');
  }

  public IsCustomShape(cell) {
    if (cell === null) { return false; }
    return this.GetShapeType(cell) === 'customShape';
  }

  public IsPlainTextCell(cell) {
    if (cell === null) { return false; }
    return cell.style.includes('text') && cell.id !== 'text-cell' && cell.id !== 'description-cell' && cell.id !== 'diagram-text-cell';
  }

  public IsTextCell(cell) {
    if (cell === null) { return false; }
    return cell.id === 'text-cell' || cell.id === 'description-cell' || cell.id === 'diagram-text-cell';
  }

  public IsSideHidden(cell): boolean {
    if (cell === null) { return false; }
    return cell.style.includes('strokeColor=#FFFFFF');
  }

  public GetSideThickness(cell) {
    if (cell === null) { return 0; }
    const thickness = this.GetCellAttribute(cell, 'sideThickness');
    if (thickness !== null) {
      return parseFloat(thickness);
    } else {
      return 0;
    }
  }

  public SetSideThickness(cell, thickness: number) {
    this.SetCellAttribute(cell, 'sideThickness', thickness.toString());
    this.RefreshAll();
  }

  private Travel(length, x1, y1, x2, y2) {
    length = length / this.scale;
    const yVector = y2 - y1;
    const xVector = x2 - x1;
    const vectorLength = Math.sqrt(xVector * xVector + yVector * yVector);
    const normalizedX = xVector / vectorLength;
    const normalizedY = yVector / vectorLength;
    const newXPoint = x1 + (normalizedX * length);
    const newYPoint = y1 + (normalizedY * length);
    return { x: newXPoint, y: newYPoint };
  }

  public SetSideLength(cell, newLength: number) {
    if (this.IsGraphReady() && newLength > 0) {
      if (this.IsShapeLine(cell)) {
        const newPoint = this.Travel(newLength, cell.geometry.sourcePoint.x,
          cell.geometry.sourcePoint.y,
          cell.geometry.targetPoint.x,
          cell.geometry.targetPoint.y);
        cell.geometry.targetPoint.x = newPoint.x;
        cell.geometry.targetPoint.y = newPoint.y;
        this.RefreshAll(true);
      }
    }
  }


  public GetSelectedOneSide() {
    let sideCell = null;
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells.length === 1 && !this.IsTextCell(selectedCells[0])) {
        if (this.IsSide(selectedCells[0])) {
          sideCell = selectedCells[0];
        }
      }
    }
    return sideCell;
  }

  public IsOneSideSelected() {
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells.length === 1 && !this.IsTextCell(selectedCells[0])) {
        return this.IsSide(selectedCells[0]);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public GetShapeHeight(cell): number {
    return cell.geometry.height * this.scale;
  }

  public GetShapeWidth(cell): number {
    return cell.geometry.width * this.scale;
  }

  public ChangeSelectedShapeDimensions(height: number, width: number) {
    if (this.IsGraphReady()) {
      const cell = this.GetSelectedOneShape();
      if (cell !== null) {
        const bounds = new mxRectangle(cell.geometry.x, cell.geometry.y, width / this.scale, height / this.scale);
        this.graph.resizeCell(cell, bounds, true);
      }
      this.RefreshAll(true);
    }
  }

  public IsOneLineSelected(): boolean {
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        !this.IsTextCell(selectedCells[0]) &&
        this.IsShapeLine(selectedCells[0])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public AreAllSelectedCellsLines(): boolean {
    let allSelectedCellsAreLines = false;
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length > 2) {
        allSelectedCellsAreLines = true;
        selectedCells.forEach((selectedCell) => {
          if (!this.IsShapeLine(selectedCell)) {
            allSelectedCellsAreLines = false;
          }
        });
        return allSelectedCellsAreLines;
      } else {
        return allSelectedCellsAreLines;
      }
    } else {
      return allSelectedCellsAreLines;
    }
  }

  public IsOnePlainTextSelected(): boolean {
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        this.IsPlainTextCell(selectedCells[0])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public GetSelectedOneText() {
    let selectedShape = null;
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        this.IsTextCell(selectedCells[0])) {
        selectedShape = selectedCells[0];
      }
    }
    return selectedShape;
  }

  public IsOneShapeSelected(): boolean {
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        !this.IsTextCell(selectedCells[0]) &&
        this.IsShape(selectedCells[0])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public IsOneCustomShapeSelected(): boolean {
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        !this.IsTextCell(selectedCells[0]) &&
        this.IsCustomShape(selectedCells[0])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public GetSelectedOneShape() {
    let selectedShape = null;
    if (this.IsGraphReady()) {
      const selectedCells = this.graph.getSelectionCells();
      if (selectedCells !== null && selectedCells.length === 1 &&
        !this.IsTextCell(selectedCells[0]) &&
        this.IsShape(selectedCells[0])) {
        selectedShape = selectedCells[0];
      }
    }
    return selectedShape;
  }

  public IsShapeNegativeSpace(cell) {
    if (this.IsShape(cell)) {
      return this.GetCellAttribute(cell, 'negative') === '1';
    } else {
      return false;
    }
  }

  public HasChanges(): boolean {
    if (this.saveRequired) {
      return true;
    }

    let hasChanges = false;
    this.Diagrams.forEach((diagram) => {
      const xml = this.GetXmlCache(diagram.id);
      if (Utility.IsEmptyOrNull(diagram.id) ||
        diagram.value !== xml) {
        hasChanges = true;
      }
    });
    return hasChanges;
  }

  public SetShapeNegativeSpace(cell, isNegativeSpace: boolean) {
    // let lineColor = '#000000';
    // if (isNegativeSpace) {
    //   lineColor = '#ff0000';
    // }
    // cell.children.forEach((child) => {
    //   child.style = mxUtils.setStyle(child.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, lineColor);
    // });
    this.SetCellAttribute(cell, 'negative', isNegativeSpace ? '1' : '0');
    this.RefreshAll();
  }

  private InitializeUndoManager(graph, undoManager) {
    const parent = this;
    const undoListener = function (sender, evt) {
      undoManager.undoableEditHappened(evt.getProperty('edit'));
      parent.RefreshAll();
    };

    graph.getModel().addListener(mxEvent.UNDO, undoListener);
    graph.getView().addListener(mxEvent.UNDO, undoListener);
    // Keeps the selection in sync with the history
    const undoHandler = function (sender, evt) {
      const cand = graph.getSelectionCellsForChanges(evt.getProperty('edit').changes);
      const model = graph.getModel();
      const cells = [];

      for (let i = 0; i < cand.length; i++) {
        if ((model.isVertex(cand[i]) || model.isEdge(cand[i])) && graph.view.getState(cand[i]) != null) {
          cells.push(cand[i]);
        }
      }

      graph.setSelectionCells(cells);
      parent.RefreshAll();
    };
    undoManager.addListener(mxEvent.UNDO, undoHandler);
    undoManager.addListener(mxEvent.REDO, undoHandler);
  }

  public ClearUndo() {
    this.undoManager.clear();
  }

  public Redo() {
    try {
      const graph = this.graph;

      if (graph.isEditing()) {
        document.execCommand('redo', false, null);
      } else {
        if (this.undoManager.canRedo()) {
          this.undoManager.redo();
        }
      }
    } catch (e) {
      // ignore all errors
    }
  }

  public CanUndo(): boolean {
    if (typeof this.undoManager === 'undefined') {
      return false;
    }
    return this.undoManager.canUndo();
  }

  public CanRedo(): boolean {
    if (typeof this.undoManager === 'undefined') {
      return false;
    }
    return this.undoManager.canRedo();
  }

  public Undo() {
    try {
      if (this.graph.isEditing() && this.undoManager.canUndo()) {
        // Stops editing and executes undo on graph if native undo
        // does not affect current editing value
        const value = this.graph.cellEditor.textarea.innerHTML;
        document.execCommand('undo', false, null);

        if (value === this.graph.cellEditor.textarea.innerHTML) {
          this.graph.stopEditing(true);
          this.undoManager.undo();
        }
      } else {
        if (this.undoManager.canUndo()) {
          this.undoManager.undo();
        }
      }
    } catch (e) {
      // ignore all errors
    }
  }

  HideSide(cell) {
    if (this.IsSide(cell) && this.IsGraphReady()) {
      this.SetCellAttribute(cell, 'hidden', '1');
      cell.style = mxUtils.setStyle(cell.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, '#FFFFFF');
      this.graph.refresh();
      const xml = this.GetModelXml();
      this.SetXmlCache(this.currentDiagramId, xml);
    }
  }

  HideSideLabel(cell) {
    if (this.IsSide(cell) && this.IsGraphReady()) {
      this.SetCellAttribute(cell, 'label-hidden', '1');
      this.RefreshLabels();
    }
  }

  ShowSideLabel(cell) {
    if (this.IsSide(cell) && this.IsGraphReady()) {
      this.SetCellAttribute(cell, 'label-hidden', '0');
      this.RefreshLabels();
    }
  }

  IsSideLabelHidden(cell): boolean {
    let hidden = false;
    if (this.IsSide(cell) && this.IsGraphReady()) {
      hidden = this.GetCellAttribute(cell, 'label-hidden') === '1';
    }
    return hidden;
  }

  ShowSide(cell) {
    if (this.IsSide(cell) && this.IsGraphReady()) {
      this.SetCellAttribute(cell, 'hidden', '0');
      cell.style = mxUtils.setStyle(cell.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, '#000000');
      this.graph.refresh();
      const xml = this.GetModelXml();
      this.SetXmlCache(this.currentDiagramId, xml);
    }
  }

  public GetModelXml(): string {
    if (this.IsGraphReady()) {
      const encoder = new mxCodec();
      const result = encoder.encode(this.graph.getModel());
      return mxUtils.getXml(result);
    }
    return '';
  }

  public SetModelXml(xml: string) {
    const doc = mxUtils.parseXml(xml);
    const codec = new mxCodec(doc);
    codec.decode(doc.documentElement, this.graph.getModel());
    this.RefreshAll();
  }

  public SetGridSize(size: number) {
    if (this.IsGraphReady()) {
      const graph = this.GetGraph();
      graph.gridSize = size;
      graph.refresh();
    }
  }

  private SaveDiagrams(diagramsToSave: Diagram[], complete: BehaviorSubject<boolean> = null) {
    if (Utility.IsNull(complete)) {
      complete = new BehaviorSubject<boolean>(false);
    }

    if (diagramsToSave.length === 0) {
      complete.next(true);
    } else {
      const diagram = diagramsToSave.pop();
      const xml = this.GetXmlCache(diagram.id);
      const oldValue = diagram.value; // Save in case there is an error saving...
      // if xml is null then no changes were made to the model for
      // this diagram or the diagram was never loaded... nothing to save
      if (xml == null) {
        this.SaveDiagrams(diagramsToSave, complete); // move on to saving the remaining diagrams
      } else {
        diagram.value = xml;
        this.SaveDiagram(diagram).subscribe(
          () => this.SaveDiagrams(diagramsToSave, complete),
          (error) => { this.errorTranslationService.ErrorHandler(error); diagram.value = oldValue; });
      }
    }
    return complete;
  }

  public SaveDiagram(diagram: Diagram) {
    if (Utility.IsEmptyOrNull(diagram.id)) {
      return this.diagramService.createDiagram(diagram)
        .pipe(
          map((savedDiagram) => {
            this.SetXmlCache(savedDiagram.id, savedDiagram.value);
            this.SetDiagramCache(savedDiagram.id, savedDiagram);
            return savedDiagram;
          })
        );
    } else {
      return this.diagramService.updateDiagram(diagram)
        .pipe(
          map((savedDiagram) => {
            this.SetXmlCache(savedDiagram.id, savedDiagram.value);
            this.SetDiagramCache(savedDiagram.id, savedDiagram);
            return savedDiagram;
          })
        );
    }
  }

  public DeleteCurrentDiagram() {
    const currentDiagram = this.CurrentDiagram;
    return this.diagramService.deleteDiagramPage(currentDiagram.id, currentDiagram.rangeId).pipe(map(() => {
      this.DeleteCache(currentDiagram.id);
    }));
  }

  public IsCurrentDiagramParent() {
    if (Utility.IsEmptyOrNull(this.parentDiagram) || Utility.IsEmptyOrNull(this.CurrentDiagram)) {
      return false;
    }
    return this.parentDiagram.id === this.CurrentDiagram.id;
  }

  public Save() {
    this.saveRequired = false;
    return this.SaveDiagrams(this.Diagrams);
  }

  public SetDirty() {
    this.saveRequired = true;
  }

  public Delete(id: string): Observable<boolean> {
    return this.diagramService.deleteDiagram(id);
  }

  public get ParentDiagram() {
    return this.parentDiagram;
  }

  public GetDiagram(id: string) {
    return this.diagramService.getDiagram(id);
  }

  public GetDiagramChildren(parentId: string) {
    return this.diagramService.getDiagramChildrens(parentId);
  }

  public ShowPrintPreview(diagramDescription: string) {
    let autoOrigin = true;
    let printScale = 1;

    if (isNaN(printScale)) {
      printScale = 1;
    }

    // Workaround to match available paper size in actual print output
    printScale *= 0.75;

    let pf = this.graph.pageFormat || mxConstants.PAGE_FORMAT_LETTER_PORTRAIT;
    let scale = 1 / this.graph.pageScale;

    // if (autoOrigin) {
    //   let pageCount = 1;

    //   if (!isNaN(pageCount)) {
    //     scale = mxUtils.getScaleForPageCount(pageCount, this.graph, pf);
    //   }
    // }

    // Negative coordinates are cropped or shifted if page visible
    const gb = this.graph.getGraphBounds();
    const border = 0;
    let x0 = 0;
    let y0 = 0;

    // Applies print scale
    pf = mxRectangle.fromRectangle(pf);
    pf.width = Math.ceil(pf.width * printScale);
    pf.height = Math.ceil(pf.height * printScale);
    scale *= printScale;

    // Starts at first visible page
    if (!autoOrigin && this.graph.pageVisible) {
      const layout = this.graph.getPageLayout();
      x0 -= layout.x * pf.width;
      y0 -= layout.y * pf.height;
    } else {
      autoOrigin = true;
      const preview = new mxPrintPreview(this.graph, scale, pf, border, x0, y0);
      preview.title = diagramDescription; // mxResources.get('preview');
      preview.printBackgroundImage = true;
      preview.autoOrigin = autoOrigin;
      let bg = this.graph.background;

      if (bg == null || bg === '' || bg === mxConstants.NONE) {
        bg = '#ffffff';
      }

      preview.backgroundColor = bg;
      const writeHead = preview.writeHead;
      // Adds a border in the preview
      preview.writeHead = function (doc) {
        writeHead.apply(this, arguments);

        doc.writeln('<style type="text/css">');
        doc.writeln('@media screen {');
        doc.writeln('  body > div { padding:30px;box-sizing:content-box; }');
        doc.writeln('}');
        doc.writeln('</style>');
      };
      preview.open();
    }
  }

  public GetDiagrams(pageSize: number, page: number) {
    return this.diagramService.getDiagramPageSizePages(pageSize, page);
  }

  public GetDiagramCount() {
    return this.diagramService.getDiagramCount();
  }

  public GetMeasurements(): Observable<MeasurementUnit[]> {
    return this.measurementUnitService.getMeasurementUnits();
  }

  public get CurrentDiagram() {
    let diagram = this.GetDiagramCache(this.currentDiagramId);
    if (Utility.IsNull(diagram)) {
      // Temp placeholder until real diagram is loaded
      diagram = new Diagram();
      diagram.graphScale = 0.02;
      diagram.gridSize = 10;
    }
    return diagram;
  }

  public get Measurements() {
    return this.measurementUnits;
  }

  public get Measurement(): MeasurementUnit {
    return this.measurementUnit;
  }

  public set Measurement(measurementUnit: MeasurementUnit) {
    this.measurementUnit = measurementUnit;
  }

  public get Diagrams() {
    const diagrams = new Array<Diagram>();
    Object.keys(this.diagramMap).forEach((key) => {
      diagrams.push(this.diagramMap[key]);
    });
    return diagrams;
  }

}

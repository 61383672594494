/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./alert.component";
var styles_AlertComponent = ["[_nghost-%COMP%]     .alert-custom {\n      padding-bottom:0px;\n      padding-top:0px;\n      margin-top:0px;\n      margin-bottom:10px;\n    }\n\n    [_nghost-%COMP%]     .alert-dismissible .close {\n      padding-bottom:0px;\n      padding-top:0px;\n    }"];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "custom"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NgbAlert_0, i1.RenderType_NgbAlert)), i0.ɵdid(1, 638976, null, 0, i2.NgbAlert, [i2.NgbAlertConfig, i0.Renderer2, i0.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, { close: "close" }), (_l()(), i0.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dismissable; var currVal_2 = "custom"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.message; _ck(_v, 2, 0, currVal_3); }); }
function View_AlertComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ngb-alert", [["class", "alert"], ["role", "alert"], ["type", "custom"]], [[2, "alert-dismissible", null]], [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_NgbAlert_0, i1.RenderType_NgbAlert)), i0.ɵdid(1, 638976, null, 0, i2.NgbAlert, [i2.NgbAlertConfig, i0.Renderer2, i0.ElementRef], { dismissible: [0, "dismissible"], type: [1, "type"] }, { close: "close" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dismissable; var currVal_2 = "custom"; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).dismissible; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.message; _ck(_v, 3, 0, currVal_3); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.title && _co.display); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.title && _co.display); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i4.AlertComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i0.ɵccf("alert", i4.AlertComponent, View_AlertComponent_Host_0, { message: "message", dismissable: "dismissable", title: "title" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };

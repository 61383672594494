import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { PasswordInformation } from '@xactly-app/common/src/lib/models/PasswordInformation';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  public passwordConfirm: string;
  public passwordInformation: PasswordInformation;
  public resetCode = '';

  constructor(public apiService: UserService,
    public router: Router,
    public errorTranslationService: ErrorTranslationService,
    public validationService: ValidationService,
    public dialogService: DialogService,
    public activatedRoute: ActivatedRoute,
    public securityService: AuthenticationService) {
    super();
    this.passwordInformation = new PasswordInformation();
  }

  ngOnInit() {
    const parameters = new URLSearchParams(this.router.url.split('?')[1]);
    this.resetCode = parameters.get('resetCode');
  }

  hasResetCode(): boolean {
    return (this.resetCode != null && this.resetCode.length > 0);
  }

  changePassword() {
    this.busy();
    this.passwordInformation.resetCode = this.resetCode;
    this.apiService.updateUserChangePassword(this.passwordInformation).pipe(finalize(() => { this.ready(); })).subscribe(result => {
      if (result) {
        this.dialogService.showOkDialog('Password was changed successfully', 'Password Change').subscribe(e => {
          this.securityService.getUser().then((user) => {
            if (user) {
              this.securityService.logout();
            } else {
              this.router.navigate(['/home']);
            }
          });
        });
      } else {
        if (this.hasResetCode()) {
          this.dialogService.showOkDialog('There was a problem changing your password, please try again.  You may need to request a new password reset.', 'Password Change Failed');
        } else {
          this.dialogService.showOkDialog('There was a problem changing your password, please try again.', 'Password Change Failed');
        }
      }
    },
      this.errorTranslationService.ErrorHandler);
  }
}

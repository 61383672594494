import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styles: [`
    :host >>> .alert-custom {
      padding-bottom:0px;
      padding-top:0px;
      margin-top:0px;
      margin-bottom:10px;
    }

    :host >>> .alert-dismissible .close {
      padding-bottom:0px;
      padding-top:0px;
    }
  `]
})
export class AlertComponent implements OnInit {
  @Input() message: string;
  @Input() dismissable: boolean;
  @Input() title = true;
  display = true;
  constructor() { }

  ngOnInit() {
  }

  closeAlert() {
    this.display = false;
  }
}

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { PasswordInformation } from '@xactly-app/common/src/lib/models/PasswordInformation';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@xactly-app/common/src/lib/services/authentication.service';
var ChangePasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ChangePasswordComponent, _super);
    function ChangePasswordComponent(apiService, router, errorTranslationService, validationService, dialogService, activatedRoute, securityService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.activatedRoute = activatedRoute;
        _this.securityService = securityService;
        _this.resetCode = '';
        _this.passwordInformation = new PasswordInformation();
        return _this;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        var parameters = new URLSearchParams(this.router.url.split('?')[1]);
        this.resetCode = parameters.get('resetCode');
    };
    ChangePasswordComponent.prototype.hasResetCode = function () {
        return (this.resetCode != null && this.resetCode.length > 0);
    };
    ChangePasswordComponent.prototype.changePassword = function () {
        var _this = this;
        this.busy();
        this.passwordInformation.resetCode = this.resetCode;
        this.apiService.updateUserChangePassword(this.passwordInformation).pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
            if (result) {
                _this.dialogService.showOkDialog('Password was changed successfully', 'Password Change').subscribe(function (e) {
                    _this.securityService.getUser().then(function (user) {
                        if (user) {
                            _this.securityService.logout();
                        }
                        else {
                            _this.router.navigate(['/home']);
                        }
                    });
                });
            }
            else {
                if (_this.hasResetCode()) {
                    _this.dialogService.showOkDialog('There was a problem changing your password, please try again.  You may need to request a new password reset.', 'Password Change Failed');
                }
                else {
                    _this.dialogService.showOkDialog('There was a problem changing your password, please try again.', 'Password Change Failed');
                }
            }
        }, this.errorTranslationService.ErrorHandler);
    };
    return ChangePasswordComponent;
}(BaseComponent));
export { ChangePasswordComponent };

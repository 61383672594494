﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';
import { User } from './User';

export class Diagram extends DynamoEntity {
        
    public gridSize : number = 0;    
    public graphScale : number = 0;    
    public pageName : string = null;    
    public name : string = null;    
    public value : string = null;    
    public description : string = null;    
    public measurementUnitId : string = null;    
    public userId : string = null;    
    public user : User = null;
}

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupportRequest } from '../models/SupportRequest';
import { UserSupportRequest } from '../models/UserSupportRequest';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class UserSupportRequestService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getSupportCount(): Observable<UserSupportRequest> {
        return this.httpService.get<UserSupportRequest>(`${this.configuration.ApiServer}api/Support/Count`);
    }

    public getSupportPageSizePages(pageSize: number, page: number): Observable<UserSupportRequest[]> {
        return this.httpService.get<UserSupportRequest[]>(
            `${this.configuration.ApiServer}api/Support/PageSize/${pageSize}/Page/${page}`
        );
    }

    public getSupport(id: string): Observable<UserSupportRequest> {
        return this.httpService.get<UserSupportRequest>(
            `${this.configuration.ApiServer}api/Support/${encodeURIComponent(id)}`
        );
    }

    public updateSupport(supportRequest: SupportRequest): Observable<UserSupportRequest> {
        return this.httpService.put<UserSupportRequest>(
            `${this.configuration.ApiServer}api/Support`,
            JSON.stringify(supportRequest)
        );
    }

    public createSupport(supportRequest: SupportRequest): Observable<UserSupportRequest> {
        return this.httpService.post<UserSupportRequest>(
            `${this.configuration.ApiServer}api/Support`,
            JSON.stringify(supportRequest)
        );
    }

    public deleteSupport(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(`${this.configuration.ApiServer}api/Support/${encodeURIComponent(id)}`);
    }
}

<instruction-card [message]="'This page displays all subscription types'" [title]="'Subscription Types'"></instruction-card>
<mat-card>
  <mat-card-content>    
    <loading-indicator *ngIf="loading"></loading-indicator>
    <button mat-button color="primary" (click)="Add()" title='Add Subscription Type'>
        Add Subscription Type
        <mat-icon>note_add</mat-icon>
    </button>
    <div *ngIf="!loading" class="grid">
      <wj-flex-grid [itemsSource]="subscriptionTypes" [headersVisibility]="1">
        <wj-flex-grid-column [allowSorting]="false" [header]="'Actions'" [isReadOnly]="true" [width]="90">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button mat-icon-button (click)="Edit(cell.item)" title='Edit Subscription Type'>
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="Delete(cell.item)" title='Remove Subscription Type'>
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Description'" [minWidth]="200" [binding]="'description'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Price'" [minWidth]="20" [binding]="'price'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Maximum Users'" [minWidth]="20" [binding]="'maximumUsers'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Duration (Days)'" [minWidth]="20" [binding]="'durationDays'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Is System'" [minWidth]="10" [binding]="'isSystem'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Is Agency Level'" [minWidth]="10" [binding]="'isAgencyLevel'" [isReadOnly]="true"></wj-flex-grid-column>
        
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
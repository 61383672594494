﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';
import { Person } from './Person';

export class User extends DynamoEntity {
        
    public username : string = null;    
    public person : Person = null;    
    public emailVerified : boolean = false;    
    public isActive : boolean = false;    
    public hashedPassword : string = null;    
    public roleId : string = null;    
    public providerName : string = null;    
    public providerSubjectId : string = null;    
    public subjectId : string = null;    
    public tenantId : string = null;
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  private snackBar: MatSnackBar;
  public helpUrl: string;
  public message: string;
  public showHelp = false;
  public buttonMessage = 'Show';
  public timeoutReference;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.snackBar = data.snackBarReference;
    this.helpUrl = data.helpUrl;
    this.message = data.message;
    this.buttonMessage = data.buttonMessage;
  }

  public Close() {
    this.snackBar.dismiss();
  }

  public Show() {
    clearTimeout(this.timeoutReference);
    this.showHelp = true;
  }

  ngOnInit() {

    this.timeoutReference = setTimeout(() => { this.Close(); }, 3000);
  }

}

<instruction-card [message]="'This page displays all agents'" [title]="'Agents'"></instruction-card>
<mat-card>
  <mat-card-content>    
    <loading-indicator *ngIf="loading"></loading-indicator>
    <button mat-button color="primary" (click)="Add()" title='Add Agent'>
        Add Agent
        <mat-icon>note_add</mat-icon>
    </button>
    <div *ngIf="!loading" class="grid">
      <wj-flex-grid [itemsSource]="agentUsers" [headersVisibility]="1">
        <wj-flex-grid-column [allowSorting]="false" [header]="'Actions'" [isReadOnly]="true" [width]="90">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell">
            <button mat-icon-button (click)="Edit(cell.item)" title='Edit Agent'>
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="Delete(cell.item)" title='Remove Agent'>
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'User Name'" [minWidth]="200" [binding]="'user.username'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'First Name'" [minWidth]="200" [binding]="'user.person.firstName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Last Name'" [minWidth]="200" [binding]="'user.person.lastName'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Agency'" [minWidth]="200" [binding]="'agency.name'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Active'" [minWidth]="50" [binding]="'user.isActive'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Last Login'" [minWidth]="50" [binding]="'user.modifiedDate'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var Agency = /** @class */ (function (_super) {
    tslib_1.__extends(Agency, _super);
    function Agency() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = null;
        _this.agentCount = 0;
        _this.isSystem = false;
        return _this;
    }
    return Agency;
}(DynamoEntity));
export { Agency };

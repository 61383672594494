import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NxModule } from '@nrwl/nx';
import { XactlyCommonModule } from '@xactly-app/common';
import { ConfirmDialogComponent } from '@xactly-app/common';
import { OkDialogComponent } from '@xactly-app/common';
import { AgencyService } from '@xactly-app/common';
import { AgentService } from '@xactly-app/common';
import { AgentUserService } from '@xactly-app/common';
import { AuthGuard } from '@xactly-app/common';
import { AuthenticationInterceptor } from '@xactly-app/common';
import { CacheService } from '@xactly-app/common';
import { CellStateService } from '@xactly-app/common';
import { DateService } from '@xactly-app/common';
import { DiagramService } from '@xactly-app/common';
import { DialogService } from '@xactly-app/common';
import { ErrorTranslationService } from '@xactly-app/common';
import { GraphService } from '@xactly-app/common';
import { HttpService } from '@xactly-app/common';
import { MeasurementUnitService } from '@xactly-app/common';
import { PasswordResetService } from '@xactly-app/common';
import { PendingChangesGuard } from '@xactly-app/common';
import { PermissionService } from '@xactly-app/common';
import { RegistrationService } from '@xactly-app/common';
import { RoleService } from '@xactly-app/common';
import { SubscriptionService } from '@xactly-app/common';
import { SubscriptionTypeService } from '@xactly-app/common';
import { UserService } from '@xactly-app/common';
import { ValidationService } from '@xactly-app/common';
import { WindowService } from '@xactly-app/common';
import { SharedSettingsModule } from '@xactly-app/common';
import { Utility } from '@xactly-app/common';
import { MaterialModule } from '@xactly-app/material';
import { RegistrationComponent } from '@xactly-app/registration';
import { TextMaskModule } from 'angular2-text-mask';
import { WjGridModule } from 'wijmo/wijmo.angular2.grid';
import { WjGridDetailModule } from 'wijmo/wijmo.angular2.grid.detail';
import { WjGridFilterModule } from 'wijmo/wijmo.angular2.grid.filter';
import { environment } from '../environments/environment';
import { AboutComponent } from './about/about.component';
import { AddressComponent } from './address/address.component';
import { AgencyAddComponent } from './agency/agency-add/agency-add.component';
import { AgencyEditComponent } from './agency/agency-edit/agency-edit.component';
import { AgencyListComponent } from './agency/agency-list/agency-list.component';
import { AgentAddComponent } from './agent/agent-add/agent-add.component';
import { AgentEditComponent } from './agent/agent-edit/agent-edit.component';
import { AgentListComponent } from './agent/agent-list/agent-list.component';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompletedValidatorDirective } from './controls/completed-validator.directive';
import { CreditcardComponent } from './controls/creditcard/creditcard.component';
import { EmailValidatorDirective } from './controls/email-validator.directive';
import { FormModelDirective } from './directives/form-model.directive';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { FaqComponent } from './help/faq/faq.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { MeasurementAddComponent } from './measurement/measurement-add/measurement-add.component';
import { MeasurementEditComponent } from './measurement/measurement-edit/measurement-edit.component';
import { MeasurementListComponent } from './measurement/measurement-list/measurement-list.component';
import { ChangePasswordComponent } from './password/change-password/change-password.component';
import { ResetPasswordComponent } from './password/reset-password/reset-password.component';
import { PermissionComponent } from './permission/permission.component';
import { SafePipe } from './pipes/safe.pipe';
import { RoleComponent } from './role/role.component';
import { RolesViewComponent } from './role/roles-view/roles-view.component';
import { HelpService } from './services/help.service';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionTypeAddComponent } from './subscription-types/add/subscription-type-add/subscription-type-add.component';
import { SubscriptionTypeEditComponent } from './subscription-types/edit/subscription-type-edit/subscription-type-edit.component';
import { SubscriptionTypeListComponent } from './subscription-types/list/subscription-type-list/subscription-type-list.component';
import { SubscriptionAddComponent } from './subscriptions/add/subscription-add/subscription-add.component';
import { SubscriptionEditComponent } from './subscriptions/edit/subscription-edit/subscription-edit.component';
import { SubscriptionListComponent } from './subscriptions/list/subscription-list/subscription-list.component';
import { SupportEditComponent } from './support/support-edit/support-edit.component';
import { SupportListComponent } from './support/support-list/support-list.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserComponent } from './user/user.component';
import { UsersViewComponent } from './user/users-view/users-view.component';
import { AuthenticationService } from '@xactly-app/common';
import { SettingService } from '@xactly-app/common';
import { CallbackComponent } from './callback/callback.component';

declare var require;
require('hammerjs');

@NgModule({
    declarations: [
        CallbackComponent,
        AppComponent,
        ForbiddenComponent,
        HomeComponent,
        UnauthorizedComponent,
        RegistrationComponent,
        UsersViewComponent,
        UserComponent,
        RolesViewComponent,
        RoleComponent,
        PermissionComponent,
        AddressComponent,
        FormModelDirective,
        CompletedValidatorDirective,
        OkDialogComponent,
        EmailValidatorDirective,
        ConfirmDialogComponent,
        AboutComponent,
        SettingsComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        CreditcardComponent,
        AgentListComponent,
        AgentEditComponent,
        AgentAddComponent,
        AgencyListComponent,
        AgencyAddComponent,
        AgencyEditComponent,
        MeasurementListComponent,
        MeasurementEditComponent,
        MeasurementAddComponent,
        SubscriptionAddComponent,
        SubscriptionEditComponent,
        SubscriptionListComponent,
        SubscriptionTypeAddComponent,
        SubscriptionTypeListComponent,
        SubscriptionTypeEditComponent,
        HelpComponent,
        SafePipe,
        FaqComponent,
        SupportListComponent,
        SupportEditComponent
    ],
    imports: [
        MaterialModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TextMaskModule,
        HttpClientModule,
        JsonpModule,
        WjGridModule,
        WjGridDetailModule,
        WjGridFilterModule,
        XactlyCommonModule,
        NgbModule,
        NxModule.forRoot(),
        RouterModule.forRoot([
            ...routes,
            {
                path: 'registration',
                loadChildren: '@xactly-app/registration#RegistrationModule'
            },
            { path: 'diagrams', loadChildren: '@xactly-app/diagram#DiagramModule', canActivate: [AuthGuard] }
        ]),
        SharedSettingsModule
    ],
    entryComponents: [OkDialogComponent, ConfirmDialogComponent, HelpComponent, FaqComponent],
    providers: [
        AuthenticationService,
        UserService,
        RoleService,
        WindowService,
        AgencyService,
        AgentService,
        CellStateService,
        GraphService,
        DateService,
        ErrorTranslationService,
        ValidationService,
        DialogService,
        AuthGuard,
        HttpService,
        SettingService,
        DiagramService,
        MeasurementUnitService,
        AgentUserService,
        PermissionService,
        AgentUserService,
        PasswordResetService,
        RegistrationService,
        PendingChangesGuard,
        SubscriptionService,
        SubscriptionTypeService,
        CacheService,
        HelpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {

    }
}

<div flex>
    <mat-form-field> <input matInput name="streetAddress" maxlength="500" [registerForm]="form" [registerModel]="modelInput1" #modelInput1="ngModel"
            [(ngModel)]="address.streetAddress" placeholder="Address" type="text" [readonly]="isReadOnly"></mat-form-field>
</div>
<div flex>
    <mat-form-field> <input matInput name="city" maxlength="500" [registerForm]="form" [registerModel]="modelInput2" #modelInput2="ngModel"
            [(ngModel)]="address.city" placeholder="City" type="text" [readonly]="isReadOnly"></mat-form-field>
</div>
<div flex>
    <mat-form-field> <input matInput name="state" maxlength="500" [registerForm]="form" [registerModel]="modelInput3" #modelInput3="ngModel"
            [(ngModel)]="address.state" placeholder="Province" type="text" [readonly]="isReadOnly"></mat-form-field>
</div>
<div flex>
    <mat-form-field>
        <input matInput name="postalCode" #postalCode="ngModel" [textMask]="validationService.PostalCodeMask" [registerForm]="form" [registerModel]="modelInput5"
            #modelInput5="ngModel" [(ngModel)]="address.postalCode" placeholder="Postal Code" type="text" completed [readonly]="isReadOnly">        
        <mat-error *ngIf="postalCode.errors?.notCompleted">Postal code is not valid</mat-error>
    </mat-form-field>
</div>
<button mat-icon-button color="warn" (click)="Close()">
  <mat-icon>close</mat-icon>
</button>
<div class="scrollable">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          How can I create a custom shape?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>To create a custom shape: draw the shape using lines that intersect, select all lines and click on the
          link
          shape button when it appears:</span>
        <mat-icon>link</mat-icon>
        <video controls width="90%" height="90%">
          <source src="./assets/help/CreateCustomShape.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          How do I make a shape's area be empty space?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>To designate any area as negative (to have it's area subtracted from the overall area), select any shape
          and click or tap on the Negative Area toggle.</span>
        <video controls width="90%" height="90%">
          <source src="./assets/help/NegativeArea.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          How do I print or save a diagram as a pdf?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>To print or save the current page of a diagram as a pdf, click or tap on the Print button and select the
          type of print output you want from your browser.</span>
        <video controls width="90%" height="90%">
          <source src="./assets/help/Print.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          How do I hide an adjoining wall or side?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>To hide a wall you can select the side that you want to hide and click or tap on the Side toggle from the
          menu.</span>
        <video controls width="90%" height="90%">
          <source src="./assets/help/HideWall.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          I can't see all menu items when using a mobile device.
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>When menu space is limited, it needs to be scrolled, you can swipe up to see all items</span>
        <video controls width="90%" height="90%">
          <source src="./assets/help/ScrollMenu.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          How do I add a new page to my diagram?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <span>Click on the expand icon on the far left of the menu to expand the pages tabs.</span>
        <video controls width="90%" height="90%">
          <source src="./assets/help/ShowPages.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          What is "Selection Mode"?
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        Selection mode allows you to select multiple lines in order to create a custom shape.
      </div>
      <div>
        On a mobile device you cannot as easily select multiple shapes without being in this mode.
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>
          Submit help request
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <a [routerLink]="'support/0'" (click)="Close()">Submit request for support</a>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
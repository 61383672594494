﻿// Do not modify manually, created by TypeWriter

import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http-service';
import { Observable } from 'rxjs';
import { DateService } from '../services/date.service';
import { Diagram } from '../models/Diagram';
import { UserSupportRequest } from '../models/UserSupportRequest';
import { SupportRequest } from '../models/SupportRequest';
import { MeasurementUnit } from '../models/MeasurementUnit';
import { Setting } from '../models/Setting';
import { Agent } from '../models/Agent';
import { Agency } from '../models/Agency';
import { AgentUser } from '../models/AgentUser';
import { Subscription } from '../models/Subscription';
import { SubscriptionType } from '../models/SubscriptionType';
import { Registration } from '../models/Registration';
import { SubscriptionRequest } from '../models/SubscriptionRequest';
import { AppConfigService } from './app-config.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';

@Injectable({
    providedIn: 'root'
})
export class AgentService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getAgents(): Observable<Agent[]> {
        return this.httpService.get<Agent[]>(`${this.configuration.ApiServer}api/Agent`);
    }

    public getAgent(id: string): Observable<Agent> {
        return this.httpService.get<Agent>(`${this.configuration.ApiServer}api/Agent/${encodeURIComponent(id)}`);
    }

    public getAgentUser(userId: string): Observable<Agent> {
        return this.httpService.get<Agent>(
            `${this.configuration.ApiServer}api/Agent/User/${encodeURIComponent(userId)}`
        );
    }

    public updateAgent(agent: Agent): Observable<Agent> {
        return this.httpService.put<Agent>(`${this.configuration.ApiServer}api/Agent`, JSON.stringify(agent));
    }

    public createAgent(agent: Agent): Observable<Agent> {
        return this.httpService.post<Agent>(`${this.configuration.ApiServer}api/Agent`, JSON.stringify(agent));
    }

    public deleteAgent(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(`${this.configuration.ApiServer}api/Agent/${encodeURIComponent(id)}`);
    }
}

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeasurementUnit } from '../models/MeasurementUnit';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class MeasurementUnitService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getMeasurementUnits(): Observable<MeasurementUnit[]> {
        return this.httpService.get<MeasurementUnit[]>(`${this.configuration.ApiServer}api/MeasurementUnit`);
    }

    public getMeasurementUnit(id: string): Observable<MeasurementUnit> {
        return this.httpService.get<MeasurementUnit>(
            `${this.configuration.ApiServer}api/MeasurementUnit/${encodeURIComponent(id)}`
        );
    }

    public updateMeasurementUnit(measurementUnit: MeasurementUnit): Observable<MeasurementUnit> {
        return this.httpService.put<MeasurementUnit>(
            `${this.configuration.ApiServer}api/MeasurementUnit`,
            JSON.stringify(measurementUnit)
        );
    }

    public createMeasurementUnit(measurementUnit: MeasurementUnit): Observable<MeasurementUnit> {
        return this.httpService.post<MeasurementUnit>(
            `${this.configuration.ApiServer}api/MeasurementUnit`,
            JSON.stringify(measurementUnit)
        );
    }

    public deleteMeasurementUnit(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(
            `${this.configuration.ApiServer}api/MeasurementUnit/${encodeURIComponent(id)}`
        );
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { OkDialogComponent } from '../dialogs/ok-dialog/ok-dialog.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) { }
  public showOkDialog(message: string, title: string): Observable<any> {
    const dialog = this.dialog.open(OkDialogComponent);
    dialog.componentInstance.setup(message, title);
    return dialog.afterClosed();
  }

  public showConfirmDialog(message: string, title: string): Observable<boolean> {
    const dialog = this.dialog.open(ConfirmDialogComponent);
    dialog.componentInstance.setup(message, title);
    return dialog.afterClosed();
  }
}

﻿// Do not modify manually, created by TypeWriter

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Setting } from '../models/Setting';
import { DateService } from '../services/date.service';
import { ConfigurationModel } from '../shared-settings/configuration-model';
import { AppConfigService } from './app-config.service';
import { HttpService } from './http-service';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    configuration: ConfigurationModel;
    constructor(private httpService: HttpService, private dateService: DateService, configService: AppConfigService) {
        this.configuration = configService.getConfig();
    }

    public getSettings(): Observable<Setting[]> {
        return this.httpService.get<Setting[]>(`${this.configuration.ApiServer}api/Setting`);
    }

    public getSetting(name: string): Observable<Setting> {
        return this.httpService.get<Setting>(`${this.configuration.ApiServer}api/Setting/${encodeURIComponent(name)}`);
    }

    public updateSetting(setting: Setting): Observable<Setting> {
        return this.httpService.put<Setting>(`${this.configuration.ApiServer}api/Setting`, JSON.stringify(setting));
    }

    public createSetting(setting: Setting): Observable<Setting> {
        return this.httpService.post<Setting>(`${this.configuration.ApiServer}api/Setting`, JSON.stringify(setting));
    }

    public deleteSetting(id: string): Observable<boolean> {
        return this.httpService.delete<boolean>(`${this.configuration.ApiServer}api/Setting/${encodeURIComponent(id)}`);
    }
}

import * as tslib_1 from "tslib";
import { Shape } from "./shape";
var Line = /** @class */ (function (_super) {
    tslib_1.__extends(Line, _super);
    function Line() {
        var _this = _super.call(this) || this;
        _this.Name = 'line';
        _this.AddTop(_this.Top);
        _this.AddBottom(_this.Bottom);
        _this.AddRight(_this.Right);
        _this.AddLeft(_this.Left);
        return _this;
    }
    Line.prototype.AddBottom = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 0, endY: 100, side: '1' });
    };
    Line.prototype.AddTop = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
    };
    Line.prototype.AddRight = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
    };
    Line.prototype.AddLeft = function (sideCoordinates) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
    };
    return Line;
}(Shape));
export { Line };

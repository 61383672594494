import { Shape } from "./shape";
import { SideCoordinate } from "./side-coordinate";

export class RightTriangle extends Shape {
    public constructor() {
        super();
        this.Name = 'right-triangle';
        this.AddTop(this.Top);
        this.AddBottom(this.Bottom);
        this.AddRight(this.Right);
        this.AddLeft(this.Left);
    }

    private AddTop(sideCoordinates: SideCoordinate[]) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 100, side: '1' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: '2' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: '3' });
    }

    private AddBottom(sideCoordinates: SideCoordinate[]) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: '2' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 0, side: '3' });
    }

    private AddLeft(sideCoordinates: SideCoordinate[]) {
        sideCoordinates.push({ startX: 0, startY: 0, endX: 100, endY: 0, side: '1' });
        sideCoordinates.push({ startX: 100, startY: 0, endX: 0, endY: 100, side: '2' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 0, endY: 0, side: '3' });
    }

    private AddRight(sideCoordinates: SideCoordinate[]) {
        sideCoordinates.push({ startX: 100, startY: 0, endX: 100, endY: 100, side: '1' });
        sideCoordinates.push({ startX: 100, startY: 100, endX: 0, endY: 100, side: '2' });
        sideCoordinates.push({ startX: 0, startY: 100, endX: 100, endY: 0, side: '3' });
    }
}

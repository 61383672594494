import * as concaveman from 'concaveman';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Diagram } from '../models/Diagram';
import { MeasurementUnit } from '../models/MeasurementUnit';
import { DiagramService } from './DiagramService';
import { ErrorTranslationService } from './error-translation.service';
import { MeasurementUnitService } from './MeasurementUnitService';
import { WindowService } from './window.service';
import { Utility } from '../utility';
import { SideCoordinate } from '../shapes/side-coordinate';
import { EquilateralTriangle } from '../shapes/equilateral-triangle';
import { Line } from '../shapes/line';
import { RightTriangle } from '../shapes/right-triangle';
import { Trapezoid } from '../shapes/trapezoid';
import { Rectangle } from '../shapes/rectangle';
var GraphService = /** @class */ (function () {
    function GraphService(winService, diagramService, measurementUnitService, errorTranslationService) {
        this.winService = winService;
        this.diagramService = diagramService;
        this.measurementUnitService = measurementUnitService;
        this.errorTranslationService = errorTranslationService;
        this.diagramMap = new Map();
        this.diagramXmlMap = new Map();
        this.scale = 1;
        this.loading = false;
        this.saveRequired = false;
        this.currentDiagramId = '';
        this.isMultiSelect = false;
        GraphService.Singleton = this;
        this.InitializeLibraryOverrides();
        this.measurementUnit = new MeasurementUnit();
        this.measurementUnit.singleName = 'Meter';
        this.measurementUnit.squaredName = 'm²';
        this.measurementUnit.shortName = 'm';
        this.measurementUnit.pluralName = 'Meters';
        this.measurementUnit.description = 'Meters';
    }
    GraphService.createHint = function () {
        var hint = document.createElement('div');
        hint.className = 'geHint';
        hint.style.whiteSpace = 'nowrap';
        hint.style.position = 'absolute';
        return hint;
    };
    GraphService.prototype.InitializeLibraryOverrides = function () {
        mxGraph.prototype.isCellBendable = function (cell) {
            return false;
            // var state = this.view.getState(cell);
            // var style = (state != null) ? state.style : this.getCellStyle(cell);
            // return this.isCellsBendable() && !this.isCellLocked(cell) && style[mxConstants.STYLE_BENDABLE] != 0;
        };
        mxTooltipHandler.prototype.enabled = false;
        mxTooltipHandler.prototype.mouseMove = function (sender, me) { };
        mxTooltipHandler.prototype.init = function () { };
        mxTooltipHandler.prototype.show = function (tip, x, y) { };
        mxGraph.prototype.selectCellForEvent = function (cell, evt) {
            var isSelected = this.isCellSelected(cell);
            if (GraphService.Singleton.isMultiSelect || this.isToggleEvent(evt)) {
                if (isSelected) {
                    this.removeSelectionCell(cell);
                }
                else {
                    this.addSelectionCell(cell);
                }
            }
            else if (!isSelected || this.getSelectionCount() !== 1) {
                this.setSelectionCell(cell);
            }
        };
        mxVertexHandler.prototype.updateHint = function (me) {
            if (this.index !== mxEvent.LABEL_HANDLE) {
                if (this.hint == null) {
                    this.hint = GraphService.createHint();
                    this.state.view.graph.container.appendChild(this.hint);
                }
                if (this.index === mxEvent.ROTATION_HANDLE) {
                    this.hint.innerHTML = this.currentAlpha + '&deg;';
                }
                else {
                    var unitScale = GraphService.Singleton.GetScale();
                    this.hint.innerHTML = (this.bounds.width * unitScale / this.state.view.scale).toFixed(2)
                        + ' x ' +
                        (this.bounds.height * unitScale / this.state.view.scale).toFixed(2);
                }
                var rot = (this.currentAlpha != null) ? this.currentAlpha : this.state.style[mxConstants.STYLE_ROTATION] || '0';
                var bb = mxUtils.getBoundingBox(this.bounds, rot);
                if (bb == null) {
                    bb = this.bounds;
                }
                this.hint.style.left = bb.x + Math.round((bb.width - this.hint.clientWidth) / 2) + 'px';
                this.hint.style.top = (bb.y + bb.height + 12) + 'px';
                if (this.linkHint != null) {
                    this.linkHint.style.display = 'none';
                }
            }
        };
        mxGraphHandler.prototype.mouseUp = function (sender, me) {
            if (!me.isConsumed()) {
                var graph = this.graph;
                // console.log('Graph Service initialized.')
                if (this.cell != null && this.first != null && this.shape != null &&
                    this.currentDx != null && this.currentDy != null) {
                    var cell = me.getCell();
                    if (this.connectOnDrop && this.target == null && cell != null && graph.getModel().isVertex(cell) &&
                        graph.isCellConnectable(cell) && graph.isEdgeValid(null, this.cell, cell)) {
                        graph.connectionHandler.connect(this.cell, cell, me.getEvent());
                    }
                    else {
                        var clone = graph.isCloneEvent(me.getEvent()) && graph.isCellsCloneable() && this.isCloneEnabled();
                        var scale = graph.getView().scale;
                        var dx = this.roundLength(this.currentDx / scale);
                        var dy = this.roundLength(this.currentDy / scale);
                        var target = this.target;
                        if (graph.isSplitEnabled() && graph.isSplitTarget(target, this.cells, me.getEvent())) {
                            graph.splitEdge(target, this.cells, null, dx, dy);
                        }
                        else {
                            // Jeff: had to override the target parameter to null otherwise cells were being reparented...
                            // no option to disable this. This was the only change to this method.
                            this.moveCells(this.cells, dx, dy, clone, null, me.getEvent());
                        }
                    }
                }
                else if (this.isSelectEnabled() && this.delayedSelection && this.cell != null) {
                    this.selectDelayed(me);
                }
            }
            // Consumes the event if a cell was initially clicked
            if (this.cellWasClicked) {
                this.consumeMouseEvent(mxEvent.MOUSE_UP, me);
            }
            this.reset();
        };
        SVGElement.prototype.getTransformToElement = SVGElement.prototype.getTransformToElement || function (elem) {
            return elem.getScreenCTM().inverse().multiply(this.getScreenCTM());
        };
        mxEdgeHandler.prototype.start = function (x, y, index) {
            this.startX = x;
            this.startY = y;
            this.isSource = (this.bends == null) ? false : index === 0;
            this.isTarget = (this.bends == null) ? false : index === this.bends.length - 1;
            this.isLabel = index === mxEvent.LABEL_HANDLE;
            if (this.isSource || this.isTarget) {
                var cell = this.state.cell;
                if (cell.style.includes('shapeLine')) {
                    var terminal = this.graph.model.getTerminal(cell, this.isSource);
                    if ((terminal == null && this.graph.isTerminalPointMovable(cell, this.isSource)) ||
                        (terminal != null && this.graph.isCellDisconnectable(cell, terminal, this.isSource))) {
                        this.index = index;
                    }
                }
            }
            else {
                this.index = index;
            }
            // Hides other custom handles
            if (this.index <= mxEvent.CUSTOM_HANDLE && this.index > mxEvent.VIRTUAL_HANDLE) {
                if (this.customHandles != null) {
                    for (var i = 0; i < this.customHandles.length; i++) {
                        if (i !== mxEvent.CUSTOM_HANDLE - this.index) {
                            this.customHandles[i].setVisible(false);
                        }
                    }
                }
            }
        };
        if (typeof mxGraph.prototype.isCellMovableEx === 'undefined') {
            mxGraph.prototype.isCellMovableEx = this.winService.nativeWindow.mxGraph.prototype.isCellMovable;
            mxGraph.prototype.isCellMovable = function (cell) {
                var selectedCells = this.getSelectionCells();
                var nonGroupSelected = false;
                for (var i = 0; i < selectedCells.length; i++) {
                    if (!selectedCells[0].style.includes('shapeLine') &&
                        !selectedCells[0].style.includes('group') &&
                        selectedCells[0].id !== 'text-cell' &&
                        selectedCells[0].id !== 'description-cell' &&
                        selectedCells[0].id !== 'diagram-text-cell') {
                        nonGroupSelected = true;
                    }
                }
                if (nonGroupSelected) {
                    return false;
                }
                else {
                    return this.isCellMovableEx(cell);
                }
            };
        }
        if (typeof mxGraph.prototype.isCellsMovableEx === 'undefined') {
            mxGraph.prototype.isCellsMovableEx = this.winService.nativeWindow.mxGraph.prototype.isCellsMovable;
            mxGraph.prototype.isCellsMovable = function (cell) {
                var selectedCells = this.getSelectionCells();
                var nonGroupSelected = false;
                for (var i = 0; i < selectedCells.length; i++) {
                    if (!selectedCells[0].style.includes('shapeLine') &&
                        !selectedCells[0].style.includes('group') &&
                        selectedCells[0].id !== 'text-cell' &&
                        selectedCells[0].id !== 'description-cell' &&
                        selectedCells[0].id !== 'diagram-text-cell') {
                        nonGroupSelected = true;
                    }
                }
                if (nonGroupSelected) {
                    return false;
                }
                else {
                    return this.isCellsMovableEx(cell);
                }
            };
        }
        mxGraph.prototype.splitEnabled = function (cell) {
            return false;
        };
        mxGraph.prototype.isCellRotatable = function (cell) {
            return false;
        };
        if (typeof mxGraph.prototype.isCellResizableEx === 'undefined') {
            mxGraph.prototype.isCellResizableEx = this.winService.nativeWindow.mxGraph.prototype.isCellResizable;
            mxGraph.prototype.isCellResizable = function (cell) {
                // console.log('isCellResizable CALLED');
                var selectedCells = this.getSelectionCells();
                var nonGroupSelected = false;
                for (var i = 0; i < selectedCells.length; i++) {
                    if (!selectedCells[0].style.includes('shapeLine') &&
                        !selectedCells[0].style.includes('group') &&
                        selectedCells[0].id !== 'text-cell' &&
                        selectedCells[0].id !== 'description-cell' &&
                        selectedCells[0].id !== 'diagram-text-cell') {
                        nonGroupSelected = true;
                    }
                }
                if (nonGroupSelected) {
                    // console.log('isCellResizable false');
                    return false;
                }
                else {
                    return this.isCellResizableEx(cell);
                }
            };
        }
        if (typeof mxGraph.prototype.isCellsResizableEx === 'undefined') {
            mxGraph.prototype.isCellsResizableEx = this.winService.nativeWindow.mxGraph.prototype.isCellsResizable;
            mxGraph.prototype.isCellsResizable = function (cell) {
                //  console.log('isCellsResizable CALLED');
                var selectedCells = this.getSelectionCells();
                var nonGroupSelected = false;
                for (var i = 0; i < selectedCells.length; i++) {
                    if (!selectedCells[0].style.includes('shapeLine') &&
                        !selectedCells[0].style.includes('group') &&
                        selectedCells[0].id !== 'text-cell' &&
                        selectedCells[0].id !== 'description-cell' &&
                        selectedCells[0].id !== 'diagram-text-cell') {
                        nonGroupSelected = true;
                    }
                }
                if (nonGroupSelected) {
                    //  console.log('isCellsResizable false');
                    return false;
                }
                else {
                    return this.isCellsResizableEx(cell);
                }
            };
        }
    };
    GraphService.prototype.LoadGraph = function (diagram) {
        if (this.IsGraphReady()) {
            this.currentDiagramId = diagram.id;
            this.SetScale(diagram.graphScale);
            this.SetGridSize(diagram.gridSize);
            var xml = this.GetXmlCache(diagram.id);
            if (!Utility.IsEmptyOrNull(xml)) {
                this.SetModelXml(xml);
            }
            else {
                this.SetModelXml('');
            }
            this.AddDescriptionCell(diagram.pageName);
            this.AddDiagramTextCell(this.parentDiagram.description);
            this.ClearUndo();
            this.loading = false;
        }
    };
    GraphService.prototype.DeleteCache = function (key) {
        delete this.diagramXmlMap[key];
        delete this.diagramMap[key];
    };
    GraphService.prototype.SetXmlCache = function (key, xml) {
        this.diagramXmlMap[key] = xml;
    };
    GraphService.prototype.GetXmlCache = function (key) {
        return this.diagramXmlMap[key];
    };
    GraphService.prototype.SetDiagramCache = function (key, diagram) {
        this.diagramMap[key] = diagram;
    };
    GraphService.prototype.GetDiagramCache = function (key) {
        return this.diagramMap[key];
    };
    GraphService.prototype.Load = function (parentDiagramId, editorContainer) {
        var _this = this;
        this.loading = true;
        var complete = new Subject();
        this.editorContainer = editorContainer;
        this.GetDiagram(parentDiagramId).subscribe(function (parentDiagram) {
            forkJoin(_this.GetDiagramChildren(parentDiagram.id), _this.GetMeasurements())
                .subscribe(function (results) {
                var index = 0;
                _this.measurementUnits = results[1];
                _this.parentDiagram = parentDiagram;
                _this.currentDiagramId = parentDiagram.id;
                if (Utility.IsEmptyOrNull(_this.parentDiagram.value)) {
                    _this.parentDiagram.graphScale = 0.02;
                    _this.parentDiagram.gridSize = 10;
                }
                if (Utility.IsEmptyOrNull(parentDiagram.pageName)) {
                    parentDiagram.pageName = 'Main Floor';
                }
                _this.SetDiagramCache(parentDiagram.id, parentDiagram);
                _this.SetXmlCache(parentDiagram.id, parentDiagram.value);
                results[0].forEach(function (child) {
                    index++;
                    if (Utility.IsEmptyOrNull(child.pageName)) {
                        child.pageName = 'Floor ' + index.toString();
                    }
                    _this.SetDiagramCache(child.id, child);
                    _this.SetXmlCache(child.id, child.value);
                });
                var meterMeasurement = null;
                if (typeof parentDiagram.measurementUnitId === 'undefined' || parentDiagram.measurementUnitId === null) {
                    meterMeasurement = _this.measurementUnits.find(function (x) { return x.singleName === 'Meter'; });
                    parentDiagram.measurementUnitId = meterMeasurement.id;
                    _this.Measurement = meterMeasurement;
                }
                else {
                    meterMeasurement = _this.measurementUnits.find(function (x) { return x.id === parentDiagram.measurementUnitId; });
                    _this.Measurement = meterMeasurement;
                }
                _this.ShowGraph(editorContainer).subscribe(function () {
                    _this.LoadGraph(parentDiagram);
                    complete.next(true);
                });
            }, _this.errorTranslationService.ErrorHandler);
        });
        return complete;
    };
    GraphService.prototype.LoadDiagram = function (id) {
        var _this = this;
        var complete = new Subject();
        if (this.loading) {
            complete = new BehaviorSubject(true);
            return complete;
        }
        this.loading = true;
        var cachedDiagram = this.GetDiagramCache(id);
        if (Utility.IsNull(cachedDiagram) || Utility.IsNull(this.GetXmlCache(cachedDiagram.id))) {
            this.GetDiagram(id).subscribe(function (diagram) {
                _this.currentDiagramId = id;
                _this.SetXmlCache(id, diagram.value);
                _this.SetDiagramCache(id, diagram);
                var meterMeasurement = null;
                if (Utility.IsNull(diagram.measurementUnitId)) {
                    meterMeasurement = _this.measurementUnits.find(function (x) { return x.singleName === 'Meter'; });
                    diagram.measurementUnitId = meterMeasurement.id;
                    _this.Measurement = meterMeasurement;
                }
                else {
                    meterMeasurement = _this.measurementUnits.find(function (x) { return x.id === diagram.measurementUnitId; });
                    _this.Measurement = meterMeasurement;
                }
                _this.editorContainer.children[0].innerHTML = '';
                _this.ShowGraph(_this.editorContainer).subscribe(function () {
                    _this.LoadGraph(diagram);
                    complete.next(true);
                });
            }, this.errorTranslationService.ErrorHandler);
        }
        else {
            this.currentDiagramId = id;
            var meterMeasurement = null;
            if (Utility.IsNull(cachedDiagram.measurementUnitId)) {
                meterMeasurement = this.measurementUnits.find(function (x) { return x.singleName === 'Meter'; });
                cachedDiagram.measurementUnitId = meterMeasurement.id;
                this.Measurement = meterMeasurement;
            }
            else {
                meterMeasurement = this.measurementUnits.find(function (x) { return x.id === cachedDiagram.measurementUnitId; });
                this.Measurement = meterMeasurement;
            }
            this.editorContainer.children[0].innerHTML = '';
            this.ShowGraph(this.editorContainer).subscribe(function () {
                _this.LoadGraph(cachedDiagram);
                complete.next(true);
            });
        }
        return complete;
    };
    GraphService.prototype.ShowGraph = function (graphElement) {
        var _this = this;
        var complete = new Subject();
        var editorUiInit = this.winService.nativeWindow.EditorUi.prototype.init;
        this.winService.nativeWindow.EditorUi.prototype.init = function () {
            editorUiInit.apply(this, arguments);
        };
        // Adds required resources (disables loading of fallback properties, this can only
        // be used if we know that all keys are defined in the language specific file)
        mxResources.loadDefaultBundle = false;
        var bundle = this.winService.nativeWindow.mxResources.getDefaultBundle(this.winService.nativeWindow.RESOURCE_BASE, this.winService.nativeWindow.mxLanguage) ||
            this.winService.nativeWindow.mxResources.getSpecialBundle(this.winService.nativeWindow.RESOURCE_BASE, this.winService.nativeWindow.mxLanguage);
        // Fixes possible asynchronous requests
        this.winService.nativeWindow.mxUtils.getAll([bundle, this.winService.nativeWindow.STYLE_PATH + '/default.xml'], function (xhr) {
            // Adds bundle text to resources
            _this.winService.nativeWindow.mxResources.parse(xhr[0].getText());
            // Configures the default graph theme
            var themes = new Object();
            themes[_this.winService.nativeWindow.Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();
            // Main
            _this.winService.nativeWindow.EditorUIInstance = null;
            _this.winService.nativeWindow.EditorUIInstance =
                new _this.winService.nativeWindow.EditorUi(new _this.winService.nativeWindow.Editor(_this.winService.nativeWindow.urlParams['chrome'] === '0', themes), graphElement);
            _this.undoManager = new mxUndoManager();
            _this.graph = _this.winService.nativeWindow.EditorUIInstance.editor.graph;
            _this.graph.connectionArrowsEnabled = false;
            _this.InitializeUndoManager(_this.graph, _this.undoManager);
            _this.graph.addListener(_this.winService.nativeWindow.mxEvent.CELLS_RESIZED, function (sender, evt) {
                setTimeout(function () { _this.UpdateAllSizeLabels(); }, 200);
            });
            // this.graph.model.addListener(this.winService.nativeWindow.mxEvent.CHANGE, (sender, evt) => {
            //   if (!this.loading) {
            //     const xml = this.GetModelXml();
            //     this.SetXmlCache(this.currentDiagramId, xml);
            //   }
            // });
            // this.graph.model.addListener(this.winService.nativeWindow.mxEvent.NOTIFY, (sender, evt) => {
            //   if (!this.loading) {
            //     const xml = this.GetModelXml();
            //     this.SetXmlCache(this.currentDiagramId, xml);
            //   }
            // });
            _this.graph.model.addListener(_this.winService.nativeWindow.mxEvent.END_EDIT, function (sender, evt) {
                if (!_this.loading) {
                    var xml = _this.GetModelXml();
                    _this.SetXmlCache(_this.currentDiagramId, xml);
                }
            });
            _this.graph.convertValueToString = function (cell) {
                if (mxUtils.isNode(cell.value)) {
                    return cell.value.getAttribute('label', '');
                }
                else {
                    return cell.value;
                }
            };
            var cellLabelChanged = _this.graph.cellLabelChanged;
            _this.graph.cellLabelChanged = function (cell, newValue, autoSize) {
                if (mxUtils.isNode(cell.value)) {
                    // Clones the value for correct undo/redo
                    var elt = cell.value.cloneNode(true);
                    elt.setAttribute('label', newValue);
                    newValue = elt;
                }
                else {
                    cell.value = newValue;
                }
                cellLabelChanged.apply(this, arguments);
            };
            complete.next(true);
        }, function () {
            document.body.innerHTML = '<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
        });
        return complete;
    };
    GraphService.prototype.ToggleMultiSelect = function () {
        this.isMultiSelect = !this.isMultiSelect;
    };
    GraphService.prototype.GridSize = function (size) {
        this.graph.gridSize(size);
    };
    GraphService.prototype.ZoomIn = function () {
        this.graph.zoomIn();
    };
    GraphService.prototype.ZoomOut = function () {
        this.graph.zoomOut();
    };
    GraphService.prototype.FitWindow = function () {
        this.graph.fit();
    };
    GraphService.prototype.FitPage = function () {
        var fmt = this.graph.pageFormat;
        var ps = this.graph.pageScale;
        var cw = this.graph.container.clientWidth - 10;
        var ch = this.graph.container.clientHeight - 10;
        var scale = Math.floor(20 * Math.min(cw / fmt.width / ps, ch / fmt.height / ps)) / 20;
        this.graph.zoomTo(scale);
        if (mxUtils.hasScrollbars(this.graph.container)) {
            var pad = this.graph.getPagePadding();
            this.graph.container.scrollTop = pad.y * this.graph.view.scale;
            this.graph.container.scrollLeft = Math.min(pad.x * this.graph.view.scale, (this.graph.container.scrollWidth - this.graph.container.clientWidth) / 2);
        }
    };
    GraphService.prototype.SendToFront = function () {
        this.graph.orderCells(false);
    };
    GraphService.prototype.SendToBack = function () {
        this.graph.orderCells(true);
    };
    GraphService.prototype.GetPathCoordinates = function (cell, path, terminalOnly) {
        var totalLength = Math.ceil(path.getTotalLength());
        var points = [];
        if (terminalOnly) {
            var abspoint0 = path.getPointAtLength(0);
            var abspoint1 = path.getPointAtLength(totalLength);
            abspoint0.x = abspoint0.x / this.graph.view.scale;
            abspoint0.y = abspoint0.y / this.graph.view.scale;
            abspoint1.x = abspoint1.x / this.graph.view.scale;
            abspoint1.y = abspoint1.y / this.graph.view.scale;
            points.push([abspoint0.x, abspoint0.y, cell]);
            points.push([abspoint1.x, abspoint1.y, cell]);
        }
        else {
            for (var index = 0; index < path.getTotalLength(); index++) {
                var point = path.getPointAtLength(index);
                point.x = point.x / this.graph.view.scale;
                point.y = point.y / this.graph.view.scale;
                points.push([point.x, point.y, cell]);
            }
        }
        return points;
    };
    GraphService.prototype.GetCellPoints = function (cell, points, terminalOnly) {
        var _this = this;
        if (points === void 0) { points = null; }
        if (cell.children != null && cell.children.length > 0) {
            cell.children.forEach(function (child) {
                _this.GetCellPoints(child, points, terminalOnly);
            });
        }
        else {
            var shape = this.graph.view.getState(cell).shape;
            if (shape.node.children.length > 0) {
                var path = shape.node.children[0];
                if (typeof path !== 'undefined' && path !== null) {
                    points = this.GetPathCoordinates(cell, path, terminalOnly);
                }
            }
        }
        return points;
    };
    GraphService.prototype.TranslatePoint = function (point) {
        var dx = this.graph.container.scrollLeft / this.graph.view.scale - this.graph.view.translate.x;
        var dy = this.graph.container.scrollTop / this.graph.view.scale - this.graph.view.translate.y;
        var layout = this.graph.getPageLayout();
        var page = this.graph.getPageSize();
        dx = Math.max(dx, layout.x * page.width);
        dy = Math.max(dy, layout.y * page.height);
        return [point[0] - this.graph.view.translate.x, point[1] - this.graph.view.translate.y];
    };
    GraphService.prototype.PointDifference = function (coordinateOne, coordinateTwo) {
        return Math.abs(coordinateOne[0] - coordinateTwo[0]) + Math.abs(coordinateOne[1] - coordinateTwo[1]);
    };
    GraphService.prototype.GetDistanceBetweenPoints = function (x0, y0, x1, y1) {
        var a = x0 - x1;
        var b = y0 - y1;
        return Math.sqrt(a * a + b * b);
    };
    GraphService.prototype.SortCells = function (cells) {
        var _this = this;
        var points = new Array();
        cells.forEach(function (cell) {
            var cellPoints = _this.GetCellPoints(cell, null, false);
            cellPoints.forEach(function (point) {
                var translatedPoints = _this.TranslatePoint(point);
                translatedPoints.push(cell);
                points.push(translatedPoints);
            });
        });
        var polygonPoints = concaveman(points);
        var sortedCellList = [];
        polygonPoints.forEach(function (polygonPoint) {
            var found = false;
            cells = cells.filter(function (selectedCell) {
                if (!found && selectedCell.mxObjectId === polygonPoint[2].mxObjectId) {
                    sortedCellList.push(selectedCell);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });
        return sortedCellList;
    };
    GraphService.prototype.GetCellSortedPoints = function (cells) {
        var previousCell = null;
        var nextCell = null;
        var currentCell = null;
        var sortedCellList = this.SortCells(cells);
        var sortedCellPoints = new Array();
        for (var cellIndex = 0; cellIndex < sortedCellList.length; cellIndex++) {
            currentCell = sortedCellList[cellIndex];
            var startPointX = 0;
            var startPointY = 0;
            var endPointX = 0;
            var endPointY = 0;
            var cellPoints = this.GetCellPoints(currentCell, null, true);
            startPointX = cellPoints[0][0];
            startPointY = cellPoints[0][1];
            endPointX = cellPoints[1][0];
            endPointY = cellPoints[1][1];
            sortedCellPoints.push([[startPointX, startPointY], [endPointX, endPointY]]);
        }
        return sortedCellPoints;
    };
    GraphService.prototype.SortCoordinates = function (coordinates) {
        var nextSideCoordinate = null;
        var previousSideCoordinate = null;
        var currentSideCoordinate = null;
        for (var index = 0; index < coordinates.length; index++) {
            currentSideCoordinate = coordinates[index];
            if (index + 1 < coordinates.length) {
                nextSideCoordinate = coordinates[index + 1];
            }
            else {
                nextSideCoordinate = null;
            }
            if (index - 1 >= 0) {
                previousSideCoordinate = coordinates[index - 1];
            }
            else {
                previousSideCoordinate = null;
            }
            if (nextSideCoordinate == null) {
                if (previousSideCoordinate !== null) {
                    var startAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.startX, currentSideCoordinate.startY, previousSideCoordinate.startX, previousSideCoordinate.startY);
                    var startAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.startX, currentSideCoordinate.startY, previousSideCoordinate.endX, previousSideCoordinate.endY);
                    var endAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.endX, currentSideCoordinate.endY, previousSideCoordinate.startX, previousSideCoordinate.startY);
                    var endAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.endX, currentSideCoordinate.endY, previousSideCoordinate.endX, previousSideCoordinate.endY);
                    var min = Math.min(startAndStart, startAndEnd, endAndStart, endAndEnd);
                    if (startAndStart === min) {
                        var startX = previousSideCoordinate.startX;
                        var startY = previousSideCoordinate.startY;
                        var endX = previousSideCoordinate.endX;
                        var endY = previousSideCoordinate.endY;
                        previousSideCoordinate.startY = endY;
                        previousSideCoordinate.startX = endX;
                        previousSideCoordinate.endX = startX;
                        previousSideCoordinate.endY = startY;
                    }
                    else if (startAndEnd === min) {
                        // do nothing... we are good
                    }
                    else if (endAndStart === min) {
                        var startX = currentSideCoordinate.startX;
                        var startY = currentSideCoordinate.startY;
                        var endX = currentSideCoordinate.endX;
                        var endY = currentSideCoordinate.endY;
                        currentSideCoordinate.startY = endY;
                        currentSideCoordinate.startX = endX;
                        currentSideCoordinate.endX = startX;
                        currentSideCoordinate.endY = startY;
                        startX = previousSideCoordinate.startX;
                        startY = previousSideCoordinate.startY;
                        endX = previousSideCoordinate.endX;
                        endY = previousSideCoordinate.endY;
                        previousSideCoordinate.startY = endY;
                        previousSideCoordinate.startX = endX;
                        previousSideCoordinate.endX = startX;
                        previousSideCoordinate.endY = startY;
                    }
                    else if (endAndEnd === min) {
                        var startX = currentSideCoordinate.startX;
                        var startY = currentSideCoordinate.startY;
                        var endX = currentSideCoordinate.endX;
                        var endY = currentSideCoordinate.endY;
                        currentSideCoordinate.startY = endY;
                        currentSideCoordinate.startX = endX;
                        currentSideCoordinate.endX = startX;
                        currentSideCoordinate.endY = startY;
                    }
                }
            }
            else {
                var startAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.startX, currentSideCoordinate.startY, nextSideCoordinate.startX, nextSideCoordinate.startY);
                var startAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.startX, currentSideCoordinate.startY, nextSideCoordinate.endX, nextSideCoordinate.endY);
                var endAndStart = this.GetDistanceBetweenPoints(currentSideCoordinate.endX, currentSideCoordinate.endY, nextSideCoordinate.startX, nextSideCoordinate.startY);
                var endAndEnd = this.GetDistanceBetweenPoints(currentSideCoordinate.endX, currentSideCoordinate.endY, nextSideCoordinate.endX, nextSideCoordinate.endY);
                var min = Math.min(startAndStart, startAndEnd, endAndStart, endAndEnd);
                if (startAndStart === min) {
                    var startX = currentSideCoordinate.startX;
                    var startY = currentSideCoordinate.startY;
                    var endX = currentSideCoordinate.endX;
                    var endY = currentSideCoordinate.endY;
                    currentSideCoordinate.startY = endY;
                    currentSideCoordinate.startX = endX;
                    currentSideCoordinate.endX = startX;
                    currentSideCoordinate.endY = startY;
                }
                else if (startAndEnd === min) {
                    var startX = currentSideCoordinate.startX;
                    var startY = currentSideCoordinate.startY;
                    var endX = currentSideCoordinate.endX;
                    var endY = currentSideCoordinate.endY;
                    currentSideCoordinate.startY = endY;
                    currentSideCoordinate.startX = endX;
                    currentSideCoordinate.endX = startX;
                    currentSideCoordinate.endY = startY;
                    startX = nextSideCoordinate.startX;
                    startY = nextSideCoordinate.startY;
                    endX = nextSideCoordinate.endX;
                    endY = nextSideCoordinate.endY;
                    nextSideCoordinate.startY = endY;
                    nextSideCoordinate.startX = endX;
                    nextSideCoordinate.endX = startX;
                    nextSideCoordinate.endY = startY;
                }
                else if (endAndStart === min) {
                    // do nothing... we are good
                }
                else if (endAndEnd === min) {
                    var startX = nextSideCoordinate.startX;
                    var startY = nextSideCoordinate.startY;
                    var endX = nextSideCoordinate.endX;
                    var endY = nextSideCoordinate.endY;
                    nextSideCoordinate.startY = endY;
                    nextSideCoordinate.startX = endX;
                    nextSideCoordinate.endX = startX;
                    nextSideCoordinate.endY = startY;
                }
            }
        }
        return coordinates;
    };
    GraphService.prototype.CreateLinesFromShapeSelection = function () {
        var cell = this.GetSelectedOneShape();
        if (cell !== null && this.IsCustomShape(cell)) {
            cell.children.forEach(function (childCell) {
                childCell.style = childCell.style.replace('shapeSide', 'shapeLine');
            });
            this.RemoveGroup([cell]);
        }
    };
    GraphService.prototype.CreateShapeFromSelection = function () {
        var _this = this;
        this.graph.getModel().beginUpdate();
        try {
            var selectedCells = this.graph.getSelectionCells();
            var cellPoints = this.GetCellSortedPoints(selectedCells);
            var sideCoordinates_1 = new Array();
            cellPoints.forEach(function (points) {
                var sideCoordinate = new SideCoordinate();
                sideCoordinate.startX = _this.TranslatePoint(points[0])[0];
                sideCoordinate.startY = _this.TranslatePoint(points[0])[1];
                sideCoordinate.endX = _this.TranslatePoint(points[1])[0];
                sideCoordinate.endY = _this.TranslatePoint(points[1])[1];
                sideCoordinate.side = '1';
                sideCoordinates_1.push(sideCoordinate);
            });
            sideCoordinates_1 = this.SortCoordinates(sideCoordinates_1);
            this.AddShape(sideCoordinates_1, 'customShape', false, true);
            this.DeleteSelectedShapes();
        }
        finally {
            // Updates the display
            this.graph.getModel().endUpdate();
            this.RefreshAll();
        }
    };
    GraphService.prototype.FitPageWidth = function () {
        var fmt = this.graph.pageFormat;
        var ps = this.graph.pageScale;
        var cw = this.graph.container.clientWidth - 10;
        var scale = Math.floor(20 * cw / fmt.width / ps) / 20;
        this.graph.zoomTo(scale);
        if (mxUtils.hasScrollbars(this.graph.container)) {
            var pad = this.graph.getPagePadding();
            this.graph.container.scrollLeft = Math.min(pad.x * this.graph.view.scale, (this.graph.container.scrollWidth - this.graph.container.clientWidth) / 2);
        }
    };
    GraphService.prototype.GetCellById = function (id) {
        var foundCell = null;
        var totalArea = 0;
        var allCells = this.graph.getChildCells();
        allCells.forEach(function (cell) {
            if (cell.id === id) {
                foundCell = cell;
                return;
            }
        });
        return foundCell;
    };
    GraphService.prototype.GetAreaTextCell = function () {
        var cell = this.GetCellById('text-cell');
        if (cell === null) {
            var freeInsertPoint = this.GetFreeInsertPoint();
            var geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
            cell = new this.winService.nativeWindow.mxCell(null, geometry, 'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
            cell.setConnectable(false);
            cell.id = 'text-cell';
            cell.setVertex(true);
            this.graph.addCells([cell], this.graph.getDefaultParent());
        }
        return cell;
    };
    GraphService.prototype.GetDiagramTextCell = function () {
        var cell = this.GetCellById('diagram-text-cell');
        if (cell === null) {
            var freeInsertPoint = this.GetFreeInsertPoint();
            var geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
            cell = new this.winService.nativeWindow.mxCell(null, geometry, 'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
            cell.setConnectable(false);
            cell.id = 'diagram-text-cell';
            cell.setVertex(true);
            this.graph.addCells([cell], this.graph.getDefaultParent());
        }
        return cell;
    };
    GraphService.prototype.GetDescriptionTextCell = function () {
        var cell = this.GetCellById('description-cell');
        if (cell === null) {
            var freeInsertPoint = this.GetFreeInsertPoint();
            var geometry = new this.winService.nativeWindow.mxGeometry(freeInsertPoint.x, freeInsertPoint.y, 100, 100);
            cell = new this.winService.nativeWindow.mxCell(null, geometry, 'text;movable=1;connectable=0;resizable=1;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;');
            cell.setConnectable(false);
            cell.id = 'description-cell';
            cell.setVertex(true);
            this.graph.addCells([cell], this.graph.getDefaultParent());
        }
        return cell;
    };
    GraphService.prototype.CreateCell = function (startX, startY, endX, endY, side, isShapeSide) {
        var styleExtras = 'shapeSide;endArrow=none;resizable=1;movable=1;editable=0;cloneable=0;deletable=1;connectable=0;html=1;strokeColor=#000000';
        if (!isShapeSide) {
            styleExtras = 'shapeLine;endArrow=none;resizable=1;movable=1;editable=0;cloneable=0;deletable=1;connectable=1;html=1;strokeColor=#000000';
        }
        var startPoint = new this.winService.nativeWindow.mxPoint(startX, startY);
        var endPoint = new this.winService.nativeWindow.mxPoint(endX, endY);
        var geometry = new this.winService.nativeWindow.mxGeometry(startX, startY, endY - startY, .1);
        geometry.setTerminalPoint(startPoint, true);
        geometry.setTerminalPoint(endPoint, false);
        var cell = new this.winService.nativeWindow.mxCell(null, geometry, styleExtras);
        cell.edge = true;
        var doc = mxUtils.createXmlDocument();
        var node = doc.createElement('SideData');
        node.setAttribute('label', '');
        node.setAttribute('hidden', '0');
        node.setAttribute('side', side);
        node.setAttribute('sideThickness', '0');
        cell.value = node;
        return cell;
    };
    GraphService.prototype.SetScale = function (scale) {
        if (this.IsGraphReady()) {
            this.scale = parseFloat(scale.toFixed(2));
            this.UpdateAllSizeLabels();
            this.GetArea();
            this.graph.refresh();
        }
    };
    GraphService.prototype.GetScale = function () {
        return this.scale;
    };
    GraphService.prototype.GetGraph = function () {
        if (this.IsGraphReady()) {
            return this.graph;
        }
        else {
            return null;
        }
    };
    GraphService.prototype.UpdateCellSizeLabels = function (cell) {
        var _this = this;
        if (cell.style.includes('group') || (cell.children !== null && cell.children.length > 0)) {
            cell.children.forEach(function (child) {
                _this.UpdateCellSizeLabels(child);
            });
        }
        else {
            var state = this.graph.view.getState(cell);
            if (typeof state !== 'undefined' && state !== null && state.shape !== null) {
                if (this.IsSideLabelHidden(cell)) {
                    this.SetCellAttribute(cell, 'label', '');
                }
                else {
                    if (state.shape.node.children.length > 0) {
                        var path = state.shape.node.children[0];
                        var length_1 = path.getTotalLength() * this.scale / this.graph.view.scale;
                        var thickness = this.GetSideThickness(cell);
                        if (thickness !== 0) {
                            this.SetCellAttribute(cell, 'label', length_1.toFixed(2) + ' (' + thickness + ') ' + this.measurementUnit.shortName);
                        }
                        else {
                            this.SetCellAttribute(cell, 'label', length_1.toFixed(2) + ' ' + this.measurementUnit.shortName);
                        }
                    }
                }
            }
        }
    };
    GraphService.prototype.GetSideLength = function (cell) {
        var length = 0;
        var state = this.graph.view.getState(cell);
        if (typeof state !== 'undefined' && state !== null && state.shape !== null) {
            if (state.shape.node.children.length > 0) {
                var path = state.shape.node.children[0];
                length = path.getTotalLength() * this.scale / this.graph.view.scale;
            }
        }
        return length;
    };
    GraphService.prototype.UpdateAllSizeLabels = function () {
        var _this = this;
        if (!this.IsGraphReady()) {
            return;
        }
        this.graph.getModel().beginUpdate();
        try {
            var allCells = this.graph.getChildVertices(this.graph.getDefaultParent());
            allCells.forEach(function (cell) {
                if (!_this.IsTextCell(cell)) {
                    _this.UpdateCellSizeLabels(cell);
                }
            });
            allCells = this.graph.getChildEdges();
            allCells.forEach(function (cell) {
                if (!_this.IsTextCell(cell)) {
                    _this.UpdateCellSizeLabels(cell);
                }
            });
            this.graph.refresh();
        }
        finally {
            // Updates the display
            this.graph.getModel().endUpdate();
            this.graph.refresh();
        }
    };
    GraphService.prototype.SetSelectedCellsAttribute = function (key, value) {
        var cells = this.graph.getSelectionCells();
        cells.forEach(function (cell) {
            if (mxUtils.isNode(cell.value)) {
                cell.value.setAttribute(key, value);
            }
        });
    };
    GraphService.prototype.SetCellAttribute = function (cell, key, value) {
        if (mxUtils.isNode(cell.value)) {
            cell.value.setAttribute(key, value);
        }
        var xml = this.GetModelXml();
        this.SetXmlCache(this.currentDiagramId, xml);
    };
    GraphService.prototype.GetCellAttribute = function (cell, key) {
        var value = '';
        if (mxUtils.isNode(cell.value)) {
            value = cell.value.getAttribute(key, '');
        }
        return value;
    };
    GraphService.prototype.GetSideDirection = function (cell) {
        return this.GetCellAttribute(cell, 'side');
    };
    GraphService.prototype.GetInsertPoint = function () {
        var gs = this.graph.getGridSize();
        var dx = this.graph.container.scrollLeft / this.graph.view.scale - this.graph.view.translate.x;
        var dy = this.graph.container.scrollTop / this.graph.view.scale - this.graph.view.translate.y;
        var layout = this.graph.getPageLayout();
        var page = this.graph.getPageSize();
        dx = Math.max(dx, layout.x * page.width);
        dy = Math.max(dy, layout.y * page.height);
        return new mxPoint(this.graph.snap(dx + gs), this.graph.snap(dy + gs));
    };
    GraphService.prototype.GetFreeInsertPoint = function () {
        var view = this.graph.view;
        var bds = this.graph.getGraphBounds();
        var pt = this.GetInsertPoint();
        // Places at same x-coord and 2 grid sizes below existing graph
        var x = this.graph.snap(Math.round(Math.max(pt.x, bds.x / view.scale - view.translate.x +
            ((bds.width === 0) ? this.graph.gridSize : 0))));
        var y = this.graph.snap(Math.round(Math.max(pt.y, (bds.y + bds.height) / view.scale - view.translate.y +
            ((bds.height === 0) ? 1 : 2) * this.graph.gridSize)));
        return new mxPoint(x, y);
    };
    GraphService.prototype.AddLine = function () {
        var shape = new Line();
        this.AddShape(shape.GetTop(), shape.Name, true, false);
    };
    GraphService.prototype.AddVerticalLine = function () {
        var shape = new Line();
        this.AddShape(shape.GetBottom(), shape.Name, true, false);
    };
    GraphService.prototype.AddTopEquiliateralTriangle = function () {
        var shape = new EquilateralTriangle();
        this.AddShape(shape.GetTop(), shape.Name);
    };
    GraphService.prototype.AddBottomEquiliateralTriangle = function () {
        var shape = new EquilateralTriangle();
        this.AddShape(shape.GetBottom(), shape.Name);
    };
    GraphService.prototype.AddLeftEquiliateralTriangle = function () {
        var shape = new EquilateralTriangle();
        this.AddShape(shape.GetLeft(), shape.Name);
    };
    GraphService.prototype.AddRightEquiliateralTriangle = function () {
        var shape = new EquilateralTriangle();
        this.AddShape(shape.GetRight(), shape.Name);
    };
    GraphService.prototype.AddTopRightTriangle = function () {
        var shape = new RightTriangle();
        this.AddShape(shape.GetTop(), shape.Name);
    };
    GraphService.prototype.AddBottomRightTriangle = function () {
        var shape = new RightTriangle();
        this.AddShape(shape.GetBottom(), shape.Name);
    };
    GraphService.prototype.AddLeftRightTriangle = function () {
        var shape = new RightTriangle();
        this.AddShape(shape.GetLeft(), shape.Name);
    };
    GraphService.prototype.AddRightRightTriangle = function () {
        var shape = new RightTriangle();
        this.AddShape(shape.GetRight(), shape.Name);
    };
    GraphService.prototype.AddRectangle = function () {
        var shape = new Rectangle();
        this.AddShape(shape.GetTop(), shape.Name);
    };
    GraphService.prototype.AddRightTrapezoid = function () {
        var shape = new Trapezoid();
        this.AddShape(shape.GetRight(), shape.Name);
    };
    GraphService.prototype.AddLeftTrapezoid = function () {
        var shape = new Trapezoid();
        this.AddShape(shape.GetLeft(), shape.Name);
    };
    GraphService.prototype.AddTopTrapezoid = function () {
        var shape = new Trapezoid();
        this.AddShape(shape.GetTop(), shape.Name);
    };
    GraphService.prototype.AddBottomTrapezoid = function () {
        var shape = new Trapezoid();
        this.AddShape(shape.GetBottom(), shape.Name);
    };
    GraphService.prototype.CreateGroup = function (cells, shapeType) {
        var group = this.graph.groupCells(null, 0, cells);
        var doc = mxUtils.createXmlDocument();
        var node = doc.createElement('CellData');
        node.setAttribute('label', '');
        node.setAttribute('negative', '0');
        node.setAttribute('shapeType', shapeType);
        group.value = node;
        return group;
    };
    GraphService.prototype.RemoveGroup = function (cells) {
        this.graph.ungroupCells(cells);
    };
    GraphService.prototype.DeleteSelectedShapes = function () {
        this.graph.removeCells(this.graph.getSelectionCells(), true);
        this.graph.escape();
        this.RefreshAll();
    };
    GraphService.prototype.GetShapeType = function (cell) {
        var shapeType = '';
        if (cell.style.includes('group')) {
            shapeType = this.GetCellAttribute(cell, 'shapeType');
        }
        return shapeType;
    };
    GraphService.prototype.AddShape = function (sideCoordinates, name, findFreeinsertPoint, groupSides) {
        var _this = this;
        if (findFreeinsertPoint === void 0) { findFreeinsertPoint = true; }
        if (groupSides === void 0) { groupSides = true; }
        var parent = this.graph.getDefaultParent();
        // Adds cells to the model in a single step
        this.graph.getModel().beginUpdate();
        try {
            var cells_1 = [];
            var freeInsertPoint_1 = this.GetFreeInsertPoint();
            if (!findFreeinsertPoint) {
                freeInsertPoint_1.x = 0;
                freeInsertPoint_1.y = 0;
            }
            sideCoordinates.forEach(function (sideCoordinate) {
                cells_1.push(_this.CreateCell(sideCoordinate.startX + freeInsertPoint_1.x, sideCoordinate.startY + freeInsertPoint_1.y, sideCoordinate.endX + freeInsertPoint_1.x, sideCoordinate.endY + freeInsertPoint_1.y, sideCoordinate.side, groupSides));
            });
            this.graph.addCells(cells_1, parent);
            if (groupSides) {
                this.CreateGroup(cells_1, name);
            }
        }
        finally {
            // Updates the display
            this.graph.getModel().endUpdate();
            this.UpdateAllSizeLabels();
        }
    };
    GraphService.prototype.GetPointsFromPath = function (path, sideDirection, sideLengthDictionary) {
        var totalLength = Math.ceil(path.getTotalLength());
        var points = [];
        var abspoint0 = path.getPointAtLength(0);
        var abspoint1 = path.getPointAtLength(totalLength);
        abspoint0.x = abspoint0.x / this.graph.view.scale;
        abspoint0.y = abspoint0.y / this.graph.view.scale;
        abspoint1.x = abspoint1.x / this.graph.view.scale;
        abspoint1.y = abspoint1.y / this.graph.view.scale;
        points.push(abspoint0);
        points.push(abspoint1);
        return points;
    };
    GraphService.prototype.GetXPointsFromPath = function (points, xPoints) {
        if (xPoints === null) {
            xPoints = new Array();
        }
        points.forEach(function (point) {
            xPoints.push(point.x);
        });
        return xPoints;
    };
    GraphService.prototype.GetYPointsFromPath = function (points, yPoints) {
        if (yPoints === null) {
            yPoints = new Array();
        }
        points.forEach(function (point) {
            yPoints.push(point.y);
        });
        return yPoints;
    };
    GraphService.prototype.PopulateCellPoints = function (cell, xPoints, yPoints, sideLengthDictionary) {
        var _this = this;
        if (cell.children != null && cell.children.length > 0) {
            sideLengthDictionary = {};
            cell.children.forEach(function (child) {
                sideLengthDictionary[_this.GetSideDirection(child)] = _this.GetSideThickness(child);
            });
            cell.children.forEach(function (child) {
                _this.PopulateCellPoints(child, xPoints, yPoints, sideLengthDictionary);
            });
        }
        else {
            var shape = this.graph.view.getState(cell).shape;
            if (shape.node.children.length > 0) {
                var path = shape.node.children[0];
                var sideDirection = this.GetSideDirection(cell);
                if (typeof path !== 'undefined' && path !== null) {
                    var points = this.GetPointsFromPath(path, sideDirection, sideLengthDictionary);
                    xPoints = this.GetXPointsFromPath(points, xPoints);
                    yPoints = this.GetYPointsFromPath(points, yPoints);
                }
            }
        }
    };
    GraphService.prototype.IsGraphReady = function () {
        return typeof this.graph !== 'undefined' && this.graph !== null;
    };
    GraphService.prototype.GetArea = function () {
        var _this = this;
        if (this.IsGraphReady()) {
            var allCells = this.graph.getChildCells();
            var totalArea_1 = 0;
            allCells.forEach(function (cell) {
                if (!_this.IsTextCell(cell) && _this.IsShape(cell)) {
                    var xPoints = new Array();
                    var yPoints = new Array();
                    _this.PopulateCellPoints(cell, xPoints, yPoints, null);
                    var area = _this.GetPolygonArea(xPoints, yPoints);
                    var negative = _this.GetCellAttribute(cell, 'negative') === '1';
                    if (negative) {
                        area = area * -1;
                    }
                    totalArea_1 += area;
                }
            });
            var areaTextCell = this.GetAreaTextCell();
            areaTextCell.value = totalArea_1.toFixed(2) + ' ' + this.measurementUnit.squaredName;
            return totalArea_1;
        }
        else {
            return 0;
        }
    };
    GraphService.prototype.AddDescriptionCell = function (description) {
        var textCell = this.GetDescriptionTextCell();
        textCell.value = description;
        this.RefreshAll();
    };
    GraphService.prototype.AddDiagramTextCell = function (diagramDescription) {
        var textCell = this.GetDiagramTextCell();
        textCell.value = diagramDescription;
        this.RefreshAll();
    };
    GraphService.prototype.GetPolygonArea = function (xPoints, yPoints) {
        var numPoints = xPoints.length;
        var area = 0; // Accumulates area in the loop
        var j = numPoints - 1; // The last vertex is the 'previous' one to the first
        for (var i = 0; i < numPoints; i++) {
            area = area + ((xPoints[j] * this.scale + xPoints[i] * this.scale) * (yPoints[j] * this.scale - yPoints[i] * this.scale));
            j = i; // j is previous vertex to i
        }
        area = area / 2 * -1;
        if (area < 0) {
            area = area * -1;
        }
        return area;
    };
    GraphService.prototype.RefreshLabels = function () {
        this.UpdateAllSizeLabels();
    };
    GraphService.prototype.RefreshAll = function (asynchronous) {
        var _this = this;
        if (asynchronous === void 0) { asynchronous = false; }
        if (asynchronous) {
            setTimeout(function () {
                _this.RefreshAll(false);
            }, 100);
        }
        else {
            this.graph.refresh();
            this.GetArea();
            this.UpdateAllSizeLabels();
            this.graph.refresh();
        }
    };
    GraphService.prototype.IsSide = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.style.includes('shapeSide') || cell.style.includes('shapeLine');
    };
    GraphService.prototype.IsShapeLine = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.style.includes('shapeLine');
    };
    GraphService.prototype.IsShape = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.style.includes('group');
    };
    GraphService.prototype.IsCustomShape = function (cell) {
        if (cell === null) {
            return false;
        }
        return this.GetShapeType(cell) === 'customShape';
    };
    GraphService.prototype.IsPlainTextCell = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.style.includes('text') && cell.id !== 'text-cell' && cell.id !== 'description-cell' && cell.id !== 'diagram-text-cell';
    };
    GraphService.prototype.IsTextCell = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.id === 'text-cell' || cell.id === 'description-cell' || cell.id === 'diagram-text-cell';
    };
    GraphService.prototype.IsSideHidden = function (cell) {
        if (cell === null) {
            return false;
        }
        return cell.style.includes('strokeColor=#FFFFFF');
    };
    GraphService.prototype.GetSideThickness = function (cell) {
        if (cell === null) {
            return 0;
        }
        var thickness = this.GetCellAttribute(cell, 'sideThickness');
        if (thickness !== null) {
            return parseFloat(thickness);
        }
        else {
            return 0;
        }
    };
    GraphService.prototype.SetSideThickness = function (cell, thickness) {
        this.SetCellAttribute(cell, 'sideThickness', thickness.toString());
        this.RefreshAll();
    };
    GraphService.prototype.Travel = function (length, x1, y1, x2, y2) {
        length = length / this.scale;
        var yVector = y2 - y1;
        var xVector = x2 - x1;
        var vectorLength = Math.sqrt(xVector * xVector + yVector * yVector);
        var normalizedX = xVector / vectorLength;
        var normalizedY = yVector / vectorLength;
        var newXPoint = x1 + (normalizedX * length);
        var newYPoint = y1 + (normalizedY * length);
        return { x: newXPoint, y: newYPoint };
    };
    GraphService.prototype.SetSideLength = function (cell, newLength) {
        if (this.IsGraphReady() && newLength > 0) {
            if (this.IsShapeLine(cell)) {
                var newPoint = this.Travel(newLength, cell.geometry.sourcePoint.x, cell.geometry.sourcePoint.y, cell.geometry.targetPoint.x, cell.geometry.targetPoint.y);
                cell.geometry.targetPoint.x = newPoint.x;
                cell.geometry.targetPoint.y = newPoint.y;
                this.RefreshAll(true);
            }
        }
    };
    GraphService.prototype.GetSelectedOneSide = function () {
        var sideCell = null;
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells.length === 1 && !this.IsTextCell(selectedCells[0])) {
                if (this.IsSide(selectedCells[0])) {
                    sideCell = selectedCells[0];
                }
            }
        }
        return sideCell;
    };
    GraphService.prototype.IsOneSideSelected = function () {
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells.length === 1 && !this.IsTextCell(selectedCells[0])) {
                return this.IsSide(selectedCells[0]);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    GraphService.prototype.GetShapeHeight = function (cell) {
        return cell.geometry.height * this.scale;
    };
    GraphService.prototype.GetShapeWidth = function (cell) {
        return cell.geometry.width * this.scale;
    };
    GraphService.prototype.ChangeSelectedShapeDimensions = function (height, width) {
        if (this.IsGraphReady()) {
            var cell = this.GetSelectedOneShape();
            if (cell !== null) {
                var bounds = new mxRectangle(cell.geometry.x, cell.geometry.y, width / this.scale, height / this.scale);
                this.graph.resizeCell(cell, bounds, true);
            }
            this.RefreshAll(true);
        }
    };
    GraphService.prototype.IsOneLineSelected = function () {
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                !this.IsTextCell(selectedCells[0]) &&
                this.IsShapeLine(selectedCells[0])) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    GraphService.prototype.AreAllSelectedCellsLines = function () {
        var _this = this;
        var allSelectedCellsAreLines = false;
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length > 2) {
                allSelectedCellsAreLines = true;
                selectedCells.forEach(function (selectedCell) {
                    if (!_this.IsShapeLine(selectedCell)) {
                        allSelectedCellsAreLines = false;
                    }
                });
                return allSelectedCellsAreLines;
            }
            else {
                return allSelectedCellsAreLines;
            }
        }
        else {
            return allSelectedCellsAreLines;
        }
    };
    GraphService.prototype.IsOnePlainTextSelected = function () {
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                this.IsPlainTextCell(selectedCells[0])) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    GraphService.prototype.GetSelectedOneText = function () {
        var selectedShape = null;
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                this.IsTextCell(selectedCells[0])) {
                selectedShape = selectedCells[0];
            }
        }
        return selectedShape;
    };
    GraphService.prototype.IsOneShapeSelected = function () {
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                !this.IsTextCell(selectedCells[0]) &&
                this.IsShape(selectedCells[0])) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    GraphService.prototype.IsOneCustomShapeSelected = function () {
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                !this.IsTextCell(selectedCells[0]) &&
                this.IsCustomShape(selectedCells[0])) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    GraphService.prototype.GetSelectedOneShape = function () {
        var selectedShape = null;
        if (this.IsGraphReady()) {
            var selectedCells = this.graph.getSelectionCells();
            if (selectedCells !== null && selectedCells.length === 1 &&
                !this.IsTextCell(selectedCells[0]) &&
                this.IsShape(selectedCells[0])) {
                selectedShape = selectedCells[0];
            }
        }
        return selectedShape;
    };
    GraphService.prototype.IsShapeNegativeSpace = function (cell) {
        if (this.IsShape(cell)) {
            return this.GetCellAttribute(cell, 'negative') === '1';
        }
        else {
            return false;
        }
    };
    GraphService.prototype.HasChanges = function () {
        var _this = this;
        if (this.saveRequired) {
            return true;
        }
        var hasChanges = false;
        this.Diagrams.forEach(function (diagram) {
            var xml = _this.GetXmlCache(diagram.id);
            if (Utility.IsEmptyOrNull(diagram.id) ||
                diagram.value !== xml) {
                hasChanges = true;
            }
        });
        return hasChanges;
    };
    GraphService.prototype.SetShapeNegativeSpace = function (cell, isNegativeSpace) {
        // let lineColor = '#000000';
        // if (isNegativeSpace) {
        //   lineColor = '#ff0000';
        // }
        // cell.children.forEach((child) => {
        //   child.style = mxUtils.setStyle(child.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, lineColor);
        // });
        this.SetCellAttribute(cell, 'negative', isNegativeSpace ? '1' : '0');
        this.RefreshAll();
    };
    GraphService.prototype.InitializeUndoManager = function (graph, undoManager) {
        var parent = this;
        var undoListener = function (sender, evt) {
            undoManager.undoableEditHappened(evt.getProperty('edit'));
            parent.RefreshAll();
        };
        graph.getModel().addListener(mxEvent.UNDO, undoListener);
        graph.getView().addListener(mxEvent.UNDO, undoListener);
        // Keeps the selection in sync with the history
        var undoHandler = function (sender, evt) {
            var cand = graph.getSelectionCellsForChanges(evt.getProperty('edit').changes);
            var model = graph.getModel();
            var cells = [];
            for (var i = 0; i < cand.length; i++) {
                if ((model.isVertex(cand[i]) || model.isEdge(cand[i])) && graph.view.getState(cand[i]) != null) {
                    cells.push(cand[i]);
                }
            }
            graph.setSelectionCells(cells);
            parent.RefreshAll();
        };
        undoManager.addListener(mxEvent.UNDO, undoHandler);
        undoManager.addListener(mxEvent.REDO, undoHandler);
    };
    GraphService.prototype.ClearUndo = function () {
        this.undoManager.clear();
    };
    GraphService.prototype.Redo = function () {
        try {
            var graph = this.graph;
            if (graph.isEditing()) {
                document.execCommand('redo', false, null);
            }
            else {
                if (this.undoManager.canRedo()) {
                    this.undoManager.redo();
                }
            }
        }
        catch (e) {
            // ignore all errors
        }
    };
    GraphService.prototype.CanUndo = function () {
        if (typeof this.undoManager === 'undefined') {
            return false;
        }
        return this.undoManager.canUndo();
    };
    GraphService.prototype.CanRedo = function () {
        if (typeof this.undoManager === 'undefined') {
            return false;
        }
        return this.undoManager.canRedo();
    };
    GraphService.prototype.Undo = function () {
        try {
            if (this.graph.isEditing() && this.undoManager.canUndo()) {
                // Stops editing and executes undo on graph if native undo
                // does not affect current editing value
                var value = this.graph.cellEditor.textarea.innerHTML;
                document.execCommand('undo', false, null);
                if (value === this.graph.cellEditor.textarea.innerHTML) {
                    this.graph.stopEditing(true);
                    this.undoManager.undo();
                }
            }
            else {
                if (this.undoManager.canUndo()) {
                    this.undoManager.undo();
                }
            }
        }
        catch (e) {
            // ignore all errors
        }
    };
    GraphService.prototype.HideSide = function (cell) {
        if (this.IsSide(cell) && this.IsGraphReady()) {
            this.SetCellAttribute(cell, 'hidden', '1');
            cell.style = mxUtils.setStyle(cell.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, '#FFFFFF');
            this.graph.refresh();
            var xml = this.GetModelXml();
            this.SetXmlCache(this.currentDiagramId, xml);
        }
    };
    GraphService.prototype.HideSideLabel = function (cell) {
        if (this.IsSide(cell) && this.IsGraphReady()) {
            this.SetCellAttribute(cell, 'label-hidden', '1');
            this.RefreshLabels();
        }
    };
    GraphService.prototype.ShowSideLabel = function (cell) {
        if (this.IsSide(cell) && this.IsGraphReady()) {
            this.SetCellAttribute(cell, 'label-hidden', '0');
            this.RefreshLabels();
        }
    };
    GraphService.prototype.IsSideLabelHidden = function (cell) {
        var hidden = false;
        if (this.IsSide(cell) && this.IsGraphReady()) {
            hidden = this.GetCellAttribute(cell, 'label-hidden') === '1';
        }
        return hidden;
    };
    GraphService.prototype.ShowSide = function (cell) {
        if (this.IsSide(cell) && this.IsGraphReady()) {
            this.SetCellAttribute(cell, 'hidden', '0');
            cell.style = mxUtils.setStyle(cell.style, this.winService.nativeWindow.mxConstants.STYLE_STROKECOLOR, '#000000');
            this.graph.refresh();
            var xml = this.GetModelXml();
            this.SetXmlCache(this.currentDiagramId, xml);
        }
    };
    GraphService.prototype.GetModelXml = function () {
        if (this.IsGraphReady()) {
            var encoder = new mxCodec();
            var result = encoder.encode(this.graph.getModel());
            return mxUtils.getXml(result);
        }
        return '';
    };
    GraphService.prototype.SetModelXml = function (xml) {
        var doc = mxUtils.parseXml(xml);
        var codec = new mxCodec(doc);
        codec.decode(doc.documentElement, this.graph.getModel());
        this.RefreshAll();
    };
    GraphService.prototype.SetGridSize = function (size) {
        if (this.IsGraphReady()) {
            var graph = this.GetGraph();
            graph.gridSize = size;
            graph.refresh();
        }
    };
    GraphService.prototype.SaveDiagrams = function (diagramsToSave, complete) {
        var _this = this;
        if (complete === void 0) { complete = null; }
        if (Utility.IsNull(complete)) {
            complete = new BehaviorSubject(false);
        }
        if (diagramsToSave.length === 0) {
            complete.next(true);
        }
        else {
            var diagram_1 = diagramsToSave.pop();
            var xml = this.GetXmlCache(diagram_1.id);
            var oldValue_1 = diagram_1.value; // Save in case there is an error saving...
            // if xml is null then no changes were made to the model for
            // this diagram or the diagram was never loaded... nothing to save
            if (xml == null) {
                this.SaveDiagrams(diagramsToSave, complete); // move on to saving the remaining diagrams
            }
            else {
                diagram_1.value = xml;
                this.SaveDiagram(diagram_1).subscribe(function () { return _this.SaveDiagrams(diagramsToSave, complete); }, function (error) { _this.errorTranslationService.ErrorHandler(error); diagram_1.value = oldValue_1; });
            }
        }
        return complete;
    };
    GraphService.prototype.SaveDiagram = function (diagram) {
        var _this = this;
        if (Utility.IsEmptyOrNull(diagram.id)) {
            return this.diagramService.createDiagram(diagram)
                .pipe(map(function (savedDiagram) {
                _this.SetXmlCache(savedDiagram.id, savedDiagram.value);
                _this.SetDiagramCache(savedDiagram.id, savedDiagram);
                return savedDiagram;
            }));
        }
        else {
            return this.diagramService.updateDiagram(diagram)
                .pipe(map(function (savedDiagram) {
                _this.SetXmlCache(savedDiagram.id, savedDiagram.value);
                _this.SetDiagramCache(savedDiagram.id, savedDiagram);
                return savedDiagram;
            }));
        }
    };
    GraphService.prototype.DeleteCurrentDiagram = function () {
        var _this = this;
        var currentDiagram = this.CurrentDiagram;
        return this.diagramService.deleteDiagramPage(currentDiagram.id, currentDiagram.rangeId).pipe(map(function () {
            _this.DeleteCache(currentDiagram.id);
        }));
    };
    GraphService.prototype.IsCurrentDiagramParent = function () {
        if (Utility.IsEmptyOrNull(this.parentDiagram) || Utility.IsEmptyOrNull(this.CurrentDiagram)) {
            return false;
        }
        return this.parentDiagram.id === this.CurrentDiagram.id;
    };
    GraphService.prototype.Save = function () {
        this.saveRequired = false;
        return this.SaveDiagrams(this.Diagrams);
    };
    GraphService.prototype.SetDirty = function () {
        this.saveRequired = true;
    };
    GraphService.prototype.Delete = function (id) {
        return this.diagramService.deleteDiagram(id);
    };
    Object.defineProperty(GraphService.prototype, "ParentDiagram", {
        get: function () {
            return this.parentDiagram;
        },
        enumerable: true,
        configurable: true
    });
    GraphService.prototype.GetDiagram = function (id) {
        return this.diagramService.getDiagram(id);
    };
    GraphService.prototype.GetDiagramChildren = function (parentId) {
        return this.diagramService.getDiagramChildrens(parentId);
    };
    GraphService.prototype.ShowPrintPreview = function (diagramDescription) {
        var autoOrigin = true;
        var printScale = 1;
        if (isNaN(printScale)) {
            printScale = 1;
        }
        // Workaround to match available paper size in actual print output
        printScale *= 0.75;
        var pf = this.graph.pageFormat || mxConstants.PAGE_FORMAT_LETTER_PORTRAIT;
        var scale = 1 / this.graph.pageScale;
        // if (autoOrigin) {
        //   let pageCount = 1;
        //   if (!isNaN(pageCount)) {
        //     scale = mxUtils.getScaleForPageCount(pageCount, this.graph, pf);
        //   }
        // }
        // Negative coordinates are cropped or shifted if page visible
        var gb = this.graph.getGraphBounds();
        var border = 0;
        var x0 = 0;
        var y0 = 0;
        // Applies print scale
        pf = mxRectangle.fromRectangle(pf);
        pf.width = Math.ceil(pf.width * printScale);
        pf.height = Math.ceil(pf.height * printScale);
        scale *= printScale;
        // Starts at first visible page
        if (!autoOrigin && this.graph.pageVisible) {
            var layout = this.graph.getPageLayout();
            x0 -= layout.x * pf.width;
            y0 -= layout.y * pf.height;
        }
        else {
            autoOrigin = true;
            var preview = new mxPrintPreview(this.graph, scale, pf, border, x0, y0);
            preview.title = diagramDescription; // mxResources.get('preview');
            preview.printBackgroundImage = true;
            preview.autoOrigin = autoOrigin;
            var bg = this.graph.background;
            if (bg == null || bg === '' || bg === mxConstants.NONE) {
                bg = '#ffffff';
            }
            preview.backgroundColor = bg;
            var writeHead_1 = preview.writeHead;
            // Adds a border in the preview
            preview.writeHead = function (doc) {
                writeHead_1.apply(this, arguments);
                doc.writeln('<style type="text/css">');
                doc.writeln('@media screen {');
                doc.writeln('  body > div { padding:30px;box-sizing:content-box; }');
                doc.writeln('}');
                doc.writeln('</style>');
            };
            preview.open();
        }
    };
    GraphService.prototype.GetDiagrams = function (pageSize, page) {
        return this.diagramService.getDiagramPageSizePages(pageSize, page);
    };
    GraphService.prototype.GetDiagramCount = function () {
        return this.diagramService.getDiagramCount();
    };
    GraphService.prototype.GetMeasurements = function () {
        return this.measurementUnitService.getMeasurementUnits();
    };
    Object.defineProperty(GraphService.prototype, "CurrentDiagram", {
        get: function () {
            var diagram = this.GetDiagramCache(this.currentDiagramId);
            if (Utility.IsNull(diagram)) {
                // Temp placeholder until real diagram is loaded
                diagram = new Diagram();
                diagram.graphScale = 0.02;
                diagram.gridSize = 10;
            }
            return diagram;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GraphService.prototype, "Measurements", {
        get: function () {
            return this.measurementUnits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GraphService.prototype, "Measurement", {
        get: function () {
            return this.measurementUnit;
        },
        set: function (measurementUnit) {
            this.measurementUnit = measurementUnit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GraphService.prototype, "Diagrams", {
        get: function () {
            var _this = this;
            var diagrams = new Array();
            Object.keys(this.diagramMap).forEach(function (key) {
                diagrams.push(_this.diagramMap[key]);
            });
            return diagrams;
        },
        enumerable: true,
        configurable: true
    });
    return GraphService;
}());
export { GraphService };

﻿
// Generated using Typewritter, please do not modify!


export class Payment {
        
    public name : string = null;    
    public orderId : string = null;    
    public amount : string = null;    
    public creditCardNumber : string = null;    
    public expiryDate : string = null;
}

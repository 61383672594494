import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Setting } from '@xactly-app/common/src/lib/models/Setting';
import { SettingService } from '@xactly-app/common/src/lib/services/SettingService';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {
  public settings: Setting[];
  public saveSettingStack: Setting[];
  constructor(public apiService: SettingService,
    public errorTranslationService: ErrorTranslationService,
    public dialogService: DialogService) {
    super();

  }

  ngOnInit() {
    this.busy();
    this.apiService.getSettings().pipe(finalize(() => { this.ready(); }))
      .subscribe(settings => {
        this.settings = settings;
      },
        this.errorTranslationService.ErrorHandler
      );
  }

  private saveSetting(setting: Setting) {
    this.busy();
    this.apiService.updateSetting(setting).pipe(finalize(() => { this.ready(); }))
      .subscribe(u => {
        if (this.saveSettingStack.length > 0) {
          this.saveSetting(this.saveSettingStack.pop());
        } else {
          this.dialogService.showOkDialog('Settings were saved successfully.', 'Save').subscribe();
        }
      },
        this.errorTranslationService.ErrorHandler
      );
  }

  public save() {
    this.saveSettingStack = [];
    for (const setting of this.settings) {
      this.saveSettingStack.push(setting);
    }
    this.saveSetting(this.saveSettingStack.pop());
  }
}

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PermissionService } from '@xactly-app/common/src/lib/services/PermissionService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { finalize } from 'rxjs/operators';
var RolesViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RolesViewComponent, _super);
    function RolesViewComponent(apiService, permissionService, errorTranslationService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.permissionService = permissionService;
        _this.errorTranslationService = errorTranslationService;
        _this.showInstructions = true;
        return _this;
    }
    RolesViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.busy();
        this.permissionService.getPermissions().pipe(finalize(function () { _this.ready(); })).subscribe(function (permissions) {
            _this.permissions = permissions;
            _this.apiService.getRoles().subscribe(function (roles) {
                _this.roles = roles;
            }, _this.errorTranslationService.ErrorHandler);
        }, this.errorTranslationService.ErrorHandler);
    };
    return RolesViewComponent;
}(BaseComponent));
export { RolesViewComponent };

export class BaseComponent {
    public SupportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    public _loading = false;
    constructor() {
        this._loading = false;
    }

    get loading(): boolean {
        return this._loading;
    }

    busy() {
        this._loading = true;
    }
    ready() {
        this._loading = false;
    }
}

export class PagingBaseComponent extends BaseComponent {
    public currentPage = 1;
    public pageSize = 10;
    public totalPages = 10;
    public totalCount = 0;
    constructor() {
        super();
    }
}

<div fxLayout="column" fxLayoutAlign="center center">
  <mat-card class="intro-card">
    <mat-card-header>
      <mat-card-title>About Xactly</mat-card-title>
    </mat-card-header>
    
    <mat-card-content>
      
    </mat-card-content>
  </mat-card>
</div>

import * as tslib_1 from "tslib";
import { AfterViewChecked, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize } from 'rxjs/operators';
var SubscriptionTypeAddComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionTypeAddComponent, _super);
    function SubscriptionTypeAddComponent(winService, stateService, dialogService, validationService, errorTranslationService, activatedRoute, router, subscriptionTypeService) {
        var _this = _super.call(this) || this;
        _this.winService = winService;
        _this.stateService = stateService;
        _this.dialogService = dialogService;
        _this.validationService = validationService;
        _this.errorTranslationService = errorTranslationService;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.subscriptionTypeService = subscriptionTypeService;
        _this.subscriptionType = new SubscriptionType();
        return _this;
    }
    SubscriptionTypeAddComponent.prototype.ngAfterViewChecked = function () {
    };
    SubscriptionTypeAddComponent.prototype.ngAfterViewInit = function () {
    };
    SubscriptionTypeAddComponent.prototype.ngOnInit = function () {
    };
    SubscriptionTypeAddComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        this.subscriptionTypeService.createSubscriptionType(this.subscriptionType)
            .pipe(finalize(function () { _this.ready(); }))
            .subscribe(function (subscriptionType) {
            _this.subscriptionType = subscriptionType;
            _this.router.navigate(['/subscriptionTypes']);
        }, this.errorTranslationService.ErrorHandler);
    };
    SubscriptionTypeAddComponent.prototype.Cancel = function () {
        this.router.navigate(['/subscriptionTypes']);
    };
    return SubscriptionTypeAddComponent;
}(BaseComponent));
export { SubscriptionTypeAddComponent };

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent extends BaseComponent implements OnInit {
  public agentUsers: AgentUser[];
  constructor(
    public errorTranslationService: ErrorTranslationService,
    public dialogService: DialogService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public agentUserService: AgentUserService,
    public agentService: AgentService,
    public userService: UserService) {
    super();
  }

  ngOnInit() {
    this.Load();
  }

  protected Edit(agentUser: AgentUser) {
    this.router.navigate(['/agent', agentUser.agent.id]);
  }

  public Add() {
    this.router.navigate(['/agent']);
  }

  protected Delete(agentUser: AgentUser) {
    this.dialogService.showConfirmDialog('Are you sure you want to remove this agent?', 'Remove Agent').subscribe(
      dialogResult => {
        if (dialogResult) {
          this.busy();
          this.agentService.deleteAgent(agentUser.agent.id).pipe(finalize(() => { this.ready(); })).subscribe(
            () => {
              this.Load();
            },
            this.errorTranslationService.ErrorHandler);
        }
      }
    );
  }

  protected Load() {
    this.busy();
    return this.agentUserService.getAgentUsers().pipe(finalize(() => { this.ready(); })).subscribe(
      agentUsers => {
        this.agentUsers = agentUsers;
      },
      this.errorTranslationService.ErrorHandler);

  }

}

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { Registration } from '@xactly-app/common/src/lib/models/Registration';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { RegistrationService } from '@xactly-app/common/src/lib/services/RegistrationService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { finalize } from 'rxjs/operators';
var RegistrationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RegistrationComponent, _super);
    function RegistrationComponent(userService, agentService, router, errorTranslationService, validationService, dialogService, registrationService) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.agentService = agentService;
        _this.router = router;
        _this.errorTranslationService = errorTranslationService;
        _this.validationService = validationService;
        _this.dialogService = dialogService;
        _this.registrationService = registrationService;
        _this.disclaimerAccepted = false;
        _this.registration = new Registration();
        _this.registration.agent = new Agent();
        _this.registration.user = new User();
        _this.registration.user.person = new Person();
        _this.registration.user.person.address = new Address();
        _this.registration.changePasswordUrl = Utility.GetServer() + 'changepassword';
        return _this;
    }
    RegistrationComponent.prototype.register = function () {
        var _this = this;
        this.busy();
        this.registrationService
            .createRegistration(this.registration)
            .pipe(finalize(function () {
            _this.ready();
        }))
            .subscribe(function (resultRegistration) {
            _this.dialogService
                .showOkDialog('You have been registered successfully.  You will receive an email with instructions on how to set your password. Please ensure that this email is not in your spam folder.', 'Registration')
                .subscribe(function (e) {
                _this.router.navigate(['/home']);
            });
        }, this.errorTranslationService.ErrorHandler);
    };
    RegistrationComponent.prototype.ngOnInit = function () { };
    return RegistrationComponent;
}(BaseComponent));
export { RegistrationComponent };

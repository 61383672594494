import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { finalize, take } from 'rxjs/operators';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
var UsersViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UsersViewComponent, _super);
    function UsersViewComponent(apiService, agentService, agencyService, errorTranslationService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.agentService = agentService;
        _this.agencyService = agencyService;
        _this.errorTranslationService = errorTranslationService;
        return _this;
    }
    UsersViewComponent.prototype.userDeleted = function (user) {
        this.load();
    };
    UsersViewComponent.prototype.userChanged = function (user) {
    };
    UsersViewComponent.prototype.load = function () {
        var _this = this;
        this.agentUsers = new Array();
        this.busy();
        this.apiService.getUsers().pipe(finalize(function () { _this.ready(); })).subscribe(function (users) {
            users.forEach(function (user, index, array) {
                var agentUser = new AgentUser();
                agentUser.user = user;
                agentUser.agent = new Agent();
                agentUser.agent.agencyId = '';
                agentUser.agency = new Agency();
                _this.agentUsers.push(agentUser);
                _this.agentService.getAgentUser(user.id).pipe(finalize(function () { _this.ready(); })).subscribe(function (agent) {
                    if (agent !== null) {
                        agentUser.agent = agent;
                        if (agent.agencyId !== null) {
                            _this.agencyService.getAgency(agent.agencyId)
                                .pipe(take(1), finalize(function () { _this.ready(); })).subscribe(function (agency) {
                                if (agency !== null) {
                                    agentUser.agency = agency;
                                }
                            });
                        }
                        else {
                            agent.agencyId = '0';
                        }
                    }
                });
            });
        }, this.errorTranslationService.ErrorHandler);
    };
    UsersViewComponent.prototype.ngOnInit = function () {
        this.showInstructions = true;
        this.load();
    };
    return UsersViewComponent;
}(BaseComponent));
export { UsersViewComponent };

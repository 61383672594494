import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private oidcSecurityService: AuthenticationService;

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = this.injector.get(AuthenticationService);
        }
        if (this.oidcSecurityService !== undefined) {
            const tokenHeader = this.oidcSecurityService.getAuthorizationHeaderValue();
            if (tokenHeader !== '') {
                requestToForward = req.clone({
                    setHeaders: {
                        Authorization: tokenHeader,
                        'Content-Type': 'application/json'
                    }
                });
            } else {
                requestToForward = req.clone({
                    setHeaders: { 'Content-Type': 'application/json' }
                });
            }
        } else {
        }
        return next.handle(requestToForward);
    }

    // private httpErrorHandler<T>(error: any, caught: Observable<T>): Observable<T> {
    //     if (typeof error._body !== 'undefined' && error._body != null) {
    //         try {
    //             if (error.status === 401) {
    //                 this.securityService.IsAuthorized = false;
    //                 this.router.navigate(['/home']);
    //                 // navigate to the home page
    //                 return empty();
    //             } else {
    //                 console.log(error);
    //             }
    //         } catch (exception) {
    //             console.log(exception);
    //         }
    //     } else if (typeof error.status !== 'undefined' && error.status != null) {
    //         try {
    //             if (error.status === 401) {
    //                 this.securityService.IsAuthorized = false;
    //                 this.router.navigate(['/home']);
    //                 // navigate to the home page
    //                 return empty();
    //             } else {
    //                 console.log(error);
    //             }
    //         } catch (exception) {
    //             console.log(exception);
    //         }
    //     }
    //     else {
    //         console.log(error);
    //     }
    //     return observableThrowError(error);
    // }
}

// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var SubscriptionType = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionType, _super);
    function SubscriptionType() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = null;
        _this.description = null;
        _this.price = 0;
        _this.maximumUsers = 0;
        _this.durationDays = 0;
        _this.isSystem = false;
        _this.isAgencyLevel = false;
        return _this;
    }
    return SubscriptionType;
}(DynamoEntity));
export { SubscriptionType };

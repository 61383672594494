import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionService } from '@xactly-app/common/src/lib/services/SubscriptionService';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { Utility } from '@xactly-app/common/src/lib/utility';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent extends BaseComponent implements OnInit {

  public subscriptionInformationList = [];
  constructor(public subscriptionService: SubscriptionService,
    public subscriptionTypeService: SubscriptionTypeService,
    public errorTranslationService: ErrorTranslationService,
    public agentService: AgentService,
    public agencyService: AgencyService,
    public dialogService: DialogService,
    public userService: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.LoadSubscriptions();
  }

  Add() {
    this.router.navigate(['/subscription/add']);
  }

  protected LoadSubscriptions() {
    this.busy();
    const subscriptionTypeMap = new Map<string, SubscriptionType>();
    const agentMap = new Map<string, Agent>();
    const userMap = new Map<string, User>();
    const agencyMap = new Map<string, Agency>();

    forkJoin(
      this.subscriptionService.getSubscriptions(),
      this.subscriptionTypeService.getSubscriptionTypes(),
      this.agentService.getAgents(),
      this.userService.getUsers(),
      this.agencyService.getAgencys()
    ).pipe(finalize(() => this.ready())).subscribe((results) => {
      const subscriptions = results[0];
      const subscriptionTypes = results[1];
      const agents = results[2];
      const users = results[3];
      const agencies = results[4];
      subscriptionTypes.forEach((subscriptionType) => {
        subscriptionTypeMap[subscriptionType.id] = subscriptionType;
      });
      users.forEach((user) => {
        userMap[user.id] = user;
      });
      agents.forEach((agent) => {
        agentMap[agent.id] = agent;
      });
      agencies.forEach((agency) => {
        agencyMap[agency.id] = agency;
      });

      subscriptions.forEach((subscription) => {
        const subscriptionType = subscriptionTypeMap[subscription.subscriptionTypeId];
        let agency: Agency = null;
        let agent: Agent = null;
        let user: User = null;

        if (Utility.IsEmptyOrNull(subscription.agencyId)) {
          agent = agentMap[subscription.agentId];
          if (Utility.IsEmptyOrNull(agent.userId)) {
            agency = agencyMap[agent.agencyId];
          } else {
            user = userMap[agent.userId];
          }
        } else {
          agency = agencyMap[subscription.agencyId];
        }

        this.subscriptionInformationList.push({
          description: subscriptionType.description,
          startDate: new Date(subscription.startDate),
          duration: subscriptionType.durationDays,
          maximumUsers: subscriptionType.maximumUsers,
          name: Utility.IsEmptyOrNull(subscription.agencyId) ? user.person.firstName + ', ' + user.person.lastName : agency.name
        });
      });
    });
  }
}

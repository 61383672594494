/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instruction-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../controls/alert/alert.component.ngfactory";
import * as i3 from "../controls/alert/alert.component";
import * as i4 from "./instruction-card.component";
var styles_InstructionCardComponent = [i0.styles];
var RenderType_InstructionCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstructionCardComponent, data: {} });
export { RenderType_InstructionCardComponent as RenderType_InstructionCardComponent };
export function View_InstructionCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(2, 114688, null, 0, i3.AlertComponent, [], { message: [0, "message"], dismissable: [1, "dismissable"], title: [2, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(4, 114688, null, 0, i3.AlertComponent, [], { message: [0, "message"], dismissable: [1, "dismissable"], title: [2, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = false; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.message; var currVal_4 = _co.dismissable; var currVal_5 = false; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_InstructionCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "instruction-card", [], null, null, null, View_InstructionCardComponent_0, RenderType_InstructionCardComponent)), i1.ɵdid(1, 114688, null, 0, i4.InstructionCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstructionCardComponentNgFactory = i1.ɵccf("instruction-card", i4.InstructionCardComponent, View_InstructionCardComponent_Host_0, { message: "message", title: "title", dismissable: "dismissable" }, {}, []);
export { InstructionCardComponentNgFactory as InstructionCardComponentNgFactory };

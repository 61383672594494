<form #userForm="ngForm" (ngSubmit)="save()">
    <mat-card>
        <mat-card-content>
            <div *ngFor="let effectivePermission of role.effectivePermissions">                                
                <permission-component [form]="userForm" [effectivePermission]="effectivePermission" [permission]="getPermission(effectivePermission.permissionId)"></permission-component>
            </div>
        </mat-card-content>        
        <mat-card-actions>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </mat-card-actions>
    </mat-card>
</form>
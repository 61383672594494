<form #userForm="ngForm" (ngSubmit)="save()">
    <mat-card>
        <mat-card-title>
        </mat-card-title>
        <mat-card-subtitle>
        </mat-card-subtitle>
        <mat-card-content>
            <div>
                <mat-checkbox [(ngModel)]="agentUser.user.isActive" type="text" name="agentUser.user.isActive">Active</mat-checkbox>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="agentUser.user.person.firstName" maxlength="200" placeholder="First Name" type="text" name="userpersonfirstName"
                        required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="agentUser.user.person.lastName" maxlength="200" placeholder="Last Name" type="text" name='user.person.lastName'
                        required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="agentUser.user.person.primaryPhone" [textMask]="validationService.PhoneMask" placeholder="Primary Phone"
                        type="text" name="userpersonprimaryPhone" #userpersonprimaryPhone="ngModel" required completed>
                    <mat-error *ngIf="userpersonprimaryPhone.errors?.required">Phone number is required</mat-error>
                    <mat-error *ngIf="userpersonprimaryPhone.errors?.notCompleted">Phone number is not valid</mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="agentUser.user.person.secondaryPhone" [textMask]="validationService.PhoneMask" placeholder="Secondary Phone"
                        type="text" name="userpersonsecondaryPhone" #userpersonsecondaryPhone="ngModel" completed>
                    <mat-error *ngIf="userpersonsecondaryPhone.errors?.notCompleted">Phone number is not valid</mat-error>
                </mat-form-field>
            </div>
            <address-component [form]="userForm" [address]="agentUser.user.person.address"></address-component>
            <div>
                <mat-form-field>
                    <input matInput [(ngModel)]="agentUser.user.username" placeholder="Email" type="text" name="userpersonemail" #userpersonemail="ngModel"
                        required emailvalid>
                    <mat-error *ngIf="userpersonemail.errors?.emailInvalid">Email is not valid</mat-error>
                </mat-form-field>
            </div>            
            <div>
                <div>
                    <strong>Role Assigned:</strong>
                    <mat-select placeholder="Role" [(ngModel)]="agentUser.user.roleId" name="agentUser.user.roleId">
                        <mat-option *ngFor="let role of roles" [value]="role.id">
                            {{role.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div>
                <div>                        
                    <strong>Agency Assigned:</strong>

                    <mat-select placeholder="Agency" [(ngModel)]="agentUser.agent.agencyId" name="agentUser.agent.agencyId">
                        <mat-option *ngFor="let agency of agencies" [value]="agency.id">
                            {{agency.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <loading-indicator *ngIf="loading"></loading-indicator>            
            <button [disabled]="loading || !userForm.valid || !userForm.dirty" mat-raised-button color="primary" type="submit">Save</button>
            <button [disabled]="loading" mat-raised-button color="warn" (click)="delete()" type="button">Delete</button>
        </mat-card-actions>
    </mat-card>
</form>
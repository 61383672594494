﻿
// Generated using Typewritter, please do not modify!


export class Address {
        
    public apartment : string = null;    
    public country : string = null;    
    public streetAddress : string = null;    
    public city : string = null;    
    public postalCode : string = null;    
    public state : string = null;
}

﻿
// Generated using Typewritter, please do not modify!

import { DynamoEntity } from './DynamoEntity';

export class MeasurementUnit extends DynamoEntity {
        
    public singleName : string = null;    
    public pluralName : string = null;    
    public shortName : string = null;    
    public squaredName : string = null;    
    public description : string = null;
}

<instruction-card [message]="'This page displays all of your subscriptions'" [title]="'Subscriptions'"></instruction-card>
<mat-card>
  <mat-card-content>
    <loading-indicator *ngIf="loading"></loading-indicator>
    <div>
      <button mat-button color="primary" (click)="Add()" title='Add Subscription'>
        Add Subscription
        <mat-icon>note_add</mat-icon>
      </button>
    </div>

    <div *ngIf="!loading" class="grid">
      <wj-flex-grid [itemsSource]="subscriptionInformationList" [headersVisibility]="1">
        <wj-flex-grid-column header='Person/Agency Name' [minWidth]="200" binding='name' [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Description'" [minWidth]="200" [binding]="'description'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Start Date'" [minWidth]="200" [binding]="'startDate'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Days'" [minWidth]="200" [binding]="'duration'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Maximum Users'" [minWidth]="200" [binding]="'maximumUsers'" [isReadOnly]="true"></wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>
    </div>
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
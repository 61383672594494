import * as tslib_1 from "tslib";
import { AfterViewChecked, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { Address } from '@xactly-app/common/src/lib/models/Address';
import { Agency } from '@xactly-app/common/src/lib/models/Agency';
import { Agent } from '@xactly-app/common/src/lib/models/Agent';
import { AgentUser } from '@xactly-app/common/src/lib/models/AgentUser';
import { PasswordResetRequest } from '@xactly-app/common/src/lib/models/PasswordResetRequest';
import { Person } from '@xactly-app/common/src/lib/models/Person';
import { User } from '@xactly-app/common/src/lib/models/User';
import { AgencyService } from '@xactly-app/common/src/lib/services/AgencyService';
import { AgentService } from '@xactly-app/common/src/lib/services/AgentService';
import { AgentUserService } from '@xactly-app/common/src/lib/services/AgentUserService';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { PasswordResetService } from '@xactly-app/common/src/lib/services/PasswordResetService';
import { RoleService } from '@xactly-app/common/src/lib/services/RoleService';
import { UserService } from '@xactly-app/common/src/lib/services/UserService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';
import { Utility } from '@xactly-app/common/src/lib/utility';
var AgentEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentEditComponent, _super);
    function AgentEditComponent(winService, stateService, agentService, agencyService, dialogService, userService, validationService, roleService, errorTranslationService, activatedRoute, router, passwordResetService, agentUserService) {
        var _this = _super.call(this) || this;
        _this.winService = winService;
        _this.stateService = stateService;
        _this.agentService = agentService;
        _this.agencyService = agencyService;
        _this.dialogService = dialogService;
        _this.userService = userService;
        _this.validationService = validationService;
        _this.roleService = roleService;
        _this.errorTranslationService = errorTranslationService;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.passwordResetService = passwordResetService;
        _this.agentUserService = agentUserService;
        _this.agency = new Agency();
        _this.user = new User();
        _this.agencies = new Array();
        _this.roles = new Array();
        _this.agent = new Agent();
        _this.user.person = new Person();
        _this.user.person.address = new Address();
        _this.passwordResetRequest = new PasswordResetRequest();
        _this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
        return _this;
    }
    AgentEditComponent.prototype.ngAfterViewChecked = function () {
    };
    AgentEditComponent.prototype.ngAfterViewInit = function () {
    };
    AgentEditComponent.prototype.ngOnInit = function () {
        this.Load();
    };
    AgentEditComponent.prototype.ResetPassword = function () {
        var _this = this;
        this.busy();
        this.passwordResetRequest.resetUrl = Utility.GetServer() + 'changepassword';
        this.passwordResetRequest.email = this.user.username;
        this.passwordResetService.createPasswordResetResetPassword(this.passwordResetRequest)
            .pipe(finalize(function () { _this.ready(); })).subscribe(function (result) {
            if (result) {
                _this.ready();
                _this.dialogService.showOkDialog('Password reset request has been sent to: ' + _this.user.username, 'Password Reset')
                    .subscribe(function (e) {
                });
            }
        });
    };
    AgentEditComponent.prototype.Save = function () {
        var _this = this;
        this.busy();
        var agentUser = new AgentUser();
        this.user.tenantId = this.agent.agencyId;
        agentUser.user = this.user;
        agentUser.agent = this.agent;
        this.agentUserService.updateAgentUser(agentUser).pipe(finalize(function () { _this.ready(); })).subscribe(function (agentUserResult) {
            _this.user = agentUserResult.user;
            _this.agent = agentUserResult.agent;
            _this.router.navigate(['/agents']);
        }, this.errorTranslationService.ErrorHandler);
    };
    AgentEditComponent.prototype.Cancel = function () {
        this.router.navigate(['/agents']);
    };
    AgentEditComponent.prototype.Load = function () {
        var _this = this;
        this.busy();
        this.activatedRoute.paramMap
            .pipe(switchMap(function (params) {
            return _this.agentService.getAgent(params.get('id')).pipe(finalize(function () { }));
        }), finalize(function () { })).subscribe(function (agent) {
            _this.busy();
            _this.agent = agent;
            _this.userService.getUser(agent.userId).pipe(finalize(function () { _this.ready(); })).subscribe(function (user) {
                _this.busy();
                _this.user = user;
                _this.agencyService.getAgencys().pipe(finalize(function () { _this.ready(); })).subscribe(function (agencies) {
                    _this.busy();
                    _this.agencies = agencies;
                    _this.roleService.getRoles().pipe(finalize(function () { _this.ready(); })).subscribe(function (roles) {
                        _this.roles = roles;
                    }, _this.errorTranslationService.ErrorHandler);
                }, _this.errorTranslationService.ErrorHandler);
            }, _this.errorTranslationService.ErrorHandler);
        }, this.errorTranslationService.ErrorHandler);
    };
    return AgentEditComponent;
}(BaseComponent));
export { AgentEditComponent };

<instruction-card [message]="'Use this page to add a new subscription type'" [title]="'Add Subscription Type'"></instruction-card>
<form #userForm="ngForm" (ngSubmit)="Save()">
    <mat-card>
      <mat-card-header>
        <loading-indicator *ngIf="loading"></loading-indicator>        
        <mat-card-subtitle>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <mat-checkbox [(ngModel)]="subscriptionType.isSystem" name="subscriptionTypeisSystem">Is System</mat-checkbox>
        </div>
        <div>
          <mat-checkbox [(ngModel)]="subscriptionType.isAgencyLevel" name="subscriptionTypeisAgencyLevel">Is Agency Level </mat-checkbox>
        </div>
        <div>
          <mat-form-field>
            <input matInput [(ngModel)]="subscriptionType.description" placeholder="Description" maxlength="200" type="text"
              name="subscriptionTypedescription" #subscriptionTypedescription="ngModel" required>
            <mat-error *ngIf="subscriptionTypedescription.errors?.required">Description is required</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [(ngModel)]="subscriptionType.price" placeholder="Price" maxlength="10" type="number" name="subscriptionTypeprice"
              #subscriptionTypeprice="ngModel" required>
            <mat-error *ngIf="subscriptionTypeprice.errors?.required">Price is required</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [(ngModel)]="subscriptionType.maximumUsers" placeholder="Maximum Users" maxlength="10" type="number"
              name="subscriptionTypemaximumUsers" #subscriptionTypemaximumUsers="ngModel" required>
            <mat-error *ngIf="subscriptionTypemaximumUsers.errors?.required">Maximum users is required</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <input matInput [(ngModel)]="subscriptionType.durationDays" placeholder="Duration (Days)" maxlength="10" type="number"
              name="subscriptionTypedurationDays" #subscriptionTypedurationDays="ngModel" required>
            <mat-error *ngIf="subscriptionTypedurationDays.errors?.required">Maximum users is required</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <loading-indicator *ngIf="loading"></loading-indicator>
        <button [disabled]="!userForm.valid || loading" mat-raised-button color="primary" type="submit">Save</button>
        <button mat-raised-button color="warn" (click)="Cancel()" type="button">Cancel</button>
      </mat-card-actions>
    </mat-card>
  </form>

<div *ngIf="showHelp">
    <span>To create a custom shape: draw the shape using lines that intersect, select all lines and click on the link shape button when it appears:</span><mat-icon>link</mat-icon>
  <video controls width="100%" height="90%">
    <source src="./assets/help/CreateCustomShape.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
<div>
  <span *ngIf="!showHelp">{{message}}</span>
  <button *ngIf="!showHelp" mat-button color="primary" (click)="Show()">Show me how</button>
  <button *ngIf="showHelp" mat-button color="warn" (click)="Close()">Close</button>
</div>
﻿
// Generated using Typewritter, please do not modify!

import { User } from './User';
import { Agent } from './Agent';
import { Agency } from './Agency';

export class AgentUser {
        
    public user : User = null;    
    public agent : Agent = null;    
    public agency : Agency = null;
}

// Generated using Typewritter, please do not modify!
import * as tslib_1 from "tslib";
import { DynamoEntity } from './DynamoEntity';
var MeasurementUnit = /** @class */ (function (_super) {
    tslib_1.__extends(MeasurementUnit, _super);
    function MeasurementUnit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.singleName = null;
        _this.pluralName = null;
        _this.shortName = null;
        _this.squaredName = null;
        _this.description = null;
        return _this;
    }
    return MeasurementUnit;
}(DynamoEntity));
export { MeasurementUnit };

import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@xactly-app/common/src/lib/base-component/base-component';
import { SubscriptionType } from '@xactly-app/common/src/lib/models/SubscriptionType';
import { CellStateService } from '@xactly-app/common/src/lib/services/cell.state.service';
import { DialogService } from '@xactly-app/common/src/lib/services/dialog.service';
import { ErrorTranslationService } from '@xactly-app/common/src/lib/services/error-translation.service';
import { SubscriptionTypeService } from '@xactly-app/common/src/lib/services/SubscriptionTypeService';
import { ValidationService } from '@xactly-app/common/src/lib/services/validation.service';
import { WindowService } from '@xactly-app/common/src/lib/services/window.service';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-type-edit',
  templateUrl: './subscription-type-edit.component.html',
  styleUrls: ['./subscription-type-edit.component.scss']
})
export class SubscriptionTypeEditComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public subscriptionType: SubscriptionType = new SubscriptionType();

  constructor(public winService: WindowService,
    public stateService: CellStateService,
    public dialogService: DialogService,
    public validationService: ValidationService,
    public errorTranslationService: ErrorTranslationService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public subscriptionTypeService: SubscriptionTypeService) {
    super();
  }

  ngAfterViewChecked() {
  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.Load();
  }

  public Save() {
    this.busy();
    this.subscriptionTypeService.updateSubscriptionType(this.subscriptionType)
      .pipe(finalize(() => { this.ready(); }))
      .subscribe(
        (subscriptionType) => {
          this.subscriptionType = subscriptionType;
          this.router.navigate(['/subscriptionTypes']);
        },
        this.errorTranslationService.ErrorHandler);
  }

  public Cancel() {
    this.router.navigate(['/subscriptionTypes']);
  }

  public Load() {
    this.busy();
    this.activatedRoute.paramMap
      .pipe(
        finalize(() => { this.ready(); }),
        switchMap((params: ParamMap) =>
          this.subscriptionTypeService.getSubscriptionType(params.get('id'))))
      .pipe(finalize(() => { this.ready(); }))
      .subscribe(
        subscriptionType => {
          this.subscriptionType = subscriptionType;
          this.ready();
        },
        this.errorTranslationService.ErrorHandler);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isProd, isTest } from '@xactly-app/common/src/lib/utility';
import { ProdConfiguration } from '../config/app.configuration.prod';
import { TestConfiguration } from '../config/app.configuration.test';
import { DevConfiguration } from '../config/app.configuration.development';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private appConfig;

    constructor(private http: HttpClient) {}

    //TODO: fetch settings from json file on server
    //   loadAppConfig() {
    //     return this.http.get('/assets/data/appConfig.json')
    //       .toPromise()
    //       .then(data => {
    //         this.appConfig = data;
    //       });
    //   }

    getConfig() {
        return isProd() ? ProdConfiguration : isTest() ? TestConfiguration : DevConfiguration;
    }
}

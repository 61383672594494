import { HttpClient } from '@angular/common/http';
import { isProd, isTest } from '@xactly-app/common/src/lib/utility';
import { ProdConfiguration } from '../config/app.configuration.prod';
import { TestConfiguration } from '../config/app.configuration.test';
import { DevConfiguration } from '../config/app.configuration.development';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppConfigService = /** @class */ (function () {
    function AppConfigService(http) {
        this.http = http;
    }
    //TODO: fetch settings from json file on server
    //   loadAppConfig() {
    //     return this.http.get('/assets/data/appConfig.json')
    //       .toPromise()
    //       .then(data => {
    //         this.appConfig = data;
    //       });
    //   }
    AppConfigService.prototype.getConfig = function () {
        return isProd() ? ProdConfiguration : isTest() ? TestConfiguration : DevConfiguration;
    };
    AppConfigService.ngInjectableDef = i0.defineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.inject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };

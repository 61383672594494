import * as tslib_1 from "tslib";
var BaseComponent = /** @class */ (function () {
    function BaseComponent() {
        this.SupportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
        this._loading = false;
        this._loading = false;
    }
    Object.defineProperty(BaseComponent.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        enumerable: true,
        configurable: true
    });
    BaseComponent.prototype.busy = function () {
        this._loading = true;
    };
    BaseComponent.prototype.ready = function () {
        this._loading = false;
    };
    return BaseComponent;
}());
export { BaseComponent };
var PagingBaseComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PagingBaseComponent, _super);
    function PagingBaseComponent() {
        var _this = _super.call(this) || this;
        _this.currentPage = 1;
        _this.pageSize = 10;
        _this.totalPages = 10;
        _this.totalCount = 0;
        return _this;
    }
    return PagingBaseComponent;
}(BaseComponent));
export { PagingBaseComponent };
